import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"

function PaySum(props) {

    const { price } = props

    const { getT } = useMultilang()

    return(
        <div className="order-pay-sum">
        <div className="text">
          {getT("settings.transactions.totalPrice")}
        </div>
        <div className="price">
          <NumberFormat
            value={+price}
            decimalSeparator=","
            suffix=" Ft"
            thousandSeparator="."
            displayType="text"
          />
        </div>
      </div>
    )
}
export default PaySum