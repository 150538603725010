import { useState, useEffect, useContext } from "react"
import moment from "moment"
import { useDevice } from "okeoke.client"
import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"
import ReportsMenu from "./ReportsMenu"
import merge from 'lodash.merge'
import ReportsHead from "./ReportsHead"
import useStatsApi from "apis/useStatsApi"
import { MainContext } from "contexts/MainContext"
import NotAvailable from "components/NotAvailable"

function Users() {

    const { getSysUsersTotal, getSysUsersBase } = useStatsApi()
    const { getT } = useMultilang()
    const { localUsers } = useDevice()

    const { featureList } = useContext(MainContext)

    const [list, setList] = useState([])
    const [userList, setUserList] = useState({})
    const [totalTippSum, setTotalTippSum] = useState(0)
    const [totalDiscountPrice, setTotalDiscountPrice] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [filter, setFilter] = useState({from: `${moment().format("YYYY-MM-DD")} 00:00:00`, to: `${moment().format("YYYY-MM-DD")} 23:59:59`})

    useEffect(() => {
        loadUsers()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        loadUsers()
        // eslint-disable-next-line
    }, [filter])

    useEffect(() => {
        setLocalUsers()
        // eslint-disable-next-line
    }, [localUsers])

    const loadUsers = () => {

        let filters = { fromDate: filter.from, toDate: filter.to }

        getSysUsersTotal(filters).then(res => {
            if(res.success) {
                let obj = {}
                let sum1 = 0
                let sum2 = 0
                for(let item of res.data) {
                    obj[item.sysUserUUID] = item
                    sum1 += +item.discountPriceSum
                    sum2 += +item.ordersCount
                }
                
                setTotalDiscountPrice(sum1)
                setTotalCount(sum2)

                getSysUsersBase(filter).then(res => {
                    if(res.success) {
                        let obj2 = {} 
                        let sum1 = 0
                        for(let item of res.data) {
                            obj2[item.sysUserUUID] = item
                            sum1 += +item.tipAmountSum
                        }
                        setTotalTippSum(sum1)
                        let newValues = merge(obj, obj2)
                        setList(newValues)
                    } else {
                        console.log(res)
                    }
                })
            } else {
                console.log(res)
            }
        })
    }

    const setLocalUsers = () => {
        let userObj = {}
        for(let user of localUsers) {
            userObj[user.userUUID] = user
        }
        setUserList(userObj)
    }

    if(!featureList.multiUser) return <NotAvailable />

    const handleDateChange = (values) => {
        setFilter(values)
    }

    return(
        <div className="reports-container users-container">
            <ReportsHead title={getT("reports.users.title")} handleDateChange={handleDateChange} filter={filter} />
            <div className="body">
                <div className="box-container">
                    <div className="box">
                        <div className="label">{getT("reports.users.totalDiscountPrice")}</div>
                        <div className="value">
                        <NumberFormat
                            value={+totalDiscountPrice}
                            decimalSeparator=","
                            suffix=" Ft"
                            thousandSeparator="."
                            displayType="text"
                        />
                        </div>
                    </div>
                    <div className="box">
                        <div className="label">{getT("reports.users.tippSum")}</div>
                        <div className="value">
                        <NumberFormat
                            value={+totalTippSum}
                            decimalSeparator=","
                            suffix=" Ft"
                            thousandSeparator="."
                            displayType="text"
                        />
                        </div>
                    </div>
                    <div className="box">
                        <div className="label">{getT("reports.users.tippPercent")}</div>
                        <div className="value">{(Math.round(10*(+totalTippSum) / (+totalDiscountPrice || 1))*100) / 10}%</div>
                    </div>
                    <div className="box">
                        <div className="label">{getT("reports.users.totalCount")}</div>
                        <div className="value">{totalCount}</div>
                    </div>
                </div>
                <div className="user-container">
                    {Object.values(list).map((user, index) =>
                        <div key={index} className="hidden appear user-box">
                            <div className="user-name">{userList?.[user.sysUserUUID]?.firstName || getT("reports.users.nameNotAvailable")}</div>
                            <div className="user-data">
                                <div className="data-box">
                                    <div className="label">{getT("reports.users.sumDiscountPrice")}</div>
                                    <div className="value">
                                        <NumberFormat
                                            value={+user.discountPriceSum}
                                            decimalSeparator=","
                                            suffix=" Ft"
                                            thousandSeparator="."
                                            displayType="text"
                                        />
                                    </div>
                                </div>
                                <div className="data-box">
                                    <div className="label">{getT("reports.users.tippSum")}</div>
                                    <div className="value">
                                        <NumberFormat
                                            value={+user.tipAmountSum}
                                            decimalSeparator=","
                                            suffix=" Ft"
                                            thousandSeparator="."
                                            displayType="text"
                                        />
                                    </div>
                                </div>
                                <div className="data-box">
                                    <div className="label">{getT("reports.users.tippPercent")}</div>
                                    <div className="value">{(Math.round(10*(+user.tipAmountSum) / +user.discountPriceSum)*100) / 10}%</div>
                                </div>
                                <div className="data-box">
                                    <div className="label">{getT("reports.users.totalCount")}</div>
                                    <div className="value">{user.ordersCount}</div>
                                </div>
                                <div className="data-box">
                                    <div className="label">{getT("reports.users.avgTipp")}</div>
                                    <div className="value">
                                        <NumberFormat
                                            value={Math.round(+user.tipAmountSum / user.ordersCount)}
                                            decimalSeparator=","
                                            suffix=" Ft"
                                            thousandSeparator="."
                                            displayType="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="footer">
                <ReportsMenu />
            </div>
      </div>
    )
}
export default Users