import DateRangeSelector from "components/InputFieldDateRange"
import { isMobileOnly } from "react-device-detect"
import moment from "moment"
import useMultilang from "intl/useMultilang"

function ReportsHead(props) {

    const { title, handleDateChange, filter } = props

    const { getT } = useMultilang()

    const onDateChanged = (e) => {
        let from = `${moment(e.fromDate).format("YYYY-MM-DD")} 00:00:00`
        let to = `${moment(e.toDate).format("YYYY-MM-DD")} 23:59:59`
        if(typeof handleDateChange === "function") handleDateChange({ from, to })
    }
    
    const handleChangeStart = (e) => {
        let from = `${moment(e.fromDate).format("YYYY-MM-DD")} 00:00:00`
        if(typeof handleDateChange === "function") handleDateChange({ from })
    }
    
    const handleChangeTo = (e) => {
        let to = `${moment(e.toDate).format("YYYY-MM-DD")} 23:59:59`
        if(typeof handleDateChange === "function") handleDateChange({ to })
    }

    return(
        <div className="head">
            <div className="left-side">
                <div className="title">{title}</div>
                <div className="sub-title">{`${moment(filter?.from).format("YYYY-MM-DD")} 00:00:00 - ${moment(filter?.to).format("YYYY-MM-DD")} 23:59:59`}</div>
            </div>
            <div className="right-side">
                <div className="date-selector">
                    {!isMobileOnly && <DateRangeSelector
                        label={getT("statistics.dashboard.dateRange")}
                        name={"dashboardDateRange"}
                        onRangeSelected={onDateChanged}
                        fromDate={filter?.from || moment()}
                        toDate={filter?.to || moment()}
                    />}
                </div>
                {isMobileOnly && <div className="">
                    <label htmlFor="start">{getT("settings.transactions.from")}: </label>
                    <input type="date" id="start" name="start" value={filter?.from || moment().format("YYYY-DD-MM")} onChange={handleChangeStart}/>
                    <br/>
                    <br/>
                    <label htmlFor="start">{getT("settings.transactions.to")}: </label>
                    <input type="date" id="to" name="to" value={filter?.to || moment().format("YYYY-DD-MM")} onChange={handleChangeTo}/>
                </div>}
            </div>
        </div>
    )
}
export default ReportsHead