import { createContext, useEffect, useState, useContext, useRef} from "react"
import { useDevice, usePos, usePrinter, usePosPrinter, OrderStatus, useClient } from "okeoke.client"
import { v4 as uuidv4 } from "uuid"
import { PersonalSettingsContext } from "contexts/PersonalSettingsProvider"
import { ModalContext } from "contexts/ModalContext"
import { MainContext } from "contexts/MainContext"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import { useMain } from "okeoke.client"
import QRCode from "qrcode.react"
import useMultilang from "intl/useMultilang"
import { toast } from "react-toastify"
import AddBankCardPaymentModal from "components/AddBankCardPaymentModal"
import figlet from "figlet"
import standard from "figlet/importable-fonts/Standard.js"

const PaymentContext = createContext()

const PaymentProvider = (props) => {
  const { 
    selectedOrderUUID,
    paymentsNeeded,
    paymentsTotal,
    discountPrice,
    totalPrice,
    order,
    payments,
    submitOrder,
    addPayment,
    orderNumber,
    serviceFee,
    invoices,
    tipAmount,
    setTipAmount,
    startOrder,
    addStatus,
    orderState,
    setFirstAvailableBasket,
    orderVipID,
    payVipMoney
  } = useContext(SelectedOrderContext)
  const { loadModal, popModal, closeAllModal } = useContext(ModalContext)
  const { featureList } = useContext(MainContext)
  const { localAppParams } = useContext(PersonalSettingsContext)
  const { getT } = useMultilang()
  const { client } = useClient()

  const { printReceipt, printNonFiscal, createPrintSlip, generateQrSale } = usePrinter(null)
  const { posPrint } = usePosPrinter()
  const { devicePaymentAgents, logError, deviceParams } = useDevice()
  const { startPayment, updatePayment } = usePos()

  const [selectedPayment, setSelectedPayment] = useState(null)
  const [currentAmount, setCurrentAmount] = useState("0")
  const [bankCardPaymentInProgress, setBankCardPaymentInProgress] = useState(false)
  const [addPaymentInProgress, setAddPaymentInProgress] = useState(false)
  const [loading, setLoading] = useState(false)

  const bankCardSlip = useRef(null)
  const submitted = useRef(false)
  const timeOutTimer = useRef(null)
  const timeOutTimer2 = useRef(null)
  const timeOutTimer3 = useRef(null)
  const startOrderAfterPayment = useRef(true)
  const bankCardPaymentAmount = useRef(paymentsNeeded)
  const customerBankcardSlip = useRef(null)

  const getData = (message) => {}

  const { messageSecondary } = useMain(getData)

  useEffect(() => {
    return () => clearTimeout(timeOutTimer3.current)
  }, [])

  useEffect(() => {
    console.log(submitted.current, paymentsNeeded, orderState)
    if((!submitted.current) && paymentsNeeded === 0 && orderState === 0) {
      submitted.current = true
      setLoading(true)
      timeOutTimer2.current = setTimeout(() => {
        toast.error("Submit order timeout")
        setLoading(false)
      }, 30000)
      submitOrder()
      .then(res => {
        if(res.success) {
          if(featureList.orderManager) {
            if(!localAppParams?.behaviour?.sendToOrderManager) addStatus(OrderStatus.ORDER_CLOSED_SUCCESS, {})
          } else {
            addStatus(OrderStatus.ORDER_CLOSED_SUCCESS, {})
          }
        } else {
          console.log("Order submit failed", res)
          setLoading(false)
        }
      })
      .finally(res => clearTimeout(timeOutTimer2.current))
    }

    return () => clearTimeout(timeOutTimer2.current)
    // eslint-disable-next-line
  }, [paymentsNeeded])

  useEffect(() => {
    if(paymentsNeeded === 0 && orderNumber != null) {
      handlePrinting()
      messageSecondary({ action: "updateStatus", data: "sell" })
      messageSecondary({ action: "tipChange", data: true })
    }
    // eslint-disable-next-line
  }, [orderNumber, paymentsNeeded])

  useEffect(() => {
    updatePayment(tipAmount)
    bankCardPaymentAmount.current = +currentAmount+tipAmount
    console.log("Tip amount changed", tipAmount)
    console.log("Current bankcard payment amount", bankCardPaymentAmount.current)
    // eslint-disable-next-line
  }, [tipAmount])

  const addInvoice = (invoiceAgentID, amount, extID, vatInvoice, data, orderUUID) => {
    client.orders.loadOrder(orderUUID).then(res => {
      if(res != null) {
        order.invoices.addInvoice(invoiceAgentID, amount, extID, vatInvoice, data).then(res => {
          if(res.success) {
            console.log("Invoice added", res)
          } else {
            console.log("Add invoice failed", res)
          }
        })
      } else {
        console.log("Load order error!")
      }
    })
  }
  
  const handleOrderStart = () => {
    if(startOrderAfterPayment.current) {
      startOrder()
      console.log("New order started")
    } else {
      setFirstAvailableBasket()
    }
  }
  
  const setStartOrderAfterPayment = value => startOrderAfterPayment.current = value
  
  const handlePrinting = () => {
    
    let printerType = deviceParams?.printer?.type
    let anyPosPrinter = deviceParams?.posPrinter?.uuid != null
    
    const handleClickOnQR = () => {
      handleOrderStart()
      onPaymentProccesFinished()
    }

    if(printerType != null && printerType !== "") {
      if(printerType === "Fiscat") {
        if(featureList.fiscalPrinter) {
          startFiscalPrint()
        } else {
          if(!anyPosPrinter || !featureList.posPrinter) {
            handleOrderStart()
            onPaymentProccesFinished()
          }
        }
      }
      if(printerType === "FiscatIPalm") {
        if(featureList.fiscalPrinter) {
          /* Show Fiscat IPalm QR code, then ok button triggers the start of next order */
          let codeQR = generateQrSale(order)
          loadModal(
            <div className="open-in-modal-root">
              <h1>{getT("modal.fiscatIPalm.title")}</h1>
              <QRCode
                value={codeQR}
                includeMargin={true}
                renderAs="canvas"
                size={300}
                level="L"
              />
              <div className="button-cotainer">
                <div className="btn" onClick={handleClickOnQR}>
                  {getT("modal.buttonResponse.ok")}
                </div>
              </div>
            </div>
          )
        } else {
          if(!anyPosPrinter || !featureList.posPrinter) {
            handleOrderStart()
            onPaymentProccesFinished()
          }
        }
      }
    }
    if(anyPosPrinter && featureList.posPrinter) {
      if(printerType !== "Fiscat") {
        /* If printer type is NOT fiscat - print order slip, then print kitchen slip */
        startPosPrintOrder()
      }
      if(printerType !== "FiscatIPalm") {
        handleOrderStart()
        onPaymentProccesFinished()
      } 
    } else {
      if(printerType !== "FiscatIPalm") {
        handleOrderStart()
        onPaymentProccesFinished()
      } 
    }
  }

  const onPaymentProccesFinished = () => {
    localStorage['firstStartTutorialFinished'] = 1
    localStorage.removeItem('firstStartTutorialOn')
    closeAllModal()
  }

  const startPosPrintOrder = () => {
    
    const printSlip = createPrintSlip(order, {printQRCode: localAppParams?.behaviour?.printQRCode, printNonFiscalDisclaimer: true, ccPrintSlip: localAppParams?.behaviour?.printCustomerBankcardSlip && customerBankcardSlip.current != null && {Rows: customerBankcardSlip.current}, figletPrintSlip: createFigletFromOrderNumber(orderNumber, 48), maxChars: 48 })
    posPrint(printSlip).then(res => {
      if(res.success) {
        console.log("Pos print success", res.data)
      } else {
        console.log("Pos print error", res.data)
        toast.error(getT("modal.posPrinterError.slipNotPrinted"))
      }
    }).finally(() => customerBankcardSlip.current = null)
  }


  // IDEIGLENES FUNKCIO!
  const cutFestiPrintSlip = (recentpay) => {
    if(recentpay == null) return null
    let reached = false;
    let newSlip = { printSlip: { Rows: [] } };
    if (recentpay?.Rows) {
      newSlip.printSlip.Rows = [
        ...newSlip.printSlip.Rows,
        { Bold: false, Msg: "----------------------------------------" },
      ];
      for (let row of recentpay.Rows) {
        if (reached) {
          newSlip.printSlip.Rows = [...newSlip.printSlip.Rows, row];
        }
        if (row.Msg.includes("########")) {
          reached = true;
        }
      }
    }
    if(!reached) {
      return recentpay
    } else {
      return newSlip.printSlip;
    }
  };

  const createFiglet = (text) => {
    figlet.parseFont("Standard", standard);
    return figlet.textSync(text);
  };
  
  function strCenter(source, maxChars)
  {
      let spaces = maxChars - source.length;
      let padLeft = spaces / 2 + source.length;
      return source.padStart(padLeft, ' ')
  }

  const createFigletFromOrderNumber = (orderNumber, maxChars) => {
    let orderNumberArray = (orderNumber || '').split("/")
    let printSlip = { Rows: [] };
    let textarray = createFiglet(orderNumberArray.pop()).split("\n");
    for (let row of textarray) {
      printSlip.Rows = [
        ...printSlip.Rows,
        { Bold: false, Msg: strCenter(row, maxChars) },
      ];
    }
    return printSlip
  }

  const startFiscalPrint = () => {
    // let fiscalSlip = cutFestiPrintSlipWithFiglet(
    //   orderNumber, 
    //   bankCardSlip.current != null ? { Rows: bankCardSlip.current } : {},
    //   orderUUID,
    //   localAppParams?.behaviour
    // )
    let maxChars = 40
    bankCardSlip.current = null
    if(invoices.length > 0) {
      console.log("Slip already printed")
    } else {
      printReceipt(order, createPrintSlip(order, { 
        printQRCode: localAppParams?.behaviour?.printQRCode,
        printItems: false, 
        headerPrintSlip: null, 
        ccPrintSlip: cutFestiPrintSlip(bankCardSlip.current != null ? { Rows: bankCardSlip.current } : null),
        figletPrintSlip: createFigletFromOrderNumber(orderNumber, maxChars),
        maxChars
      })).then(res => {
        if(res.success) {
          console.log("Fiscal print success", res)
          addInvoice(0, res.data.amount, res.data.extID, res.data.vatInvoice, res.data, selectedOrderUUID)
        } else {
          console.log("Fiscal printer error (fiscal receipt)", res)
          logError(1, 100, "", res.data)
          toast.error(getT("modal.fiscalPrinterError.fiscalReceipt"))
        }
      })
    }
  }

  const printBankCardSlip = (slip) => {

    let printerType = deviceParams?.printer?.type
    let anyPosPrinter = Object.values(deviceParams?.posPrinters || {}).length > 0

    if(printerType === "Fiscat") {
      printNonFiscal(slip).then(res => {
        if(res.success) {
          console.log("Bank card slip print success", res)
        } else {
          console.log("Fiscal printer error (bank card slip)", res)
          logError(1, 100, "", res.data)
          toast.error(getT("modal.fiscalPrinterError.nonFiscalReceipt.bankCardSlip"))
        }
      })
    } else {
      if(anyPosPrinter) {
        posPrint(slip).then(res => {
          if(res.data.success) {
            console.log("Pos print success", res.data)
          } else {
            console.log("Bank card pos print error", res.data)
            toast.error(getT("modal.posPrinterError.bankCardSlipNotPrinted"))
          }
        })
      } else {
        toast.error(getT("modal.posPrinterError.bankCardSlipNotPrinted"))
      }
    }
  }

  const handleAddPayment = (params) => {
    setAddPaymentInProgress(true)
    console.log("Payment params", params.paymentAgentID, params.amount, params.transactionID, params.data, params.uuid, params.agentType)
    timeOutTimer3.current = setTimeout(() => {
      toast.error("Add payment timeout")
      setAddPaymentInProgress(false)
    }, 30000)
    addPayment(params.paymentAgentID, params.amount, params.transactionID, params.data, params.stornoUUID, params.agentType)
    .then(res => {
      if(res.success) {
        console.log("Payment added", res)
      } else {
        console.log("Payment error", res)
        toast.error("Payment error")
      }
      setAddPaymentInProgress(false)
    })
    .finally(res => clearTimeout(timeOutTimer3.current))
  }

  const handlePaymentTypeChange = (agent) => {
    setSelectedPayment(agent)
  }

  const handleSetCurrent = (amount) => {
    setCurrentAmount(amount)
  }

  const addCardPayment = (amount, paymentAgentID) => {
    if(+amount === 0) return null
    let params = {
      paymentAgentID, 
      amount, 
      transactionID: uuidv4(), 
      data: {}, 
      agentType: "bankCard"
    }
    handleAddPayment(params)
    setCurrentAmount("0")
  }

  const addOtherPayment = (amount, paymentAgentID, agentType) => {
    if(+amount === 0) return null
    let params = {
      paymentAgentID, 
      amount, 
      transactionID: uuidv4(), 
      data: {}, 
      agentType
    }
    handleAddPayment(params)
    setCurrentAmount("0")
  }

  const addCashPayment = (amount, paymentAgentID, agentType) => {
    if(+amount === 0) return null;
    let params = {
      paymentAgentID, 
      amount: +amount % 5 !== 0 ? Math.round(amount / 5) * 5 : amount, 
      transactionID: uuidv4(), 
      data: {}, 
      agentType
    }
    handleAddPayment(params)
    setCurrentAmount("0")
  }
  
  const proceedPayment = (amount, selectedPayment) => {

    const handleClickOnChangeModal = (roundedPaymentsNeeded, paymentAgentID, agentType) => {
      popModal()
      addCashPayment(roundedPaymentsNeeded, paymentAgentID, agentType)
    }
    console.log(selectedPayment)
    if(selectedPayment.agentType === "cash") {
      let roundedAmount = Math.round(amount / 5) * 5
      let roundedPaymentsNeeded = Math.round(paymentsNeeded / 5) * 5
      if (roundedAmount > roundedPaymentsNeeded) {
        loadModal(
          <div className="open-in-modal-root">
            <div className="text">{getT("modal.ChangeModalDesign.text")}: {roundedAmount - roundedPaymentsNeeded} Ft</div>
            <div className="button-cotainer">
              <div className="btn" onClick={() => handleClickOnChangeModal(roundedPaymentsNeeded, selectedPayment.paymentAgentID, selectedPayment.agentType)}>
                {getT("modal.buttonResponse.ok")}
              </div>
            </div>
          </div>
        )
      } else {
        addCashPayment(roundedAmount, selectedPayment.paymentAgentID, selectedPayment.agentType)
      }
    }
    if(selectedPayment.agentType === "bankCard") {
      if(selectedPayment?.useTerminal?.terminalType == null) {
        addCardPayment(amount, selectedPayment.paymentAgentID)
      } else {
        startBankCardPayment(amount, selectedPayment)
      }
    }
    if(selectedPayment.agentType === "vip") {
      payVipMoney(amount, selectedPayment.paymentAgentID).then(res => {
        if(res.success) {
          console.log(res)
        } else {
          toast.error(getT("modal.vipError.payment"))
        }
      })
    }
    if(selectedPayment.agentType !== "bankCard" && selectedPayment.agentType !== "cash" && selectedPayment.agentType !== "vip") {
      addOtherPayment(amount, selectedPayment.paymentAgentID, selectedPayment.agentType)
    }
  }

  const startBankCardPayment = (amount, selectedPayment) => {
    if(amount === 0) {
      console.log("Start bank card payment failed, amount 0")
      toast.error(getT("modal.bankCardError.amountNull"))
      return null
    }
    if(selectedPayment?.useTerminal?.terminalType === "festipayPswTerminal" && (amount < paymentsNeeded || paymentsTotal > 0)) {
      console.log("Only full amount with PSW")
      toast.error(getT("modal.bankCardError.onlyFullPayment"))
      return null
    }
    setBankCardPaymentInProgress(true)
    timeOutTimer.current = setTimeout(() => {
      setBankCardPaymentInProgress(false)
      toast.error(getT("modal.bankCardError.timeout"))
      loadModal(
      <AddBankCardPaymentModal 
        popModal={popModal} 
        addCardPayment={addCardPayment}
        amount={amount}
        paymentAgentID={selectedPayment.paymentAgentID}
      />)
    }, 30000)

    
    startPayment(selectedOrderUUID, +amount, selectedPayment.useTerminal, tipAmount, (res) => onTransactionUpdated(+amount, selectedPayment, res)).then(res => {
      bankCardPaymentAmount.current = amount
      if(res.success) { 
        if(["festipayPswTerminal", "saltpayTerminal"].includes(selectedPayment?.useTerminal?.terminalType)) {
          clearTimeout(timeOutTimer.current)
        } else {
          let params = {
            paymentAgentID: selectedPayment.paymentAgentID,
            amount: res.data?.provider?.amount || 0,
            transactionID: res.data?.provider?.transactionId || 0,
            data: res.data,
            agentType: selectedPayment.agentType
          }
          handleAddPayment(params)
          bankCardSlip.current = cutFestiPrintSlip({ Rows: res.data.provider.printSlip })
          if(+amount < paymentsNeeded) printBankCardSlip(bankCardSlip.current)
          setCurrentAmount("0")
          clearTimeout(timeOutTimer.current)
          setBankCardPaymentInProgress(false)
        }
      } else {
        logError(1, 100, "", res.data)
        toast.error(getT("modal.bankCardError.error"))
        clearTimeout(timeOutTimer.current)
        setBankCardPaymentInProgress(false)
        loadModal(
        <AddBankCardPaymentModal 
          popModal={popModal} 
          addCardPayment={addCardPayment}
          amount={amount}
          paymentAgentID={selectedPayment.paymentAgentID}
        />)
      }
    })
  }

  const onTransactionUpdated = (amount, selectedPayment, res) => {
    if(res.success) {
      if(res.code === 201) {
        let params = {
          paymentAgentID: selectedPayment.paymentAgentID,
          amount: selectedPayment?.useTerminal?.terminalType === "festipayPswTerminal" ? +res.data.terminalResponse.tipAmount+amount : res.data.provider.amount,
          transactionID: res.data.provider.transactionId,
          data: res.data,
          agentType: selectedPayment.agentType
        }
        customerBankcardSlip.current = res.data?.provider?.printSlip || null
        console.log(customerBankcardSlip.current)
        handleAddPayment(params)
        setBankCardPaymentInProgress(false)
        setCurrentAmount("0")
      } else {
        if(res.data.posState != null && +res.data.posState !== 1) {
          messageSecondary({ action: "tipChange", data: false })
        }
      }
    } else {
      setBankCardPaymentInProgress(false)
      toast.error(getT("modal.bankCardError.transactionFailed"))
      if (res.code !== 666) {
        logError(1, 100, "", res.data)
      }
      loadModal(
        <AddBankCardPaymentModal 
          popModal={popModal} 
          addCardPayment={addCardPayment}
          amount={bankCardPaymentAmount.current}
          paymentAgentID={selectedPayment.paymentAgentID}
        />
      )
    }
  }

  return (
    <PaymentContext.Provider
      value={{
        selectedPayment,
        handlePaymentTypeChange,
        currentAmount,
        handleSetCurrent,
        paymentsNeeded,
        paymentsTotal,
        totalPrice,
        order,
        payments,
        invoices,
        proceedPayment,
        devicePaymentAgents,
        handleAddPayment,
        discountPrice,
        serviceFee,
        tipAmount,
        setTipAmount,
        bankCardPaymentInProgress,
        addPaymentInProgress,
        setStartOrderAfterPayment,
        loading,
        orderVipID
      }}
    >
      {props.children}
    </PaymentContext.Provider>
  )
}

export { PaymentContext, PaymentProvider }