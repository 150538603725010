import { useContext, useEffect, useRef, useState } from "react"
import { useMain, useVip } from "okeoke.client"
import Item from "./Item"
import useMultilang from "intl/useMultilang"
import { ModalContext } from "contexts/ModalContext"
import BasketThrowModal from "./BasketThrowModal"
import LoadSvg from "components/LoadSvg-v2"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import { isMobileOnly } from "react-device-detect"
import SellNotAllowedOverlay from "components/SellNotAllowedOverlay"
import OrderActions from "./OrderActions"
import OrderItemModal from "../OrderItemModal"
import OrderIdentifierModal from "./OrderIdentifierModal"

function OrderSummary(props) {
  
  const { priceAndSubmitOpen, itemAdded } = props
  
  const scrollToBottomDivRef = useRef()
  const { takeAway, orderItemsAssembled, orderIdentifier, setOrderIdentifier, notes, orderVipID, tableInfo } = useContext(SelectedOrderContext)
  const { firstName } = useVip(orderVipID)
  const { getT } = useMultilang()
  const { loadModal, popModal } = useContext(ModalContext)
  const [update, setUpdate] = useState(null)
  const [showDetails, setShowDetails] = useState(localStorage['showItemDetailsInBasket'])
  
  const getData = (message) => {
    if(message?.action === "update") setUpdate(message?.data)
  }
  const { messageSecondary } = useMain(getData)
  
  const loadBasketThrow = () => loadModal(<BasketThrowModal popModal={popModal} />, popModal)

  const openOrderItemModal = orderItemUUID => loadModal(<OrderItemModal orderItemUUID={orderItemUUID} popModal={popModal} />, popModal)
  
  const openIdentifierModal = () => loadModal(<OrderIdentifierModal orderIdentifier={orderIdentifier} setOrderIdentifier={setOrderIdentifier} popModal={popModal} />, popModal)

  useEffect(() => {
    messageSecondary({ action: "updateBasketItems", data: orderItemsAssembled })
    // eslint-disable-next-line
  }, [update])

  useEffect(() => {
    messageSecondary({ action: "updateBasketItems", data: orderItemsAssembled })
    // eslint-disable-next-line
  }, [orderItemsAssembled])

  useEffect(() => {
    if(itemAdded != null && !isMobileOnly) scrollToBottomDivRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [itemAdded])

  const toggleShowItemDetails = () => {
    setShowDetails(showDetails ? false : "1")
    showDetails ? localStorage.removeItem("showItemDetailsInBasket") : localStorage["showItemDetailsInBasket"] = "1"
  }

  const items = Object.values(orderItemsAssembled || {}).filter(assembledItem => assembledItem?.count > 0)

  return (
    <>
      <div className="order-summary-header">
        <div className="order-summary-title" onClick={openIdentifierModal}>
          {orderVipID != null && firstName != null && firstName !== "" && `${firstName}`}
          {(orderVipID == null || firstName == null || firstName === "") && orderIdentifier === "" ? getT("sell.basket.order") : orderIdentifier}
          {tableInfo?.name != null && ` - ${tableInfo?.name}`}
        </div>
        <div className="drop-basket-container">
          <LoadSvg name={"BinIcon"} onClick={loadBasketThrow} className={"icon-btn"}/>
        </div>
      </div>
      <OrderActions toggleShowItemDetails={toggleShowItemDetails} showDetails={showDetails} />
      <div className={`order-summary-container ${priceAndSubmitOpen ? "open" : "closed"}`}>
        <SellNotAllowedOverlay/>
        <div className="order-summary-body">
          <div className="order-summary-items-container">
            <div className="order-summary-items-body">
              {items.length < 1 && <div className="empty-basket">{getT("sell.basketEmpty")}</div>}            
              {items.map(assembledItem => 
                <Item
                  key={assembledItem.orderItemUUID}
                  assembledItem={assembledItem}
                  orderTakeAway={takeAway}
                  openOrderItemModal={() => openOrderItemModal(assembledItem.orderItemUUID)}
                  showDetails={showDetails}
                />
              )}
              {notes != null && notes !== "" && <div className="basket-item-container notes">{`${getT("item.notes")}: ${notes}`}</div>}
              <div ref={scrollToBottomDivRef} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default OrderSummary