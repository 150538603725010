import { useState, useEffect } from "react";
import { useReports, useClient } from "okeoke.client";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import useMultilang from "intl/useMultilang";


function HourlySalesChart(props) {
  
  Chart.register(...registerables);
  const { fetchOrdersReport } = useReports();
  const { fromDate, toDate } = props;

  const { brandID } = useClient();
  const { getT } = useMultilang();

  const [data, setData] = useState(null);
  const [labels, setLabels] = useState(null);
  const [dataset1, setDataset1] = useState(null);
  const [dataset2, setDataset2] = useState(null);
  const [dataset3, setDataset3] = useState(null);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [brandID, fromDate, toDate]);

  useEffect(() => {
    if (data != null) {
      setLabels(Object.keys(data));

      let data1 = {
        label: getT("statistics.dashboard.hourlyChart.totalCount"),
        data: Object.values(data || {}).map((row) => row.totalCount),
        type: "line",
        backgroundColor: ["rgba(255, 159, 64, 0.2)"],
        borderColor: ["rgba(255, 159, 64)"],
        borderWidth: 1,
        yAxisID: "y1",
      };
      setDataset1(data1);

      let data2 = {
        label: getT("statistics.dashboard.hourlyChart.totalPriceSum"),
        data: Object.values(data || {}).map((row) => parseInt(row.totalPriceSum)),
        type: "bar",
        backgroundColor: ["rgba(255, 99, 132)"],
        order: 2,
      };
      setDataset2(data2);

      let data3 = {
        label: getT("statistics.dashboard.hourlyChart.totalDiscountPrice"),
        data: Object.values(data || {}).map((row) => parseInt(row.discountPriceSum)),
        type: "bar",
        backgroundColor: ["rgba(54, 162, 235)"],
        order: 1,
      };
      setDataset3(data3);
    } else {
      setDataset1(null);
      setDataset2(null);
      setDataset3(null);
    }
    // eslint-disable-next-line
  }, [data]);

  const loadData = async () => {
    if (brandID != null) {
      let res = await fetchOrdersReport("hourly", { fromDate, toDate });
      if (res.success) {
        if (res.data.length > 0) {
          res.data.sort((a, b) => a.hour - b.hour);
          let firstHour = res.data[0].hour;
          let lastHour = res.data[res.data.length - 1].hour;
          let obj = {};
          for (let row of res.data) {
            obj[row.hour] = row;
          }
          let obj2 = {};
          for (let i = firstHour; i < lastHour + 1; i++) {
            obj2[i] = {
              totalCount: obj[i]?.totalCount || 0,
              totalPriceSum: obj[i]?.totalPriceSum || 0,
              discountPriceSum: obj[i]?.discountPriceSum || 0,
            };
          }
          setData(obj2);
        } else {
          setData(null);
        }
      } else {
        console.log(res);
      }
    }
  };

  if (data == null)
    return (
      <div className="chart-container">
        <div className="text">{getT("statistics.dashboard.noData")}</div>
      </div>
    );

  return (
    <div className="chart-container">
      <Bar
        data={{
          datasets: [dataset1, dataset2, dataset3],
          labels,
        }}
        width={"100%"}
        height={"100%"}
        options={{
          plugins: {
            title: {
              display: true,
              text: getT("statistics.dashboard.saleByHours"),
            },
          },
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              type: "linear",
              display: true,
              position: "left",
            },
            y1: {
              type: "linear",
              display: true,
              position: "right",

              // grid line settings
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
            },
          },
        }}
      />
    </div>
  );
}
export default HourlySalesChart;
