import useMultilang from "intl/useMultilang"
import { useContext } from "react"
import { PreviousOrderContext } from "contexts/PreviousOrderContext"
import DateSelector from "./DateSelector"
//import TextFields from "./TextFields"
//import DeviceTypeSelector from "./DeviceTypeSelector"

function Filter() {
  
  const { getT } = useMultilang()
  const { filters, setFilters, loading } = useContext(PreviousOrderContext)

  return (
    <div className="tansactions-filter-root">
      <div className={`loading-container ${loading ? "" : "hidden"}`}>{getT("settings.transactions.loading")}</div>
      <div className="group-container">
        <div className="title">{getT("settings.transactions.date")}</div>
        <DateSelector setFilters={setFilters} filters={filters} />
      </div>
      {/*<div className="group-container">
        <div className="title">{getT("settings.transactions.filters")}</div>
        <TextFields setFilters={setFilters} filters={filters} />
        <DeviceTypeSelector setFilters={setFilters} filters={filters} />
      </div>*/}
    </div>
  )
}
export default Filter