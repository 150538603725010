import { useIPanelApi, useClient } from "okeoke.client"

export default function useVatGroupsApi() {

  const { post, get } = useIPanelApi()
  const { brandID } = useClient()

  const getVatGroups = async (filter) => {
    try {
      const response = await get(`brand/catalog/vatGroups/all?brandID=${brandID}&filter=${JSON.stringify(filter || {})}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const getVatGroup = async (vatGroupID) => {
    try {
      const response = await get(`brand/catalog/vatGroups/${vatGroupID}?brandID=${brandID}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const getVatGroupItems = async (vatGroupID) => {
    try {
      const response = await get(`brand/catalog/vatGroups/items/${vatGroupID}?brandID=${brandID}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setVatGroup = async (vatGroup) => {
    let body = {
        brandID,
        vatGroup
        /*vatGroup: {
            id,
            vat,
            vatTa,
            name
        }*/
    }
    try {
      const response = await post(`brand/catalog/vatGroups`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  return {
    getVatGroups,
    getVatGroup,
    getVatGroupItems,
    setVatGroup
  }
}