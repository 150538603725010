import { useEffect, useState } from "react"
import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"

function VatGroups(props) {

    const { items } = props
    const { getT } = useMultilang()

    const [groups, setGroups] = useState({})

    useEffect(() => {
        let newValues = {}
        for(let item of items) {
            if(item.count > 0) {
                newValues = {...newValues, [item.vat]: (parseInt(newValues[item.vat] || 0)) + parseInt(item.discountPrice)}
            }
        }
        setGroups(newValues)
    }, [items])

    return(
        <div className="vat-root">
            <div className="vat-group-head">
                <div className="vat">{getT("vat.group.name")}</div>
                <div className="amount-net">{getT("vat.group.net")}</div>
                <div className="amount-gross">{getT("vat.group.gross")}</div>
            </div>
            <div className="vat-groups">
                {Object.entries(groups).map(
                    group => <div key={group[0]} className="vat-group">
                        <div className="vat">{group[0]}%</div>
                        <div className="amount-net">
                            <NumberFormat
                                value={Math.round(group[1] / ((parseFloat(group[0])/100)+1))}
                                decimalSeparator=","
                                suffix=" Ft"
                                thousandSeparator="."
                                displayType="text"
                            />
                        </div>
                        <div className="amount-gross">
                            <NumberFormat
                                value={group[1]}
                                decimalSeparator=","
                                suffix=" Ft"
                                thousandSeparator="."
                                displayType="text"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default VatGroups