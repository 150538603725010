import { useState, useEffect } from "react"
import InputFieldSelect from "components/InputFieldSelect"
import TextField from 'components/TextField'
import useMultilang from 'intl/useMultilang'
import { toast } from 'react-toastify'
import { useDevice } from "okeoke.client"

function PosPrinterParams(props) {

    const { params, onSave, onDelete, popModal, printers, onSelect } = props
    const { getT } = useMultilang()
    const [localParams, setLocalParams] = useState(null)
    const [selectedOption, setSelectedOption] = useState(params?.uuid != null ? "edit" : "addNew")
    const [isPrivate, setIsPrivate] = useState(false)
    const { deviceUUID } = useDevice()
    const [selectedNetworkPrinterUUID, setSelectedNetworkPrinterUUID] = useState(localParams?.uuid)

    useEffect(() => {
        setLocalParams(params)
    }, [params]) 

    const validate = () => {

        let validation = true

        if(localParams?.name == null || localParams?.name === "") {
            toast.error("Name required")
            validation = false
        }
        if(localParams?.commPath == null || localParams?.commPath === "") {
            toast.error("CommPath required")
            validation = false
        }
        if(localParams?.commType == null) {
            toast.error("CommType required")
            validation = false
        }
        if(localParams?.printerType == null) {
            toast.error("PrinterType required")
            validation = false
        }

        const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
        if(localParams?.commType === "net" && !ipRegex.test(localParams?.commPath)) {
            toast.error("Invalid ip")
            validation = false
        }

        return validation
    }

    const onChangePrinterType = (e) => {
        let newParams = {...localParams, [e.target.name]: e.target.value}
        if(e.target.value === "net") newParams = {...newParams, commPort: "9100"}
        if(e.target.name === "commType") newParams = {...newParams, commPath: ""}
        setLocalParams(newParams)
    }

    const changeFieldData = (e) => {
        setLocalParams({...localParams, [e.target.name]: e.target.value})
    }

    const handleSave = () => {
        switch(selectedOption) {
            case "select": onSelect(selectedNetworkPrinterUUID)
            break
            case "addNew": savePrinter()
            break
            case "delete": handleDeletePrinter()
            break
            case "edit": savePrinter()
            break
            default:
        }
    }

    const savePrinter = () => {
        let valid = validate()
        if(valid) onSave({...localParams, deviceUUID: isPrivate ? deviceUUID : null})
    }

    const handleDeletePrinter = () => {
        if(localParams?.uuid == null) return null
        onDelete()
    }

    const handleSetOption = (option) => {
        switch(option) {
            case "addNew": 
                setLocalParams(null)
                setSelectedOption("addNew")
            break
            case "edit":
                setLocalParams(params)
                setSelectedOption("edit")
            break                
            default: setSelectedOption(option)
        }
    }

    return(
        <div className="printer-modal">
            <div className="modal-title">{getT('devices.posPrinters.modalTitle')}</div>
            <div className="choose">
                <div className={`btn ${selectedOption === "addNew" ? "btn-highlighted" : ""}`} onClick={() => handleSetOption("addNew")}>{getT("devices.printer.addNewPrinter")}</div>
                {params?.uuid != null && <div className={`btn ${selectedOption === "edit" ? "btn-highlighted" : ""}`} onClick={() => handleSetOption("edit")}>{getT("devices.printer.editPrinter")}</div>}
                <div className={`btn ${selectedOption === "select" ? "btn-highlighted" : ""}`} onClick={() => handleSetOption("select")}>{getT("devices.printer.selectPrinter")}</div>
                {params?.uuid != null && <div className={`btn ${selectedOption === "delete" ? "btn-highlighted" : ""}`} onClick={() => handleSetOption("delete")}>{getT('devices.deletePosPrinter')}</div>}
            </div>
            {(selectedOption === "addNew" || selectedOption === "edit") && <div className="content">
                <TextField 
                    label={getT("devices.posPrinterName")}
                    name="name"
                    onChange={changeFieldData}
                    value={localParams?.name || ""}
                    type="text"
                    fullWidth
                />
                <InputFieldSelect 
                    label={getT("devices.posPrinterType")}
                    name="printerType"
                    onChange={onChangePrinterType}
                    value={localParams?.printerType}
                    displayedDefaultName={getT("devices.selectPosPrinterType")}
                    options={[{ title: "Epson", value: "EPSON"}, { title: "Star", value: "STAR"}]}
                    fullWidth
                />
                <InputFieldSelect 
                    label={getT("devices.posPrinterCommType")}
                    name="commType"
                    onChange={onChangePrinterType}
                    value={localParams?.commType}
                    displayedDefaultName={getT("devices.selectcommType")}
                    options={[{ title: "Net", value: "net"}, { title: "Com", value: "com"}/*, { title: "Printer", value: "printer"}*/]}
                    fullWidth
                />        
                <TextField 
                    label={getT("devices.posPrintercommPath")}
                    name="commPath"
                    onChange={changeFieldData}
                    value={localParams?.commPath || ""}
                    type="text"
                    fullWidth
                />
                {localParams?.commType === "net" &&
                    <TextField 
                        label={getT("devices.posPrintercommPort")}
                        name="commPort"
                        onChange={changeFieldData}
                        value={localParams?.commPort || "9100"}
                        type="text"
                        fullWidth
                    />
                }
                <div className="printer-private-container">
                    <div className={`private ${!isPrivate ? "selected" : ""}`} onClick={() => setIsPrivate(false)}>
                        <div className="inner-bg"></div>
                        {getT("localPrinter.networkPrinter.shared")}
                    </div>
                    <div className={`private ${isPrivate ? "selected" : ""}`} onClick={() => setIsPrivate(true)}>
                        <div className="inner-bg"></div>
                        {getT("localPrinter.networkPrinter.private")}
                    </div>
                </div>
            </div>}
            {selectedOption === "select" && <div className="content">
                <div className="printer-cards-container">
                    {Object.values(printers).map(printer => 
                        <div key={printer.uuid} className={`printer-card ${selectedNetworkPrinterUUID === printer.uuid ? "selected" : ""}`} onClick={() => setSelectedNetworkPrinterUUID(printer.uuid)}>
                            <div className="name">{printer.name}</div>
                            <div className="type">{printer.printerType}</div>
                            <div className="path">{printer.commPath}</div>
                        </div>
                    )}
                </div>
            </div>}
            {selectedOption === "delete" && <div className="content">
                {getT('devices.posPrinters.areYouSure')}
            </div>}
            <div className="buttons-container">
                <div className="btn btn-cancel" onClick={popModal}>{getT('devices.posPrinters.cancel')}</div>
                <div className="btn btn-save" onClick={handleSave}>{getT('devices.posPrinters.save')}</div>
            </div>
        </div>
    )
}
export default PosPrinterParams