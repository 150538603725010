import LoadSvg from "components/LoadSvg-v2"
import useMultilang from "intl/useMultilang"

function SetCategory(props) {

    const { goToNext } = props

    const { getT } = useMultilang()

    return(
        <div className="hidden appear set-category-root">
            <div className="row category-head">{getT("tutorial.setCategory.categoryHead")}<LoadSvg name="ArrowLeftIcon" /></div>
            <div className="row category-parent">{getT("tutorial.setCategory.categoryParent")}<LoadSvg name="ArrowLeftIcon" /></div>
            <div className="row category-vat">{getT("tutorial.setCategory.categoryVat")}<LoadSvg name="ArrowLeftIcon" /></div>
            <div className="row category-ntak">{getT("tutorial.setCategory.categoryNtak")}<LoadSvg name="ArrowLeftIcon" /></div>
            <div className="row action" onClick={goToNext}><div className="btn btn-highlighted border-glow-effect pulsate-fwd">{getT("tutorial.setCategory.goToNext")}</div></div>
        </div>
    )
}
export default SetCategory