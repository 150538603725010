import { useEffect, useContext, useRef, useState } from "react"
import { MainContainerContext } from "contexts/MainContainerProvider"
import EditorHeader from "./EditorHeader"
import Categories from "views/Sell/Categories"
import EditItems from "./EditItems"
import { Swiper, SwiperSlide } from 'swiper/react'
import { isMobileOnly } from "react-device-detect"
import BackToParentCateg from "views/Sell/BackToParentCateg"
import MoveToSubCateg from "views/Sell/MoveToSubCateg"
import { isMobile } from "react-device-detect"
import { PersonalSettingsContext } from "contexts/PersonalSettingsProvider"
import CreateNewCategoryButton from "./CreateNewCategory/CreateNewCategoryButton"
import CreateNewItemButton from "./CreateNewItem/CreateNewItemButton"
import EditItem from "./EditItem"
import { MainContext } from "contexts/MainContext"
import EditCateg from "./EditCateg"
import { TutorialContext } from "contexts/TutorialContext"
import CreateCategory from "./Tutorial/CreateCategory"
import SetCategory from "./Tutorial/SetCategory"
import CreateItem from "./Tutorial/CreateItem"
import SetItem from "./Tutorial/SetItem"
import { useItems } from "okeoke.client"
import { useNavigate } from "react-router-dom"

function Editor() {

  const { setHeader } = useContext(MainContainerContext)
  const { selectedCategUUID } = useContext(MainContext)
  const { customization } = useContext(PersonalSettingsContext)
  const { tutorialOn, setContents, setSectionsVisibility } = useContext(TutorialContext)
  const { categoryTree } = useItems()
  const navigate = useNavigate()
  
  const [tutorialStep, setTutorialStep] = useState("createCategory")
  const [selectedItemUUID, setSelectedItemUUID] = useState(null)

  const containerRef = useRef(null)

  let pageSelectorHeight = 42
  let flexGap = 12

  useEffect(() => {
    setHeader(<EditorHeader />)
    return () => setHeader(null)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(categoryTree.length > 0 && selectedItemUUID == null) {
      setTutorialStep("categorySettings")
    }
    if(categoryTree.length > 0 && selectedItemUUID != null) {
      setTutorialStep("itemSettings")
    }
  }, [categoryTree, selectedItemUUID])

  useEffect(() => {
    if(tutorialOn) {
      if(tutorialStep === "createCategory") {
        setContents([
          {
            section: "center",
            content: <CreateCategory goToNext={handleGoToNext} />
          }
        ])
        setSectionsVisibility([
          {section: "summary", visibility: true}, 
          {section: "center", visibility: true}, 
          {section: "header", visibility: false},
          {section: "leftSide", visibility: true}
        ])
      }
      if(tutorialStep === "categorySettings") {
        setContents([
          {
            section: "center",
            content: <SetCategory goToNext={handleGoToNext} />
          }
        ])
        setSectionsVisibility([
          {section: "summary", visibility: false}, 
          {section: "center", visibility: true}, 
          {section: "header", visibility: true},
          {section: "leftSide", visibility: true}
        ])
      }
      if(tutorialStep === "createItem") {
        setContents([
          {
            section: "summary",
            content: <CreateItem goToNext={handleGoToNext} />
          }
        ])
        setSectionsVisibility([
          {section: "summary", visibility: true}, 
          {section: "center", visibility: false}, 
          {section: "header", visibility: true},
          {section: "leftSide", visibility: true}
        ])
      }
      if(tutorialStep === "itemSettings") {
        setContents([
          {
            section: "center",
            content: <SetItem goToNext={handleGoToNext} />
          },
          {
            section: "summary",
            content: null
          }
        ])
        setSectionsVisibility([
          {section: "summary", visibility: false}, 
          {section: "center", visibility: true}, 
          {section: "header", visibility: true},
          {section: "leftSide", visibility: true}
        ])
      }
    }
  // eslint-disable-next-line
  }, [tutorialOn, tutorialStep])

  useEffect(() => {
    setSelectedItemUUID(null)
  }, [selectedCategUUID])

  const handleGoToNext = () => {
    if(tutorialStep === "createCategory") setTutorialStep("categorySettings")
    if(tutorialStep === "categorySettings") setTutorialStep("createItem")
    if(tutorialStep === "itemSettings") navigate("/")
  }

  if(isMobileOnly) return(
    <Swiper>
      <SwiperSlide>
        <Categories editorMode={true} />
      </SwiperSlide>
      <SwiperSlide>
        <div className="sell-root hidden quickAppear editor-root">
          <div id={"order-items-container-id"} className={`order-items-container ${isMobile ? "is-mobile" : ""} ${customization?.categoriesTop ? "horizontal" : "vertical"} ${customization?.paginationOff ? "pagination-off" : ""}`} ref={containerRef}>
            <BackToParentCateg />
            <MoveToSubCateg />
            <CreateNewCategoryButton />
            <CreateNewItemButton 
              setSelectedItemUUID={setSelectedItemUUID}
              selectedItemUUID={selectedItemUUID}
            />
            <EditItems 
              rows={Math.floor(((containerRef.current?.offsetHeight || 0) - pageSelectorHeight) / ((customization?.orderItemFontSize || 16)*(customization?.orderItemHeight || 8) + flexGap))}
              cols={customization?.orderItemsColumns}
              setSelectedItemUUID={setSelectedItemUUID}
              selectedItemUUID={selectedItemUUID}
            />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        {selectedItemUUID != null && <EditItem itemUUID={selectedItemUUID} />}
        {selectedItemUUID == null && <EditCateg selectedCategUUID={selectedCategUUID} />}
      </SwiperSlide>
    </Swiper>
  )

  return (
    <div className="sell-outer-container">
      <div className="sell-root hidden quickAppear editor-root">
        <Categories editorMode={true} />
        <div id={"order-items-container-id"} className={`hidden appear order-items-container ${isMobile ? "is-mobile" : ""} ${customization?.categoriesTop ? "horizontal" : "vertical"} ${customization?.paginationOff ? "pagination-off" : ""}`} ref={containerRef}>
          <BackToParentCateg />
          <MoveToSubCateg />
          <CreateNewCategoryButton />
          <CreateNewItemButton 
            setSelectedItemUUID={setSelectedItemUUID}
            selectedItemUUID={selectedItemUUID}
            tutorialOn={tutorialOn}
          />
          <EditItems 
            rows={Math.floor(((containerRef.current?.offsetHeight || 0) - pageSelectorHeight) / ((customization?.orderItemFontSize || 16)*(customization?.orderItemHeight || 8) + flexGap))}
            cols={customization?.orderItemsColumns || 3}
            setSelectedItemUUID={setSelectedItemUUID}
            selectedItemUUID={selectedItemUUID}
          />
        </div>
      </div>
      {selectedItemUUID != null && <EditItem itemUUID={selectedItemUUID} />}
      {selectedItemUUID == null && <EditCateg selectedCategUUID={selectedCategUUID} />}
    </div>
  )
}

export default Editor