import { useState, useEffect, useContext } from 'react'
import OrderCard from 'views/OrderManager/OrderCard'
import { useOrders, OrderStates, useClient } from 'okeoke.client'
import { Link } from 'react-scroll'
import { MainContainerContext } from "contexts/MainContainerProvider"
import { MainContext } from "contexts/MainContext"
import Header from './Header'
import useMultilang from "intl/useMultilang"
import TableList from './TableList'
import SelectedTableHeader from './SelectedTableHeader'

function OrderManager(props) {

    const { readOnly, deviceType } = props
    const { setHeader } = useContext(MainContainerContext)
    const { featureList } = useContext(MainContext)
    const { socketAuthenticated } = useClient()
    const { orders } = useOrders()
    const [pingedOrderUUID, setPingedOrderUUID] = useState("")
    const { getT } = useMultilang()
    const [activeView, setActiveView] = useState(localStorage['selectedOrderManagerView'] || "openOrders")
    const [orderList, setOrderList] = useState([])
    const [selectedTableUUID, setSelectedTableUUID] = useState("")

    useEffect(()=> {
        if(typeof setHeader === "function") {
            setHeader(<Header setView={handleViewSet} activeView={activeView} />)
        }     
        return () => setHeader(null)
        // eslint-disable-next-line
    },[activeView, featureList])

    useEffect(() => {
        if(activeView === "openOrders") setOrderList(Object.values(orders || {}))
        if(activeView === "tableManager" && featureList?.tableManager === 1) {
            let ordersArray = []
            for(let order of Object.values(orders || {})) {
                if(order.tableUUID === selectedTableUUID) ordersArray.push(order)
            }
            setOrderList(ordersArray)
        }
    }, [orders, activeView, featureList, selectedTableUUID])

    const handleViewSet = (viewName) => {
        localStorage['selectedOrderManagerView'] = viewName
        setActiveView(viewName)
    }

    const handleSelectTable = (tableUUID) => {
        setSelectedTableUUID(selectedTableUUID === tableUUID ? "" : tableUUID)
    }
    
    return (
        <div className={`order-view-content ${deviceType !== "orderManager" ? "" : "padding-left"}`}>
            {!socketAuthenticated && <div className="hidden quickAppear offline-container">{getT(`openOrders.offlineMode`)}</div>}
            {featureList?.tableManager === 1 && <div className={`table-list ${activeView === "tableManager" ? "open" : "closed"}`}>
                <TableList handleSelectTable={handleSelectTable} selectedTableUUID={selectedTableUUID} orders={orders} />
            </div>}
            <div className="order-view-right-side">
                {activeView !== "tableManager" && <div className="order-view-top-bar">
                    {orderList.map(order => 
                        <Link key={order.uuid} to={order.uuid} spy={true} smooth={true} containerId="order-card-container" horizontal={true} offset={-10}
                            onClick={() => { setPingedOrderUUID(order.uuid) }}
                            className={`order-view-top-bar-element ${order.orderState >= OrderStates.DONE ? "ready" : ""} `}>
                            <div className="order-number">
                                {order.orderNumber}
                            </div>
                            <div className="order-state">
                                {getT(`openOrders.orderCard.orderState-${order.orderState}`)}
                            </div>
                        </Link>
                    )}
                </div>}
                {activeView === "tableManager" && <SelectedTableHeader selectedTableUUID={selectedTableUUID} orders={orderList} />}
                <div id="order-card-container" className="order-view-card-container hidden appear">
                    {orderList.length < 1 && getT("openorders.noOrders")}
                    {orderList.map(order => 
                        <OrderCard
                            key={order.uuid}
                            orderUUID={order.uuid}
                            pingedOrderUUID={pingedOrderUUID}
                            setPingedOrderUUID={setPingedOrderUUID}
                            readOnly={readOnly}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
export default OrderManager