import { useContext, useRef, useState, useEffect } from "react"
import { useLocation, useClient } from "okeoke.client"
import { MainContext } from "contexts/MainContext"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import useMultilang from "intl/useMultilang"
import moment from "moment"
import { TutorialContext } from "contexts/TutorialContext"
import DayOpenNeeded from "./DayOpenNeeded"
import OpenDayHelpWindow from "./OpenDayHelpWindow"
import { isMobileOnly } from "react-device-detect"

function SellNotAllowedOverlay(props) {

    const { openDay, offlineOpen, changeOfflineOpen } = useContext(MainContext)
    const { startOrder, paymentsTotal } = useContext(SelectedOrderContext)
    const { tutorialOn, setContents } = useContext(TutorialContext)
    
    const { getT } = useMultilang()
    const { location } = useLocation()
    const { socketConnected } = useClient()

    const [firstOrderTimeStamp, setFirstOrderTimeStamp] = useState(null)

    useEffect(() => {

        if(location == null) return null

        if(location?.openState == null) return null

        
        const dayOpen = offlineOpen || (location?.openState?.dayState != null && location?.openState?.dayState?.open)
        
        if(!isMobileOnly && tutorialOn && !dayOpen) {
            setContents([
                {
                    section: "center",
                    content: <DayOpenNeeded 
                                visible={true}
                                firstOrderTimeStamp={firstOrderTimeStamp} 
                                socketConnected={socketConnected} 
                                handleOpenDay={handleOpenDay}
                                changeOfflineOpen={changeOfflineOpen}
                            />
                },
                {
                    section: "summary",
                    content: <OpenDayHelpWindow />
                }
            ])
        } 
        // eslint-disable-next-line
    }, [tutorialOn, offlineOpen, location])

    const date = useRef(moment().format("YYYY-MM-DD"))
    const time = useRef(moment().format("HH:mm:ss"))


    const handleOpenDay = () => {
        openDay(moment(`${date.current} ${time.current}`).toISOString(), onDayOpen)
    }

    const onDayOpen = (res) => {
        if(res.data.firstOpenOrderTimestamp != null) setFirstOrderTimeStamp(moment(res.data.firstOpenOrderTimestamp).format("YYYY-MM-DD HH:mm:ss"))
    }

    if(location?.openState == null) return null

    const dayOpen = offlineOpen || (location?.openState?.dayState != null && location?.openState?.dayState?.open)

    if(dayOpen && paymentsTotal === 0) return null
    
    if(!dayOpen) return (
        <DayOpenNeeded 
            visible={!tutorialOn}
            firstOrderTimeStamp={firstOrderTimeStamp} 
            socketConnected={socketConnected} 
            handleOpenDay={handleOpenDay}
            changeOfflineOpen={changeOfflineOpen}
        />
    )
    if(dayOpen && paymentsTotal > 0) {
        return(
            <div className="sell-not-allowed">
                <div className="text">{getT("sell.not.allowed")}</div>
                <div className="btn btn-highlighted" onClick={startOrder} >{getT("sell.newBasket")}</div>
            </div>
        )
    }
    return null
}
export default SellNotAllowedOverlay