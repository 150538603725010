import LoadSvg from "components/LoadSvg-v2";
import React, {useRef, useEffect, useContext} from "react";
import { useDrag, useDrop } from "react-dnd";
import { DataTableContext} from "./DataTableContext";

function Row(props) {
  const { rowIndex, moveRow } = props;
  const { setRowInRefMatrix, setRefOfRows, dndHandle, pagination, paginationFromProp, columns } = useContext(DataTableContext)
  const dropRef = useRef(null);
  const dragRef = useRef(null);

  const [, drop] = useDrop({
    accept: "row",
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = rowIndex;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [, drag, preview] = useDrag({
    type: "row",
    rowIndex,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(dropRef));
  drag(dragRef);

  useEffect(() => {
    setRefOfRows(rowIndex,dropRef)
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    if(rowIndex < pagination || paginationFromProp == null)
    setRowInRefMatrix(rowIndex, {"rowRef":dropRef})
    // eslint-disable-next-line
  }, [pagination, columns])

  return (
    <tr ref={dropRef} key={rowIndex} className={`data-row`} /*style={{ opacity }}*/>
      <td ref={dragRef} className={`data-cell dnd ${dndHandle ? "appear" : "d-none"}`}>
        {dndHandle == null || typeof dndHandle === "boolean"
          ? <LoadSvg name="gridDots" />
          : dndHandle}
      </td>
      {props.children}
    </tr>
  );
}
export default Row;
