import { useContext } from "react"
import { MainContext } from "contexts/MainContext"
import { ModalContext } from "contexts/ModalContext"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import LoadSvg from "components/LoadSvg-v2"
import useMultilang from "intl/useMultilang"
import CreateNewItem from "views/Editor/CreateNewItem"
import { useLocation } from "react-router-dom"

function CreateNewItemButton(props) {

  const { setSelectedItemUUID, tutorialOn } = props

  const { selectedCategUUID } = useContext(MainContext)
  const { addModal, popModal } = useContext(ModalContext)
  const { getT } = useMultilang()
  const editorMode = useLocation().pathname === "/editor"

  const openNewItemInModal = () => {
    addModal(
      <Modal
        key={uuidv4()}
        className="create-new-item-modal"
        onClickLayout={popModal}
      >
        <CreateNewItem selectedCategoryUUID={selectedCategUUID} setSelectedItemUUID={setSelectedItemUUID} />
      </Modal>
    )
  }

  if(!editorMode || selectedCategUUID == null) return null
  
  return (
    <div className={`order-item-container order-dummy-item-container ${tutorialOn ? "tutorial-on border-glow-effect pulsate-fwd" : ""}`} onClick={openNewItemInModal}>
      <div className="inner-container">
        <div className="svg-container"><LoadSvg name={"plus"} /></div>
        <div className="text">{getT("sell.edit.CreateItem")}</div>
      </div>
    </div>
  )
}

export default CreateNewItemButton