import { useContext, useState, useEffect } from "react"
import TextField from "components/TextField"
import { ModalContext } from "contexts/ModalContext"
import useMultilang from "intl/useMultilang"
import { useClient } from "okeoke.client"
import InputFieldSelect from "components/InputFieldSelect"
import { toast }  from "react-toastify"
import useNtakApi from "apis/useNtakApi"
import useItemsApi from "apis/catalog/useItemsApi"
import useVatGroupsApi from "apis/catalog/useVatGroupsApi"
import useCategoriesApi from "apis/catalog/useCategoriesApi"

function CreateNewItem(props) {

  const { selectedCategoryUUID, setSelectedItemUUID } = props

  const { client } = useClient()
  const { setItem: addItem } = useItemsApi()
  const { popModal } = useContext(ModalContext)
  const { getT, language } = useMultilang()
  const { getNtakCategories } = useNtakApi()
  const { getVatGroups } = useVatGroupsApi()
  const { getCategory } = useCategoriesApi()
  
  const [item, setItem] = useState({ name: { hu: "", en: "" }, vatGroupID: 0, price: "", categoryUUID: "", ntakCategory: "", ntakCategoryID: 0 })
  const [categData, setCategData] = useState(null)
  const [ntakCategories, setNtakCategories] = useState(null)
  const [vatGroups, setVatGroups] = useState(null)
  const [selectedNtakCategory, setSelectedNtakCategory] = useState("")
  const [error, setError] = useState(true)

  useEffect(() => {
    getNtakCategories().then(res => {
      if(res.success) {
        let obj = {}
        for(let categ of Object.values(res.data || {})) {
          obj[categ.code] = categ
        }
        setNtakCategories(obj)
      } else {
        console.log(res)
      }
    })
    getVatGroups().then(res => {
      if(res.success) {
        setVatGroups(res.data)
      } else {
        console.log(res)
      }
    })
    // eslint-disable-next-line
  }, [])
  
  useEffect(() => {
    setItem({ ...item, categoryUUID: selectedCategoryUUID})
    loadCateg(selectedCategoryUUID)
    // eslint-disable-next-line
  }, [selectedCategoryUUID])

  useEffect(() => {
    if(categData != null && ntakCategories != null && categData?.attributes?.ntakCategoryID != null) {

      let ntakCategoryCode = ""
      let ntakSubCategoryCode = ""

      for(let categ of Object.values(ntakCategories)) {
        if(categ.subCategories?.[categData.attributes.ntakCategoryID] != null) {
          ntakCategoryCode = categ.code
          ntakSubCategoryCode = categ.subCategories?.[categData.attributes.ntakCategoryID].code || ""
        }
      }

      let newValues = { ...item }
      newValues.ntakCategoryID = categData.attributes.ntakCategoryID
      newValues.ntakCategory = ntakSubCategoryCode
      newValues.vatGroupID = categData.attributes.vatGroupID
      setSelectedNtakCategory(ntakCategoryCode)
      setItem(newValues)
    }
  // eslint-disable-next-line
  }, [categData, ntakCategories])

  const loadCateg = (selectedCategoryUUID) => {
    getCategory(selectedCategoryUUID).then(res => {
      if(res.success) {
        setCategData(res.data)
      } else {
        console.log(res)
      }
    })
  }
  
  const handleClose = () => popModal()

  const handleCreate = () => {
    
    let valid = validate(item)

    if(!valid) return null

    addItem(item).then(res => {
      if(res.success) {
        setSelectedItemUUID(res.data.uuid)
      } else {
        toast.error(res.message)
      }
      popModal()
    })
  }

  const validate = (data) => {

    if(data == null) return false
    
    let valid = true
    
    if(data?.name?.hu === "") valid = false
    if(+data?.price < 1) valid = false
    if(data?.ntakCategory === "") valid = false
    if(data?.ntakCategoryID === 0) valid = false
    if(data?.categoryUUID === "") valid = false
    if(data?.vatGroupID === 0) valid = false

    setError(!valid)

    return valid
  }

  const handleChange = (e) => {

    let newValues = { ...item }
    if(e.target.name === "name") {
      newValues = { ...newValues, name: { hu: e.target.value, en: e.target.value } }
    } else {
      newValues = { ...newValues, [e.target.name]: e.target.value }
    }

    if(e.target.name === "ntakCategory") setSelectedNtakCategory(e.target.value)

    setItem(newValues)
    validate(newValues)
  }

  return (
    <div className="create-new-item-root">
      <div className="create-new-item-title">
        {getT("createItem.modal.addItem")}
      </div>
      <div className="create-new-item-container">
        <div className="new-item-single-data">
          <div className="data-name">{getT("createItem.modal.itemName")}</div>
            <TextField
              type="text"
              name="name"
              onChange={handleChange}
              value={item.name?.[language]}
              placeholder={getT("createItem.modal.newItem")}
            />
          </div>
          <div className="new-item-single-data">
            <div className="data-name">
              {getT("createItem.modal.itemPrice")}
            </div>
            <TextField
              name="price"
              type="number"
              onChange={handleChange}
              value={item.price}
              placeholder={getT("createItem.modal.price")}
            />
          </div>
          <div className="new-item-single-data">
            <div className="data-name">{getT("createItem.modal.VATgroup")}</div>
            <InputFieldSelect
              className={"create-new-item-root-input-field-select"}
              options={(vatGroups || []).map(vat => { return { value: vat.id, title: `${vat.name} (${parseInt(vat?.vat || 0)}% / ${parseInt(vat?.vatTA || 0)}%)` } })}
              onChange={handleChange}
              value={item.vatGroupID}
              name="vatGroupID"
              displayedDefaultName={getT("createItem.modal.selectVatGroup")}
            />
          </div>
          <div className="new-item-single-data">
            <div className="data-name">{getT("createItem.modal.category")}</div>
            <InputFieldSelect
              className={"create-new-item-root-input-field-select"}
              options={Object.values(client.catalog.categories || {}).map(categ => {
                return { title: categ.name?.[language], value: categ.uuid }
              })}
              name="categoryUUID"
              onChange={handleChange}
              value={item.categoryUUID}
              displayedDefaultName={getT("createItem.modal.selectCategory")}
            />
          </div>
          <div className="new-item-single-data">
            <div className="data-name">{getT("createItem.modal.ntakMainCategory")}</div>
            <InputFieldSelect
              className={"create-new-item-root-input-field-select"}
              options={Object.values(ntakCategories || {}).map(categ => {
                return { title: categ.name?.[language], value: categ.code }
              })}
              onChange={handleChange}
              name="ntakCategory"
              value={selectedNtakCategory}
              displayedDefaultName={getT("createItem.modal.selectNtakMainCategory")}
            />
          </div>
          <div className="new-item-single-data">
            <div className="data-name">{getT("createItem.modal.ntakSubCategory")}</div>
            <InputFieldSelect
              className={"create-new-item-root-input-field-select"}
              options={Object.values(ntakCategories?.[selectedNtakCategory]?.subCategories || {}).map(categ => {
                return { title: categ.name?.[language], value: categ.id }
              })}
              onChange={handleChange}
              name="ntakCategoryID"
              value={item.ntakCategoryID}
              displayedDefaultName={getT("createItem.modal.selectNtakSubCategory")}
              disabled={selectedNtakCategory === ""}
            />
          </div>
        </div>
        <div className="button-container">
          <div className="btn btn-cancel" onClick={handleClose}>
            {getT("createItem.modal.cancel")}
          </div>
          <div className={`btn btn-save ${error ? "inactive" : ""}`} onClick={handleCreate}>
            {getT("createItem.modal.save")}
          </div>
        </div>
      </div>
    )
}
export default CreateNewItem
