import { useEffect, useRef, useState } from "react"
import axios from 'axios'
import { GridLoader } from 'react-spinners'
import { toast } from "react-toastify"

var axiosReq = axios.create({
    timeout: 15000,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
})

function RegStep6(props) {

    const { activeScreen, initUUID, setActiveScreen, handleSetRegtoken } = props
    const timer = useRef(null)

    const [isConfirmed, setIsConfirmed] = useState(false)
    const [isOnline, setIsOnline] = useState(false)
    const [isExpired, setIsExpired] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        clearInterval(timer.current)
        if(initUUID != null && initUUID !== "null" && !isExpired) {
            timer.current = setInterval(() => {
                axiosReq.get(`https://ipanel.okeoke.io/api/v1/brand/init/state/${initUUID}`).then(res => {
                    if(res.data.success) {
                        if(res.data.data.isConfirmed) {
                            setIsConfirmed(true)
                        }
                        if(res.data.data.isOnline) {
                            setIsOnline(true)
                            localStorage.removeItem("initUUID")
                            localStorage.removeItem("reguserEmail")
                            localStorage['autoActivateBrand'] = res.data.data.state.serviceID
                            localStorage['firstStartTutorialOn'] = 1
                            handleSetRegtoken(res.data.data.token)
                        }
                        if(res.data.data.isExpired) {
                            setIsExpired(true)
                            localStorage.removeItem("initUUID")
                            localStorage.removeItem("reguserEmail")
                        }
                    } else {
                        console.log(res.data)
                        setError(true)
                    }
                })
            }, 2000)
        }        
        return () => clearInterval(timer.current)
        // eslint-disable-next-line
    }, [initUUID])

    const goToLoggedInScreen = () => {
        setActiveScreen("brandSettings")
    }

    const handleReset = () => {
        localStorage.removeItem("initUUID")
        localStorage.removeItem("reguserEmail")
        window.location.reload()
    }

    const cancel = () => {
        try {
            let body = { initUUID }
            axiosReq.post(`https://ipanel.okeoke.io/api/v1/brand/init/cancel`, body).then(res => {
                if(res.data.success) {
                    toast.success("Megszakítva.")
                } else {
                    toast.error("Hiba történt!")
                    console.log(res.data)
                }
            })
        } catch(e) {
            console.log(e)
        }
    }

    if(activeScreen !== "reg-step6") return null

    if(error) return (
        <div className="device-login-reg-step step6">
            <div className="welcome-message highlighted">Hiba történt</div>
            <div className="hidden appear welcome-message2">A folyamatot újra kell kezdeni.</div>
            <div className="hidden appear  btn-container">
                <div className="btn highlighted" onClick={handleReset}>Újrakezdés</div>
            </div>
        </div>
    )

    if(isExpired) return (
        <div className="device-login-reg-step step6">
            <div className="welcome-message highlighted">Regisztráció határideje lejárt</div>
            <div className="hidden appear welcome-message2">A folyamatot újra kell kezdeni.</div>
            <div className="hidden appear  btn-container">
                <div className="btn highlighted" onClick={() => window.location.reload()}>Újrakezdés</div>
            </div>
        </div>
    )

    return(
        <div className="device-login-reg-step step6">
            {!isConfirmed && !isOnline && <>
                <div className="welcome-message highlighted">Megerősítés szükséges</div>
                <div className="welcome-message2">Kiküldtük a megerősítéshez szükséges emailt a következő címre:</div>
                <div className="welcome-message email">{localStorage['reguserEmail']}</div>
                <div className="welcome-message2">Kérjük nyisd meg az e-mailed és kattints az aktiváló linkre.</div>
                <GridLoader color="#3F9AB7"/>
                <div className="cancel-progress">
                    <div className="text">A folyamatot megszakíthatod az alábbi gomb megnyomásával. Ez esetben a megadott adataidat töröljük a rendszerből.</div>
                    <div className="btn btn-small" onClick={cancel}>Megszakítás</div>
                </div>
            </>}
            {isConfirmed && !isOnline && <>
                <div className="welcome-message highlighted">Brand készítés folyamatban</div>
                <div className="hidden appear welcome-message2"></div>
                <GridLoader color="#3F9AB7"/>
            </>}
            {isConfirmed && isOnline && <>
                <div className="welcome-message highlighted">Brand elkészült</div>
                <div className="hidden appear welcome-message2">Tovább az eszköz aktiváláshoz</div>
                <div className="hidden appear  btn-container">
                    <div className="btn highlighted" onClick={goToLoggedInScreen}>Eszköz aktiválás</div>
                </div>
            </>}
        </div>
    )
}
export default RegStep6