import React, { useRef, useContext } from "react"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import { ModalContext } from "contexts/ModalContext"

function InputFieldSelect(props) {
  const { addModal, popModal } = useContext(ModalContext);
  const {
    label,
    errorText,
    className,
    options, //[{key,value}]  //a listElements helyett
    onChange,
    name,
    value, //aktiv
    displayedDefaultName,
    disabled,
    fullWidth
  } = props;

  const ref = useRef();

  const returnErrorText = () => {
    if (errorText.constructor !== Array) {
      return (
        <div className="error-row" style={{ bottom: -22 }}>
          {errorText}
        </div>
      );
    }
    let temp = errorText.map((row, index) => {
      return (
        <div
          key={index}
          className="error-row"
          style={{ bottom: -index * 18 - 22 }}
        >
          {row}
        </div>
      );
    });

    return temp;
  };

  const openModal = () => {
    if(disabled) return null

    addModal(
      <Modal
        key={uuidv4()}
        className={`select-modal ${className}-modal`}
        onClickLayout={popModal}
        parentRef={ref}
      >
        
        <div className="inputFieldSelectList">{deployDataListElements()}</div>
      </Modal>
    );
  };

  const handleClickSelect = (element) => {
    if (typeof onChange === "function") onChange({ target:{ value: element.value, name} });
    popModal()
  };

  const deployDataListElements = () => {
    if (!options) return null;
    let temp = options.map((element, index) => {
      return (
        <label
          key={index}
          onClick={(e) => {
            handleClickSelect(element);
          }}
          className={`list-element ${value === element.value ? "selected" : ""}`}
        >
          {element.title}
        </label>
      );
    });
    return temp;
  };

  const returnTitle = (key) => {
    let temp = displayedDefaultName;
    for (let row of ( options || [])) {
      if (row.value === key) {
        temp = row.title;
      }
    }
    return temp;
  };

  return (
    <div
      className={`textField-root input-field-select-main-container ${disabled ? "disabled" : ""} ${errorText ? "show-error" : ""} ${className ? className : ""} ${fullWidth ? "fullWidth" : ""}`}
    >
      <label className="textField-label shrink">{label}</label>
      {errorText && returnErrorText()}
      <div ref={ref} className="textField-base custom-select" onClick={openModal}>
        {returnTitle(value)}
      </div>
    </div>
  );
}

export default InputFieldSelect;
