import { useState, createContext, useEffect, useContext } from "react"
import { useItems, useClient, useLocation } from "okeoke.client"
//import { InputHandlerContext } from "contexts/InputHandlerProvider"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import { toast } from "react-toastify"
import moment from "moment"
import useMultilang from "intl/useMultilang"
import useLocationsApi from "apis/useLocationsApi"

/*
Feature list:
fiscalPrinter 
itemModifiersAndSizes
localDiscounts
multiBasket
multiUser
orderManager
posPrinter
reports
tableManager
vip
*/

const MainContext = createContext()

function MainContextProvider(props) {

  //const { machineValue } = useContext(InputHandlerContext)
  //const { loginUser } = useDevice()
  const { itemCount } = useContext(SelectedOrderContext)
  const { categoryTree } = useItems()  
  const { location } = useLocation()
  const { setLocationDayState } = useLocationsApi()
  const { socketConnected, features } = useClient()
  const { getT } = useMultilang()

  const [selectedCategUUID, setSelectedCategUUID] = useState(null)
  const [paymentStartAllowed, setPaymentStartAllowed] = useState(true)
  const [offlineOpen, setOfflineOpen] = useState(false)
  const [reloadNeeded, setReloadNeeded] = useState(false)
  const [openOrdersType, setOpenOrdersType] = useState("openOrders")
  const [settingsWizardDone, setSettingsWizardDone] = useState(localStorage['settingsWizardDone'])
  const [featureList, setFeatureList] = useState({})

  useEffect(() => {
    let allowed = itemCount > 0 && (offlineOpen || (location?.openState?.dayState != null && location?.openState?.dayState?.open))
    setPaymentStartAllowed(allowed)
  }, [itemCount, location, offlineOpen])

  useEffect(() => {
    if(socketConnected) {
      changeOfflineOpen(false)
    }
  }, [socketConnected])

  useEffect(() => {
    setFeatureList({...features})
  }, [features])

  // useEffect(() => {
  //   if(machineValue !== null) loginUser(null, machineValue)
  //   // eslint-disable-next-line
  // }, [machineValue])

  useEffect(() => {
    if(categoryTree && !selectedCategUUID) selectFirstValidCategory()
    // eslint-disable-next-line
  }, [categoryTree])

  const handleSelectCategory = (uuid) => {
    if(selectedCategUUID !== uuid) setSelectedCategUUID(uuid)
  }

  const selectFirstValidCategory = () => {
    let validCategs = categoryTree?.filter(categ => categ.availability.availableItems > 0)
    if(validCategs?.length > 0) setSelectedCategUUID(validCategs?.[0]?.uuid)
  }

  const changeOfflineOpen = (value) => {
    setOfflineOpen(value)
  }

  const openDay = (timeStamp, callback) => {

    let dateTime = moment(timeStamp)

    const day = {
      eventType: 1,
      eventTimestamp: timeStamp,
      accDate: dateTime.format("YYYY-MM-DD"),
      override: 1
    }

    setLocationDayState(day).then(res => {
      if(res.success) {
        console.log("Day opened", res)
        toast.success("Day opened!")
      } else {
        console.log("Open day error", res)
        toast.error(getT(`openDayError.${res.data.errorCode}`))
      }
      if(typeof callback === "function") callback(res)
    })
  }

  const closeDay = (accDate, eventTimestamp, callback) => {

    const day = {
      eventType: 2,
      eventTimestamp,
      accDate,
      override: 1
    }

    setLocationDayState(day).then(res => {
      if(res.success) {
        console.log("Day closed", res)
        toast.success("Day closed!")
      } else {
        console.log("Close day error", res)
        toast.error(getT(`openDayError.${res.data.errorCode}`))
      }
      if(typeof callback === "function") callback(res)
    })
  }

  return (
    <MainContext.Provider
      value={{
        selectedCategUUID,
        handleSelectCategory,
        openDay,
        closeDay,
        offlineOpen,
        changeOfflineOpen,
        paymentStartAllowed,
        openOrdersType, 
        setOpenOrdersType,
        setReloadNeeded,
        reloadNeeded,
        featureList,
        setSettingsWizardDone,
        settingsWizardDone
      }}
    >
      {props.children}
    </MainContext.Provider>
  )
}

export { MainContextProvider, MainContext }
