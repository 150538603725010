import LoadSvg from "components/LoadSvg-v2"
import CreateNewCategory from "views/Editor/CreateNewCategory"
import { useContext } from "react"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import { ModalContext } from "contexts/ModalContext"
import useMultilang from "intl/useMultilang"
//import { useNavigate } from "react-router-dom"
import { isMobileOnly } from "react-device-detect"

function Header() {
  
  const { addModal, popModal } = useContext(ModalContext)
  const { getT } = useMultilang()
  //const navigate = useNavigate()

  const openCreateCategoryInModal = () => {
    addModal(
      <Modal
        key={uuidv4()}
        className="create-new-category-modal"
        onClickLayout={popModal}
      >
        <CreateNewCategory />
      </Modal>
    )
  }

  // const openCreateItemInModal = () => {
  //   addModal(
  //     <Modal
  //       key={uuidv4()}
  //       className="create-new-item-modal"
  //       onClickLayout={popModal}
  //     >
  //       <CreateNewItem />
  //     </Modal>
  //   )
  // }

  return (
    <div className="editor-header-container-root">
      {/* <div className="editor-header-left-side-container" onClick={() => navigate("/")}>
        <div className={`icon-btn`} >
          <LoadSvg name="EditorModeIcon" />
        </div>
        <div className="text">{getT("sell.edit.MenuEditor")}</div>
      </div> */}
      <div className="editor-header-right-side-container">
        <div className="btn btn-highlighted" onClick={openCreateCategoryInModal}>
          <div className="icon-btn">
            <LoadSvg name={"subCateg"} />
          </div>
          <div className="text">{isMobileOnly ? "+" : getT("sell.edit.CreateCategory")}</div>
        </div>
        {/* <div className="btn btn-highlighted" onClick={openCreateItemInModal}>
          <div className="icon-btn">
            <LoadSvg name={"CreateNewItemIcon"} />
          </div>
          <div className="text">{getT("sell.edit.CreateItem")}</div>
        </div> */}
      </div>
    </div>
  )
}

export default Header
