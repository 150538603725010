import useMultilang from "intl/useMultilang"


function CreateCategory(props) {

    const { getT } = useMultilang()

    return(
        <div className="create-categ-root">
            <div className="text-box border-glow-effect">
                {getT("tutorial.createCategoryText")}
            </div>
        </div>
    )
}
export default CreateCategory