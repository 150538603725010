function DashboardBox(props) {

    const { label, value, className } = props

    return(
        <div className={`dashboard-box ${className == null ? "" : className}`}>
            <div className="value">{value}</div>
            <div className="label">{label}</div>
        </div>
    )
}
export default DashboardBox