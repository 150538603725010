import React from 'react'
import ModifierGroupMultiple from './ModifierGroupMultiple'
import ModifierGroupRadio from './ModifierGroupRadio'
import useMultilang from "intl/useMultilang"

function Modifiers(props) {

    const { modifierGroups, setModifierCount } = props
    const { language, getT } = useMultilang()

    if(modifierGroups == null) return null

    if(modifierGroups.length === 0) return <div className="no-group">{getT("modifiers.noGroup")}</div>

    return (
        modifierGroups.map((modGroup, index) => {
            return (<div key={index} className="modifier-group">
                {modGroup.type === 1 &&
                    <ModifierGroupRadio
                        name={modGroup.name?.[language]}
                        modifiers={modGroup?.modifiers}
                        setModifierCount={setModifierCount}
                    />}
                {modGroup.type === 2 &&
                    <ModifierGroupMultiple
                        group={modGroup}
                        modifiers={modGroup?.modifiers}
                        setModifierCount={setModifierCount}
                    />}
            </div>)
        })
    )
}
export default Modifiers