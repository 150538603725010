import { useState, useEffect, useContext } from "react"
import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import { useNavigate } from "react-router-dom"

function SelectedTableHeader(props) {

    const { selectedTableUUID, orders } = props

    const { getT } = useMultilang()

    const { setTable } = useContext(SelectedOrderContext)

    const navigate = useNavigate()

    const [tableOrders, setTableOrders] = useState(null)
    const [discountPriceSum, setDiscountPriceSum] = useState(0)
    const [paymentsNeededSum, setPaymentsNeededSum] = useState(0)
    const [paymentsTotalSum, setPaymentsTotalSum] = useState(0)

    useEffect(() => {
        if(orders != null) {
            let obj = {}
            let discountSum = 0
            let paymentsNeedSum = 0
            let paymentsTotSum = 0
            for(let order of orders) {
                if(order.tableUUID === selectedTableUUID) {
                    obj[order.uuid] = order
                    discountSum += +order.discountPrice
                    paymentsNeedSum += +order.paymentsNeeded
                    paymentsTotSum += +order.paymentsTotal
                }
            }
            setTableOrders(obj)
            setDiscountPriceSum(discountSum)
            setPaymentsNeededSum(paymentsNeedSum)
            setPaymentsTotalSum(paymentsTotSum)
        }
        // eslint-disable-next-line
    }, [orders])

    const startNewOrderOnTable = () => {
        setTable(selectedTableUUID)
        navigate("/")
    }

    if(tableOrders == null) return null

    if(selectedTableUUID === "") return  <div className="order-view-table-header">{getT("orderManager.noTableSelected")}</div>

    return(
        <div className="order-view-table-header">
            <div className="data-block">
                <div className="label">{getT("orderManager.tableDiscountPriceSum")}</div>
                <div className="value">
                    <NumberFormat
                        value={+discountPriceSum}
                        decimalSeparator=","
                        suffix=" Ft"
                        thousandSeparator="."
                        displayType="text"
                    />
                </div>
            </div>
            <div className="data-block">
                <div className="label">{getT("orderManager.tablePaymentsNeededSum")}</div>
                <div className="value">
                    <NumberFormat
                        value={+paymentsNeededSum}
                        decimalSeparator=","
                        suffix=" Ft"
                        thousandSeparator="."
                        displayType="text"
                    />
                </div>
            </div>
            <div className="data-block">
                <div className="label">{getT("orderManager.tablePaymentsTotalSum")}</div>
                <div className="value">
                    <NumberFormat
                        value={+paymentsTotalSum}
                        decimalSeparator=","
                        suffix=" Ft"
                        thousandSeparator="."
                        displayType="text"
                    />
                </div>
            </div>
            <div className="start-new-order">
                <div className="btn" onClick={startNewOrderOnTable}>{getT("orderManager.startNewOrderOnTable")}</div>
            </div>
        </div>
    )
}
export default SelectedTableHeader