import { useState, useContext, useEffect } from 'react'
import TextField from 'components/TextField'
import { ModalContext } from 'contexts/ModalContext'
import { v4 as uuidv4 } from 'uuid'
import Modal from 'components/Modal/Modal'
import DatePickerModal from 'components/Modal/DatePickerModal'
import moment from 'moment'

function DateRangeSelector(props) {

  const { fromDate, toDate, label, onRangeSelected, name } = props
  const { addModal, popModal } = useContext(ModalContext)
  const [values, setValues] = useState("")

  useEffect(() => {
    let startDateString = moment(fromDate).format('YYYY-MM-DD')
    let endDateString = moment(toDate).format('YYYY-MM-DD')
    setValues(`${startDateString} / ${endDateString}`)
    // eslint-disable-next-line
  }, [])

  const handleRangeSelected = (e) => {
    let startDateString = moment(e.fromDate).format('YYYY-MM-DD')
    let endDateString = moment(e.toDate).format('YYYY-MM-DD')
    setValues(`${startDateString} / ${endDateString}`)
    onRangeSelected(e)
    popModal()
  }

  const openDatePickerModal = () => {
    addModal(
      <Modal key={uuidv4()} className="datePicker-modal" onClickLayout={popModal}>
        <DatePickerModal 
          fromDate={fromDate} 
          toDate={toDate} 
          name={name} 
          onRangeSelected={handleRangeSelected}
        />
      </Modal>
    )
  }

  return (
    <TextField  
      type={'Text'} 
      label={label} 
      value={values} 
      disabled
      onClick={openDatePickerModal}
      className=""
      fullWidth
    />
  )
}

export default DateRangeSelector