import LoadSvg from "components/LoadSvg-v2"
import useMultilang from "intl/useMultilang"

function HeaderPresentation(props) {

    const { goToNext } = props

    const { getT } = useMultilang()

    return(
        <div className="header-presentation-root">
            <div className="text-box border-glow-effect menu-toggler-box">
                <div className="icon-container"><LoadSvg name="menu" /></div>
                {getT("tutorial.menuTogglerText")}
            </div>
            <div className="text-box border-glow-effect others-box">
                {getT("tutorial.otherHeaderFeatures")}
            </div>
            <div className="text-box border-glow-effect instruction-box">
                <div className="text">{getT("tutorial.headerInstruction")}</div>
                <div className="btn btn-highlighted border-glow-effect pulsate-fwd" onClick={goToNext}>{getT("tutorial.headerInstructionNextBtn")}</div>
            </div>
        </div>
    )
}
export default HeaderPresentation