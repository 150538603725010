import { useEffect } from "react"
import LoadSvg from "components/LoadSvg-v2"
import { useState } from "react"
import Activate from "./Activate"
import Welcome from "./Welcome"
import RegStep1 from "./RegStep1"
import RegStep2 from "./RegStep2"
import RegStep3 from "./RegStep3"
import RegStep4 from "./RegStep4"
import RegStep5 from "./RegStep5"
import RegStep6 from "./RegStep6"
import BrandSettings from "./BrandSettings"
import LoginUser from "./LoginUser"
import config from "config"
import axios from "axios"
import { isMobileOnly } from "react-device-detect"

function RegisterDevice(props) {

    const { title, error, requestCode, checkPin, onLogin } = props

    const [activeScreen, setActiveScreen] = useState("welcome")
    const [initUUID, setInitUUID] = useState(localStorage['initUUID'])
    const [posToken, setPosToken] = useState(localStorage['pos-token'])
    const [userData, setUserData] = useState({
        accountOwner: {
            firstName: "",
            lastName: "",
            email: "",
            telNumber: ""
        },
        company: {
            taxNumber: "",
            companyID: "",
            name: "",
            shortName: "",
            address: {
                zip: "",
                city: "",
                floor: "",
                state: "",
                street: "",
                telNumber: "",
                countryCode: "HU",
                streetNumber: ""
            }
        },
        location: {
            name: "",
            description: "",
            address: {
                zip: "",
                city: "",
                floor: "",
                state: "",
                street: "",
                telNumber: "",
                countryCode: "HU",
                streetNumber: ""
            },
            geolocation: {
                lat: "47.5014668",
                lng: "19.0635207"
            }
        },
        options: {
            devices: {
                createPos: true
            }
        },
        inviteUUID: ""
    })

    useEffect(() => {
        if(initUUID != null && initUUID !== "null") {
            setActiveScreen("reg-step6")
        }
    }, [initUUID])

    useEffect(() => {

    }, [])

    const handleChangeUserData = (field, value) => {
        setUserData({...userData, [field]: value})
    }

    const handleSetInitUUID = (uuid) => {
        setInitUUID(uuid)
        localStorage['initUUID'] = uuid
    }

    const handleSetRegtoken = (token) => {
        setPosToken(token)
        localStorage['pos-token'] = token
    }

    const activateDemo = () => {
        axios.post('https://ipanel.okeoke.io/api/v1/auth/generateDemoDeviceToken', {
            'brandID': 'xkg0gps7',
            'deviceType': 3,
            'locationUUID': '11ED43C1D8D26690B71E25CE6111D8F4'
        }).then(res => {
            if (res.data.success) {
                onLogin(res.data.data.token, res.data.data.apiUrl)
            } else {
                console.log(res.data)
            }
        })
    }

    const reset = () => {
        window.location.reload()
    }

    if(posToken != null) return (
        <div className="device-login-main">
            <BrandSettings checkPin={checkPin} setActiveScreen={setActiveScreen} posToken={posToken}/>
        </div>
    )

    if(activeScreen === "") return <div className="device-login-main"><div className="btn" onClick={reset}>Tovább</div></div>

    return(
        <div className={`device-login-main ${isMobileOnly ? "mobile" : ""}`}>
            <div className="refresh-svg"><LoadSvg name="refresh" onClick={() => window.location.reload() } /></div>
            <div className="left-side">
                <img src="https://cdn.okeoke.io/pos/content_1673598837.png" alt="posbg" />
                <div className="text-box">
                    <div className="title">Asztalhoz rendelés akár percek alatt</div>
                    <div className="text">Próbáld ki a WebApp megoldásunkat, aminek a segítségével vendégeid könnyen és gyorsan tudnak rendelést leadni</div>
                    <div className="text">Részletek: barsoft.hu</div>
                </div>
            </div>
            <div className="right-side">
                <Welcome activeScreen={activeScreen} setActiveScreen={setActiveScreen} requestCode={requestCode} activateDemo={activateDemo} />
                <LoginUser activeScreen={activeScreen} setActiveScreen={setActiveScreen} />
                <RegStep1 activeScreen={activeScreen} setActiveScreen={setActiveScreen} handleChangeUserData={handleChangeUserData} userData={userData.accountOwner} />
                <RegStep2 activeScreen={activeScreen} setActiveScreen={setActiveScreen} handleChangeUserData={handleChangeUserData} userData={userData.accountOwner} />
                <RegStep3 activeScreen={activeScreen} setActiveScreen={setActiveScreen} handleChangeUserData={handleChangeUserData} userData={userData.company} />
                <RegStep4 activeScreen={activeScreen} setActiveScreen={setActiveScreen} handleChangeUserData={handleChangeUserData} userData={userData.location} />
                <RegStep5 activeScreen={activeScreen} setActiveScreen={setActiveScreen} handleChangeUserData={handleChangeUserData} userData={userData} setInitUUID={handleSetInitUUID} />
                <RegStep6 activeScreen={activeScreen} setActiveScreen={setActiveScreen} initUUID={initUUID} handleSetRegtoken={handleSetRegtoken} />
                <Activate activeScreen={activeScreen} title={title} error={error} requestCode={requestCode} checkPin={checkPin} />
            </div>
            <div className="pos-version">{`v${config?.version || ""}`}</div>
        </div>
    )
}
export default RegisterDevice