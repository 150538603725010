import { useContext, useState } from "react"
import { useClient } from "okeoke.client"
import { PersonalSettingsContext } from "contexts/PersonalSettingsProvider"
import { Link } from 'react-scroll'
import { isMobileOnly } from "react-device-detect"

function Pagination(props) {

  const { selectableItems } = props

  const { getName } = useClient()
  const { customization } = useContext(PersonalSettingsContext)

  const [selectedItem, setSelectedItem] = useState((selectableItems || [])[0]?.uuid)

  const handleSetActive = (e) => {
    setSelectedItem(e.split("_")[1])
  }
  
  if (customization.paginationOff || isMobileOnly) return null

  return (
    <div className={`pagination-root ${customization?.categoriesTop ? "categories-top" : "categories-left"} ${customization?.sellRightToLeft ? "sell-right-to-left" : ""}`}>
      <div className="background-layer"></div>
      {selectableItems.map((item, index) => {
        let name = getName(item.name)
        return (
          <div key={index} className={`hidden appear pagination-element ${selectedItem === item.uuid ? "selected" : ""}`}>
            <div className="inner-bg"></div>
            <Link 
              to={`order-items-item_${item.uuid}`}
              containerId="order-items-container-id"
              smooth={true}
              duration={500}
              spy={true}
              onSetActive={handleSetActive}
              offset={-12}
            >
              {name.length > 12 ? `${name.substring(0, 9)}...` : name}
            </Link>
          </div>
        )
      })}
    </div>
  )
}
export default Pagination