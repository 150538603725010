import { useState } from "react"
import moment from "moment"
import useMultilang from "intl/useMultilang"

function OpenModal(props) {

    const { openDay, popModal, dayState } = props

    const { getT } = useMultilang()

    const [openTimestamp, setOpenTimestamp] = useState(moment())

    const handleOpenDay = () => {
        openDay(openTimestamp.toISOString())
        popModal()
    }

    const handleDateChange = (e) => {
        
        if(moment(e.target.value).isBefore(dayState?.lastCloseTimestamp, "minutes")) return null
        if(moment(e.target.value).isSame(dayState?.lastCloseTimestamp, "minutes")) return null

        setOpenTimestamp(moment(e.target.value))
    }

    return(
        <div className="work-day-modal">
            <div className="title">{getT("workDayModal.open.title")}</div>
            <div className="last-open">
                <div className="label">{getT("workDayModal.open.lastClose")}</div>
                <div className="date-time-container">
                    <input type="datetime-local" value={moment(dayState?.lastCloseTimestamp).format("YYYY-MM-DD HH:mm")} disabled/>
                </div>
            </div>
            <div className="last-open">
                <div className="label">{getT("workDayModal.open.openTime")}</div>
                <div className="date-time-container">
                    <input type="datetime-local" value={openTimestamp.format("YYYY-MM-DD HH:mm")} onChange={handleDateChange}/>
                </div>
            </div>
            <div className="buttons-container">
                <div className="btn" onClick={popModal}>{getT("workDayModal.open.cancel")}</div>
                <div className="btn btn-highlighted" onClick={handleOpenDay}>{getT("workDayModal.open.open")}</div>
            </div>
        </div>
    )
}
export default OpenModal