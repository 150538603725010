import { useState, useEffect } from "react"
import useMultilang from "intl/useMultilang"
import { useNavigate } from "react-router-dom"
import { useDevice, usePrinter } from "okeoke.client"
import OpenDay from 'views/Admin/FiscatAdmin/OpenDay'

function FiscalPrinterOverlay() {

    const { getT } = useMultilang()
    const navigate = useNavigate()
    const { deviceParams } = useDevice()
    const { generalError, dayOpened } = usePrinter()

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if(deviceParams?.printer?.type != null && deviceParams?.printer?.type !== "") {
            setVisible(generalError === "1" || dayOpened === "0")
        }
    }, [generalError, deviceParams, dayOpened])

    const handleGoSettings = () => {
        navigate("admin/fiscal-printer")
    }

    const handleClickLater = () => {
        setVisible(false)
    }

    if(!visible) return null

    return(
        <div className="fiscal-printer-overlay">
            <div className="title">{getT("fiscalPrinterOverlay.title")}</div>
            <div className="message">{getT("fiscalPrinterOverlay.message")}</div>
            <div className="buttons-container">
                <div className="btn" onClick={handleClickLater}>{getT("fiscalPrinterOverlay.later")}</div>
                <div className="btn btn-highlighted" onClick={handleGoSettings}>{getT("fiscalPrinterOverlay.goToSettings")}</div>
            </div>
            {dayOpened === "0" && <>
                <div className="message">{getT("fiscalPrinterOverlay.dayNotOpened")}</div>
                <OpenDay />
            </>}
        </div>
    )
}
export default FiscalPrinterOverlay