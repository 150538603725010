import { useState, useEffect } from "react"
import moment from "moment"
import DataTable from "components/DataTable"
import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"
import ReportsMenu from "./ReportsMenu"
import ReportsHead from "./ReportsHead"
import { Doughnut } from "react-chartjs-2"
import { Chart, registerables } from "chart.js"
import useStatsApi from "apis/useStatsApi"

function VatSum() {

    Chart.register(...registerables)

    const { getOrdersByVat } = useStatsApi()
    const { getT } = useMultilang()

    const [list, setList] = useState(null)
    const [labels, setLabels] = useState(null)
    const [dataset, setDataset] = useState(null)
    const [sumAmount, setSumAmount] = useState(null)
    const [sumNetAmount, setNetSumAmount] = useState(null)
    const [sumVatAmount, setVatSumAmount] = useState(null)
    const [filter, setFilter] = useState({from: `${moment().format("YYYY-MM-DD")} 00:00:00`, to: `${moment().format("YYYY-MM-DD")} 23:59:59`})

    useEffect(() => {
      loadVatSum()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
      loadVatSum()
        // eslint-disable-next-line
    }, [filter])


    useEffect(() => {
      if(list != null) {

        setLabels(list.map(item => `${item.vat} %`))

        let data1 = {
          label: getT("reports.vatGroups.hourlyChart.percent"),
          data: list.map(row => row.discountPriceSum),
          backgroundColor: [
            "rgba(255, 159, 64, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 99, 132), 0.2"
          ],
          borderColor: [
            "rgba(255, 159, 64)",
            "rgba(54, 162, 235)",
            "rgba(255, 99, 132)"
          ],
          borderWidth: 1,
        }
        setDataset(data1)
      }
      // eslint-disable-next-line
    }, [list])

    const loadVatSum = () => {

      let filters = { fromDate: filter.from, toDate: filter.to }

      getOrdersByVat(filters).then(res => {
        if(res.success) {
          setList(res.data)
          let sum = 0
          let sum2 = 0
          let sum3 = 0
          for(let item of res.data) {
            sum = sum + parseInt(item.discountPriceSum)
            let netValue = Math.round(parseInt(item.discountPriceSum) / ((parseFloat(item.vat)/100)+1))
            sum2 = sum2 + netValue
            sum3 = sum3 + (item.discountPriceSum - netValue)
          }
          setSumAmount(sum)
          setNetSumAmount(sum2)
          setVatSumAmount(sum3)
        } else {
          console.log(res)
        }
      })
    }

    const columns = [
        {
          name: "name",
          label: getT("reports.vatGroup"),
          options: {
            className: "head-align-left cell-align-left",
            sort: false,
            format: row => `${row.vat} %`,
            type: false,
          },
        },
        {
          name: "amount",
          label: getT("reports.vatGroupSum"),
          options: {
            className: "head-align-right cell-align-right",
            sort: false,
            format: row => 
              <NumberFormat
                value={parseInt(row.discountPriceSum)}
                decimalSeparator=","
                suffix=" Ft"
                thousandSeparator="."
                displayType="text"
              />,
            type: false,
          },
        },
        {
          name: "netAmount",
          label: getT("reports.vatGroupNetSum"),
          options: {
            className: "head-align-right cell-align-right",
            sort: false,
            format: row => 
              <NumberFormat
                value={Math.round(parseInt(row.discountPriceSum) / ((parseFloat(row.vat)/100)+1))}
                decimalSeparator=","
                suffix=" Ft"
                thousandSeparator="."
                displayType="text"
              />,
            type: false,
          },
        },
        {
          name: "vatAmount",
          label: getT("reports.vatGroupVatSum"),
          options: {
            className: "head-align-right cell-align-right",
            sort: false,
            format: row => 
              <NumberFormat
                value={row.discountPriceSum - (Math.round(parseInt(row.discountPriceSum) / ((parseFloat(row.vat)/100)+1)))}
                decimalSeparator=","
                suffix=" Ft"
                thousandSeparator="."
                displayType="text"
              />,
            type: false,
          },
        }
    ]

    const handleDateChange = (values) => {
      setFilter(values)
    }

    return(
      <div className="reports-container vat-sum-container">
        <ReportsHead title={getT("reports.vatSum.title")} handleDateChange={handleDateChange} filter={filter} />
        <div className="body">
          <div className="box-container">
            <div className="box">
              <div className="label">{getT("reports.vatSum.sumAmount")}</div>
              <div className="value">
                <NumberFormat
                  value={parseInt(sumAmount)}
                  decimalSeparator=","
                  suffix=" Ft"
                  thousandSeparator="."
                  displayType="text"
                />
              </div>
            </div>
            <div className="box">
              <div className="label">{getT("reports.vatSum.sumNetAmount")}</div>
              <div className="value">
                <NumberFormat
                  value={parseInt(sumNetAmount)}
                  decimalSeparator=","
                  suffix=" Ft"
                  thousandSeparator="."
                  displayType="text"
                />
              </div>
            </div>
            <div className="box">
              <div className="label">{getT("reports.vatSum.sumVatAmount")}</div>
              <div className="value">
                <NumberFormat
                  value={parseInt(sumVatAmount)}
                  decimalSeparator=","
                  suffix=" Ft"
                  thousandSeparator="."
                  displayType="text"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="table-container">
              <DataTable data={list} columns={columns} />
            </div>
            <div className="chart-container">
              {list != null && labels != null && <Doughnut
                data={{
                  datasets: [dataset],
                  labels,
                }}
                width={"100%"}
                height={"100%"}
                options={{
                  plugins: {
                    title: {
                      display: false,
                      text: getT("reports.transactions.saleByHours"),
                    },
                  },
                  maintainAspectRatio: false,
                  responsive: true
                }}
              />}
            </div>
          </div>
        </div>
        <div className="footer">
          <ReportsMenu />
        </div>
      </div>
    )
}
export default VatSum