import Filter from "./Filter"
import SelectedOrder from "./SelectedOrder"
import OrderList from "./OrderList"
import { isMobileOnly } from "react-device-detect"
import { Swiper, SwiperSlide } from 'swiper/react'

function Orders() {

  /* Mobile version with swiper */
  if(isMobileOnly) return(
    <Swiper>
      <SwiperSlide>
        <div className="transactions-root">
          <Filter />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="transactions-root">
          <OrderList />
        </div>
      </SwiperSlide>
      <SwiperSlide>
          <SelectedOrder />
      </SwiperSlide>
    </Swiper>
  )

  /* Desktop version without swiper */
  return (
    <div className="transactions-root">
      <Filter />
      <OrderList />
      <SelectedOrder />
    </div>
  )
}
export default Orders