import { useContext } from "react"
import { MainContext } from "contexts/MainContext"
import useMultilang from "intl/useMultilang"
import LoadSvg from "components/LoadSvg-v2"
import { useItems } from "okeoke.client"

function BackToParentCateg() {

  const { selectedCategUUID, handleSelectCategory } = useContext(MainContext)
  const { categories } = useItems()
  const { getT, language } = useMultilang()

  const parentUUID = categories?.[selectedCategUUID]?.parentUUID;
  const categName = categories?.[categories?.[selectedCategUUID]?.parentUUID]?.name?.[language];

  if(selectedCategUUID == null || categories?.[selectedCategUUID]?.parentUUID == null || categories?.[selectedCategUUID]?.parentUUID === "") return null

  return (
    <div className={`order-item-container back-to`} onClick={() => handleSelectCategory(parentUUID)}>
      <div className="order-item-container-return-to-previous">
        <div className="return-to-previous">
          <div className="icon-btn">
            <LoadSvg name={"UpArrowCurveLeftIcon"} />
          </div>
          <div className="text">{getT("sell.edit.orderItems.BackHere")}:</div>
        </div>
        <div className="previous-categ-name">{categName}</div>
      </div>
    </div>
  )
}

export default BackToParentCateg
