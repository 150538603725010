import { useContext } from "react"
import { PersonalSettingsContext } from "contexts/PersonalSettingsProvider"
import useMultilang from "intl/useMultilang"
import LoadSvg from "components/LoadSvg-v2"

function DeviceCustomization() {

  const { getT } = useMultilang()
  const { customization, changeCustomization } = useContext(PersonalSettingsContext)

  const setItemColumns = (value) => {
    changeCustomization("orderItemsColumns", value)
    document.documentElement.style.setProperty(
      "--orderItemsColumns",
      //"1fr ".repeat(value)
      value
    )
  }

  const setItemHeight = (value) => {
    changeCustomization("orderItemHeight", value)
    document.documentElement.style.setProperty(
      "--orderItemHeight",
      `${value}em`
    )
  }

  const setItemFontSize = (value) => {
    changeCustomization("orderItemFontSize", value)
    document.documentElement.style.setProperty(
      "--orderItemFontSize",
      `${value}px`
    )
  }

  const setFontFamily = (value) => {
    changeCustomization("fontFamily", value)
    document.documentElement.style.setProperty(
      "--fontFamily",
      value
    )
  }

  const setItemFontWeight = (value) => {
    changeCustomization("orderItemFontWeight", value)
    document.documentElement.style.setProperty(
      "--orderItemFontWeight",
      `${value}`
    )
  }

  const setButtonColor = (value) => {
    changeCustomization("buttonColor", value)
    document.documentElement.style.setProperty(
      "--ten-color",
      `${value}`
    )
  }

  const setColorMode = (value) => {
    changeCustomization("colorMode", value)
    if(value === "light") {
      document.documentElement.style.setProperty(
        "--sixty-color",
        "#F5F5F5"
      )
      document.documentElement.style.setProperty(
        "--thirty-color",
        "#FAFAFA"
      )
      document.documentElement.style.setProperty(
        "--text-color",
        "#000000"
      )
      document.documentElement.style.setProperty(
        "--border-color",
        "#D9D9D9"
      )
      document.documentElement.style.setProperty(
        "--orderItemShadow",
        "drop-shadow(0 0 2px rgba(217, 217, 217, 0.66))"
      )
    }
    if(value === "dark") {
      document.documentElement.style.setProperty(
        "--sixty-color",
        "#252836"
      )
      document.documentElement.style.setProperty(
        "--thirty-color",
        "#1f1d2b"
      )
      document.documentElement.style.setProperty(
        "--text-color",
        "#FFFFFF"
      )
      document.documentElement.style.setProperty(
        "--header-background",
        "#17151F"
      )
      document.documentElement.style.setProperty(
        "--border-color",
        "#393C49"
      )   
      document.documentElement.style.setProperty(
        "--orderItemShadow",
        "unset"
      )   
    }
  }

  const setLayout = (col) => {
    changeCustomization("orderItemsColumns", col)
    document.documentElement.style.setProperty(
      "--orderItemsColumns",
      col
    )
  }

  const selectDeviceType = (type) => {
    switch(type) {
      case "mobile": setLayout(2)
      break
      case "tablet": setLayout(3)
      break
      case "pos": setLayout(4)
      break
      case "desktop": setLayout(8)
      break
      default:
    }
  }

  const setSellRightToLeft = (value) => {
    changeCustomization("sellRightToLeft", value)
  }

  const setCategoriesTop = (value) => {
    changeCustomization("categoriesTop", value)
    document.documentElement.style.setProperty(
      "--sellRootFlexDirection",
      value ? "column" : "row"
    )   
  }

  const setVerticalCategWidth = (value) => {
    changeCustomization("verticalCategWidth", value)
    document.documentElement.style.setProperty(
      "--verticalCategWidth", 
      `${value}px`
    )
  }

  const setItemImgOff = (value) => {
    changeCustomization("itemImgOff", value)
  }

  const setItemPaginationOff = (value) => {
    changeCustomization("paginationOff", value)
  }

  return (
    <div className="device-customization">
      <div className="col">
        <div className="layout-presets">
          <div className="title">{getT("settings.device.customization.layoutPresetsTitle")}</div>
          <div className="presets-container">
            <div className={`preset ${customization?.orderItemsColumns === 2 ? "selected" : ""}`} onClick={() => selectDeviceType("mobile")}><div className="inner-bg"></div><LoadSvg name="mobile" /></div>
            <div className={`preset ${customization?.orderItemsColumns === 3 ? "selected" : ""}`} onClick={() => selectDeviceType("tablet")}><div className="inner-bg"></div><LoadSvg name="tablet" /></div>
            <div className={`preset ${customization?.orderItemsColumns === 4 ? "selected" : ""}`} onClick={() => selectDeviceType("pos")}><div className="inner-bg"></div><LoadSvg name="pos2" /></div>
            <div className={`preset ${customization?.orderItemsColumns === 8 ? "selected" : ""}`} onClick={() => selectDeviceType("desktop")}><div className="inner-bg"></div><LoadSvg name="desktop" /></div>
          </div>
        </div>
        <div className="layout">
          <div className="title">{getT("settings.device.customization.layoutTitle")}</div>
          <p>{getT("settings.device.customization.itemColumns")}</p>
          <div className="item-columns">
            <div className={`item-column-btn ${customization?.orderItemsColumns === 1 ? 'selected' : ''}`} onClick={() => setItemColumns(1)}>
              <div className="inner-bg"></div>
              1
            </div>
            <div className={`item-column-btn ${customization?.orderItemsColumns === 2 ? 'selected' : ''}`} onClick={() => setItemColumns(2)}>
              <div className="inner-bg"></div>
              2
            </div>
            <div className={`item-column-btn ${(customization?.orderItemsColumns == null || customization?.orderItemsColumns === 3) ? 'selected' : ''}`} onClick={() => setItemColumns(3)}>
              <div className="inner-bg"></div>
              3
            </div>
            <div className={`item-column-btn ${customization?.orderItemsColumns === 4 ? 'selected' : ''}`} onClick={() => setItemColumns(4)}>
              <div className="inner-bg"></div>
              4
            </div>
            <div className={`item-column-btn ${customization?.orderItemsColumns === 5 ? 'selected' : ''}`} onClick={() => setItemColumns(5)}>
              <div className="inner-bg"></div>
              5
            </div>
            <div className={`item-column-btn ${customization?.orderItemsColumns === 6 ? 'selected' : ''}`} onClick={() => setItemColumns(6)}>
              <div className="inner-bg"></div>
              6
            </div>
            <div className={`item-column-btn ${customization?.orderItemsColumns === 7 ? 'selected' : ''}`} onClick={() => setItemColumns(7)}>
              <div className="inner-bg"></div>
              7
            </div>
            <div className={`item-column-btn ${customization?.orderItemsColumns === 8 ? 'selected' : ''}`} onClick={() => setItemColumns(8)}>
              <div className="inner-bg"></div>
              8
            </div>
          </div>
          <p>{getT("settings.device.customization.itemHeight")}</p>
          <div className="item-columns">
            <div className={`item-column-btn ${customization?.orderItemHeight === 5 ? 'selected' : ''}`} onClick={() => setItemHeight(5)}>
              <div className="inner-bg"></div>
              XS
            </div>
            <div className={`item-column-btn ${customization?.orderItemHeight === 6 ? 'selected' : ''}`} onClick={() => setItemHeight(6)}>
              <div className="inner-bg"></div>
              S
            </div>
            <div className={`item-column-btn ${(customization?.orderItemHeight == null || customization?.orderItemHeight === 8) ? 'selected' : ''}`} onClick={() => setItemHeight(8)}>
              <div className="inner-bg"></div>
              M
            </div>
            <div className={`item-column-btn ${customization?.orderItemHeight === 10 ? 'selected' : ''}`} onClick={() => setItemHeight(10)}>
              <div className="inner-bg"></div>
              L
            </div>
            <div className={`item-column-btn ${customization?.orderItemHeight === 12 ? 'selected' : ''}`} onClick={() => setItemHeight(12)}>
              <div className="inner-bg"></div>
              XL
            </div>
            <div className={`item-column-btn ${customization?.orderItemHeight === 14 ? 'selected' : ''}`} onClick={() => setItemHeight(14)}>
              <div className="inner-bg"></div>
              XXL
            </div>
          </div>
          <p>{getT("settings.device.customization.itemFontSize")}</p>
          <div className="item-columns">
            <div className={`item-column-btn font-size font-12 ${customization?.orderItemFontSize === 12 ? 'selected' : ''}`} onClick={() => setItemFontSize(12)}>
              <div className="inner-bg"></div>
              {getT("settings.device.customization.fontSize")}
            </div>
            <div className={`item-column-btn font-size font-14 ${customization?.orderItemFontSize === 14 ? 'selected' : ''}`} onClick={() => setItemFontSize(14)}>
              <div className="inner-bg"></div>
              {getT("settings.device.customization.fontSize")}
            </div>
            <div className={`item-column-btn font-size font-16 ${customization?.orderItemFontSize == null || customization?.orderItemFontSize === 16 ? 'selected' : ''}`} onClick={() => setItemFontSize(16)}>
              <div className="inner-bg"></div>
              {getT("settings.device.customization.fontSize")}
            </div>
            <div className={`item-column-btn font-size font-18 ${customization?.orderItemFontSize === 18 ? 'selected' : ''}`} onClick={() => setItemFontSize(18)}>
              <div className="inner-bg"></div>
              {getT("settings.device.customization.fontSize")}
            </div>
            <div className={`item-column-btn font-size font-20 ${customization?.orderItemFontSize === 20 ? 'selected' : ''}`} onClick={() => setItemFontSize(20)}>
              <div className="inner-bg"></div>
              {getT("settings.device.customization.fontSize")}
            </div>
          </div>
          <p>{getT("settings.device.customization.itemFontWeight")}</p>
          <div className="item-columns">
            <div className={`item-column-btn font-weight font-200 ${customization?.orderItemFontWeight === 200 ? 'selected' : ''}`} onClick={() => setItemFontWeight(200)}>
              <div className="inner-bg"></div>
              {getT("settings.device.customization.fontWeight")}
            </div>
            <div className={`item-column-btn font-weight font-400 ${customization?.orderItemFontWeight == null || customization?.orderItemFontWeight === 400 ? 'selected' : ''}`} onClick={() => setItemFontWeight(400)}>
              <div className="inner-bg"></div>
              {getT("settings.device.customization.fontWeight")}
            </div>
            <div className={`item-column-btn font-weight font-600 ${customization?.orderItemFontWeight === 600 ? 'selected' : ''}`} onClick={() => setItemFontWeight(600)}>
              <div className="inner-bg"></div>
              {getT("settings.device.customization.fontWeight")}
            </div>
            <div className={`item-column-btn font-weight font-800 ${customization?.orderItemFontWeight === 800 ? 'selected' : ''}`} onClick={() => setItemFontWeight(800)}>
              <div className="inner-bg"></div>
              {getT("settings.device.customization.fontWeight")}
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="button-colors">
          <div className="title">{getT("settings.device.customization.buttonColors")}</div>
          <div className="color-pickers">
            <div className={`color-picker ${(customization?.buttonColor === "#3F9AB7" || customization?.buttonColor == null) ? "selected" : ""}`} style={{backgroundColor: "#3F9AB7"}} onClick={() => setButtonColor("#3F9AB7")}>{getT("settings.device.customization.button")}</div>
            <div className={`color-picker ${customization?.buttonColor === "#EA7C69" ? "selected" : ""}`} style={{backgroundColor: "#EA7C69"}} onClick={() => setButtonColor("#EA7C69")}>{getT("settings.device.customization.button")}</div>
            <div className={`color-picker ${customization?.buttonColor === "#309D27" ? "selected" : ""}`} style={{backgroundColor: "#309D27"}} onClick={() => setButtonColor("#309D27")}>{getT("settings.device.customization.button")}</div>
            <div className={`color-picker ${customization?.buttonColor === "#BA2100" ? "selected" : ""}`} style={{backgroundColor: "#BA2100"}} onClick={() => setButtonColor("#BA2100")}>{getT("settings.device.customization.button")}</div>
            <div className={`color-picker ${customization?.buttonColor === "#8A00BA" ? "selected" : ""}`} style={{backgroundColor: "#8A00BA"}} onClick={() => setButtonColor("#8A00BA")}>{getT("settings.device.customization.button")}</div>
          </div>
        </div>
        <div className="color-mode">
          <div className="title">{getT("settings.device.customization.colorMode")}</div>
          <div className="mode-pickers">
            <div className={`mode-picker ${(customization?.colorMode === "dark" || customization?.colorMode == null) ? "selected" : ""}`} onClick={() => setColorMode("dark")}>
              <div className="inner-bg"></div>
              <LoadSvg name="moon" />
              <div className="text">{getT("settings.device.customization.darkMode")}</div>
            </div>
            <div className={`mode-picker ${customization?.colorMode === "light" ? "selected" : ""}`} onClick={() => setColorMode("light")} >
              <div className="inner-bg"></div>
              <LoadSvg name="sun" />
              <div className="text">{getT("settings.device.customization.lightMode")}</div>
            </div>
          </div>
        </div>
        <div className="font-title">{getT("settings.device.customization.fontFamily")}</div>
        <div className="item-columns font-family">
          <div className={`item-column-btn nunito ${customization?.fontFamily == null || customization?.fontFamily === "'Nunito', sans-serif" ? 'selected' : ''}`} onClick={() => setFontFamily("'Nunito', sans-serif")}>
            <div className="inner-bg"></div>
            Nunito
          </div>
          <div className={`item-column-btn barlow ${customization?.fontFamily === "'Barlow', sans-serif" ? 'selected' : ''}`} onClick={() => setFontFamily("'Barlow', sans-serif")}>
            <div className="inner-bg"></div>
            Barlow
          </div>
          <div className={`item-column-btn poppins ${customization?.fontFamily === "'Poppins', sans-serif" ? 'selected' : ''}`} onClick={() => setFontFamily("'Poppins', sans-serif")}>
            <div className="inner-bg"></div>
            Poppins
          </div>
          <div className={`item-column-btn montserrat ${customization?.fontFamily === "'Montserrat', sans-serif" ? 'selected' : ''}`} onClick={() => setFontFamily("'Montserrat', sans-serif")}>
            <div className="inner-bg"></div>
            Montserrat
          </div>
        </div>
        <div className="layout">
          <div className="title">{getT("settings.device.customization.layout")}</div>
          <div className="item-columns layout">
            <div className={`item-column-btn layout ${customization?.sellRightToLeft ? 'selected' : ''}`} onClick={() => setSellRightToLeft(!customization?.sellRightToLeft)}>
              <div className="inner-bg"></div>
              {getT("settings.device.customization.sellRightToLeftMode")}
            </div>
            <div className={`item-column-btn layout ${customization?.categoriesTop ? 'selected' : ''}`} onClick={() => setCategoriesTop(!customization?.categoriesTop)}>
              <div className="inner-bg"></div>
              {getT("settings.device.customization.categoriesTop")}
            </div>
          </div>
          <div className="item-columns layout">
            <div className={`item-column-btn layout ${(customization?.verticalCategWidth == null || customization?.verticalCategWidth === 130) ? 'selected' : ''}`} onClick={() => setVerticalCategWidth(130)}>
              <div className="inner-bg"></div>
              {`${getT("settings.device.customization.verticalCategWidth")} xs`}
            </div>
            <div className={`item-column-btn layout ${customization?.verticalCategWidth === 160 ? 'selected' : ''}`} onClick={() => setVerticalCategWidth(160)}>
              <div className="inner-bg"></div>
              {`${getT("settings.device.customization.verticalCategWidth")} s`}
            </div>
            <div className={`item-column-btn layout ${customization?.verticalCategWidth === 190 ? 'selected' : ''}`} onClick={() => setVerticalCategWidth(190)}>
              <div className="inner-bg"></div>
              {`${getT("settings.device.customization.verticalCategWidth")} m`}
            </div>
            <div className={`item-column-btn layout ${customization?.verticalCategWidth === 220 ? 'selected' : ''}`} onClick={() => setVerticalCategWidth(220)}>
              <div className="inner-bg"></div>
              {`${getT("settings.device.customization.verticalCategWidth")} l`}
            </div>
            <div className={`item-column-btn layout ${customization?.verticalCategWidth === 250 ? 'selected' : ''}`} onClick={() => setVerticalCategWidth(250)}>
              <div className="inner-bg"></div>
              {`${getT("settings.device.customization.verticalCategWidth")} xl`}
            </div>
            <div className={`item-column-btn layout ${customization?.verticalCategWidth === 280 ? 'selected' : ''}`} onClick={() => setVerticalCategWidth(280)}>
              <div className="inner-bg"></div>
              {`${getT("settings.device.customization.verticalCategWidth")} xxl`}
            </div>
          </div>
        </div>
        <div className="others">
          <div className="title">{getT("settings.device.customization.others")}</div>
          <div className="item-columns others">
            <div className={`item-column-btn other ${customization?.itemImgOff ? 'selected' : ''}`} onClick={() => setItemImgOff(!customization?.itemImgOff)}>
              <div className="inner-bg"></div>
              {getT("settings.device.customization.itemImgOff")}
            </div>
            <div className={`item-column-btn other ${customization?.paginationOff ? 'selected' : ''}`} onClick={() => setItemPaginationOff(!customization?.paginationOff)}>
              <div className="inner-bg"></div>
              {getT("settings.device.customization.pagination")}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default DeviceCustomization