import { useState, useEffect } from "react"
import moment from "moment"
import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"
import ReportsMenu from "./ReportsMenu"
import ReportsHead from "./ReportsHead"
import useStatsApi from "apis/useStatsApi"

function Devices() {

    const { getOrdersByDeviceType } = useStatsApi()
    const { getT } = useMultilang()

    const [list, setList] = useState([])
    const [sumAmount, setSumAmount] = useState(null)
    const [sumCount, setSumCount] = useState(null)
    const [sumOrderItems, setSumOrderItems] = useState(null)
    const [filter, setFilter] = useState({from: `${moment().format("YYYY-MM-DD")} 00:00:00`, to: `${moment().format("YYYY-MM-DD")} 23:59:59`})

    useEffect(() => {
        loadDevices()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        loadDevices()
        // eslint-disable-next-line 
    }, [filter])

    const loadDevices = () => {

        let filters = { fromDate: filter.from, toDate: filter.to }

        getOrdersByDeviceType(filters).then(res => {
            if(res.success) {
                setList(res.data)
                let sum1 = 0
                let sum2 = 0
                let sum3 = 0
                for(let item of res.data) {
                    sum1 += +item.ordersCount
                    sum2 += +item.discountPriceSum
                    sum3 += +item.orderItemsCount
                }
                setSumCount(sum1)
                setSumAmount(sum2)
                setSumOrderItems(sum3)
            } else {
                console.log(res)
            }
        })
    }

    const handleDateChange = (values) => {
        setFilter(values)
    }

    return(
        <div className="reports-container devices-container">
            <ReportsHead title={getT("reports.devices.title")} handleDateChange={handleDateChange} filter={filter}/>
            <div className="body">
                <div className="box-container">
                    <div className="box">
                        <div className="label">{getT("reports.devices.totalDiscountPrice")}</div>
                        <div className="value">
                            <NumberFormat
                                value={+sumAmount}
                                decimalSeparator=","
                                suffix=" Ft"
                                thousandSeparator="."
                                displayType="text"
                            />
                        </div>
                    </div>
                    <div className="box">
                        <div className="label">{getT("reports.devices.sumCount")}</div>
                        <div className="value">{sumCount}</div>
                    </div>
                    <div className="box">
                        <div className="label">{getT("reports.devices.avaragePrice")}</div>
                        <div className="value">
                            <NumberFormat
                                value={Math.round(+sumAmount / sumCount)}
                                decimalSeparator=","
                                suffix=" Ft"
                                thousandSeparator="."
                                displayType="text"
                            />
                        </div>
                    </div>
                    <div className="box">
                        <div className="label">{getT("reports.devices.sumOrderItems")}</div>
                        <div className="value">{sumOrderItems}</div>
                    </div>
                </div>
                <div className="device-container">
                    {list.map((row, index) => 
                        <div key={index} className="hidden appear device-box">
                            <div className="device-name">
                                {row.deviceName}
                            </div>
                            <div className="device-data">
                                <div className="data-box">
                                    <div className="label">{getT("reports.devices.discountPriceSum")}</div>
                                    <div className="value">
                                        <NumberFormat
                                            value={+row.discountPriceSum}
                                            decimalSeparator=","
                                            suffix=" Ft"
                                            thousandSeparator="."
                                            displayType="text"
                                        />
                                    </div>
                                </div>
                                <div className="data-box">
                                    <div className="label">{getT("reports.devices.ordersCount")}</div>
                                    <div className="value">{row.ordersCount}</div>
                                </div>
                                <div className="data-box">
                                    <div className="label">{getT("reports.devices.avaragePrice")}</div>
                                    <div className="value">
                                        <NumberFormat
                                            value={Math.round(row.discountPriceSum / row.ordersCount)}
                                            decimalSeparator=","
                                            suffix=" Ft"
                                            thousandSeparator="."
                                            displayType="text"
                                        />
                                    </div>
                                </div>
                                <div className="data-box">
                                    <div className="label">{getT("reports.devices.orderItemsCount")}</div>
                                    <div className="value">{row.orderItemsCount}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="footer">
                <ReportsMenu />
            </div>
        </div>
    )
}
export default Devices