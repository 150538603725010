import { useContext, useEffect, useState, useRef } from "react"
import { PreviousOrderContext } from "contexts/PreviousOrderContext"
import useMultilang from "intl/useMultilang"
import Items from "./Items"
import VatGroups from "./VatGroups"
import Payments from "./Payments"
import OrderIcons from "./OrderIcons"
import PaySum from "./PaySum"
import Delivery from "./Delivery"
import useOrdersApi from "apis/useOrdersApi"

function SelectedOrder() {

  const { getT } = useMultilang()
  const { getSelectedOrder } = useOrdersApi()
  
  const { selectedOrderUUID } = useContext(PreviousOrderContext)

  const [selectedOrder, setSelectedOrder] = useState(null)
  const [loading, setLoading] = useState(false)
  const serviceFee = useRef(0)

  useEffect(() => {
    if(selectedOrderUUID != null) getOrder()
    // eslint-disable-next-line
  }, [selectedOrderUUID])

  const getOrder = () => {
    if(selectedOrderUUID == null) return null
    setLoading(true)
    getSelectedOrder(selectedOrderUUID).then(res => {
      if(res.success) {
        setSelectedOrder(res.data)
        serviceFee.current = res.data.items.reduce((sum, a) => sum + +(a?.serviceFee || 0), 0)
      } else {
        console.log("Get order error", res)
      }
    }).finally(() => setTimeout(() => setLoading(false), 500))
  }

  if(selectedOrder == null) return <div className="no-order-selected">{getT("orders.noOrderSelected")}</div>

  if(loading) return (
    <div className="selected-order-container">
      <div className={`loading-container ${loading ? "" : "hidden"}`}>{getT("settings.transactions.loading")}</div>
    </div>
  )
  
  return (
    <div className="selected-order-container">
      <div className={`loading-container ${loading ? "" : "hidden"}`}>{getT("settings.transactions.loading")}</div>
      <div className="order-head-container">
        <div className="order-number">{selectedOrder.orderNumber}</div>
        <OrderIcons 
          insertTimestamp={selectedOrder.insertTimestamp} 
          orderIdentifier={selectedOrder.orderIdentifier}
          insertDeviceType={selectedOrder.insertDeviceType}
          paymentsNeeded={selectedOrder.paymentsNeeded}
          selectedOrderUUID={selectedOrderUUID}
          sysUserUUID={selectedOrder.sysUserUUID}
        />
      </div>
      {selectedOrder.notes !== "" && <div className="order-notes">{selectedOrder.notes}</div>}
      {selectedOrder.vipID != null && <div className="order-vip">Vip id: {selectedOrder.vipID}</div>}
      <Items items={selectedOrder.items} />
      <Delivery deliveryCost={selectedOrder.deliveryCost} />
      <VatGroups items={selectedOrder.items} />
      {serviceFee.current > 0 && <div className="order-tipp"><div>{getT("order.serviceFee")}</div><div>{serviceFee.current} Ft</div></div>}
      {+selectedOrder.tipAmount > 0 && <div className="order-tipp"><div>{getT("order.tippAmount")}</div><div>{+selectedOrder.tipAmount} Ft</div></div>}
      <Payments payments={selectedOrder.payments} />
      <PaySum price={+selectedOrder.discountPrice + +serviceFee.current} />
    </div>
  )
}
export default SelectedOrder