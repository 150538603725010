import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import useMultilang from "intl/useMultilang"
import { MainContext } from "contexts/MainContext"
import { useLocation, useDevice } from "okeoke.client"
import { ModalContext } from "contexts/ModalContext"
import CloseModal from "./WorkDay/CloseModal"
import OpenModal from "./WorkDay/OpenModal"
import ReportsMenu from "./Reports/ReportsMenu"
import Button from "./Button"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import SettingsWizard from "components/SettingsWizard"
import { isMobileOnly } from "react-device-detect"

function Admin() {
  
  const { openDay, closeDay, reloadNeeded, featureList } = useContext(MainContext)
  const { location } = useLocation()
  const { loadModal, popModal, addModal } = useContext(ModalContext)
  const { deviceParams } = useDevice()

  const { getT } = useMultilang()
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState("deviceSettings")

  const handleOpenDay = () => {
    if(location?.openState?.dayState != null && location?.openState?.dayState?.open) return null
    loadModal(<OpenModal openDay={openDay} popModal={popModal} dayState={location?.openState?.dayState} />, popModal)
  }

  const handleCloseDay = () => {
    if(location?.openState?.dayState != null && location?.openState?.dayState?.closed) return null
    loadModal(<CloseModal closeDay={closeDay} popModal={popModal} dayState={location?.openState?.dayState} />, popModal)
  }

  const handleClickFiscalPrinter = () => {
    if(deviceParams?.printer?.type != null && deviceParams?.printer?.type !== "Fiscat") return null
    navigate("/admin/fiscal-printer")
  }

  const handleOpenWizardModal = () => {
    addModal(
      <Modal key={uuidv4()} className="settings-wizard-modal" onClickLayout={popModal} >
        <SettingsWizard location={location} />
      </Modal>
    )
  }

  const reload = () => {
    window.location.reload()
  }

  return (
    <div className="admin-view-root hidden quickAppear">
      {reloadNeeded && <div className="reload-needed-container" onClick={reload}>{getT("settings.reload.needed")}</div>}
      <div className="tab-selector-container">
        <div className={`settings-tab ${activeTab === "deviceSettings" ? "selected" : ""}`} onClick={() => setActiveTab("deviceSettings")} >{getT("settings.selectTab.deviceSettings")}</div>
        <div className={`settings-tab ${activeTab === "locationSettings" ? "selected" : ""}`} onClick={() => setActiveTab("locationSettings")}>{getT("settings.selectTab.locationSettings")}</div>
      </div>
      {activeTab === "deviceSettings" && <>
        <div className="admin-title">{getT("settings.options.workDay")}</div>
        <div className="admin-button-group">
          <Button 
            disabled={location?.openState?.dayState != null && location?.openState?.dayState?.open} 
            highlighted={location?.openState?.dayState != null && !location?.openState?.dayState?.open} 
            onClick={handleOpenDay} 
            label={getT("settings.options.openDay")}
            svgName="sun"
          />
          <Button 
            disabled={location?.openState?.dayState != null && location?.openState?.dayState?.closed} 
            highlighted={location?.openState?.dayState != null && !location?.openState?.dayState?.closed}
            onClick={handleCloseDay} 
            label={getT("settings.options.closeDay")}
            svgName="moon"
          />
        </div>
        <div className="admin-title">{getT("settings.options.device")}</div>
        <div className="admin-button-group">
          {!isMobileOnly && <Button  
            onClick={handleOpenWizardModal} 
            label={getT("settings.options.settingsWizard")}
            svgName="settingsAlert"
          />}
          <Button  
            onClick={() => navigate("/admin/device")} 
            label={getT("settings.options.deviceSettings")}
            svgName="settings"
          />
          <Button  
            onClick={() => navigate("/admin/device-customization")} 
            label={getT("settings.options.deviceCustomization")}
            svgName="layout"
          />
        </div>
        {(featureList.fiscalPrinter === 1 || featureList.posPrinter === 1) && <>
          <div className="admin-title">{getT("settings.options.printer")}</div>
          <div className="admin-button-group">
            <Button  
              onClick={() => navigate("/admin/common-printer")} 
              label={getT("settings.options.commonPrinter")}
              svgName="printer"
            />
            {featureList.fiscalPrinter === 1 && <Button  
              disabled={!(deviceParams?.printer?.type != null && deviceParams?.printer?.type === "Fiscat")}
              onClick={handleClickFiscalPrinter} 
              label={getT("settings.options.fiscalPrinter")}
              svgName="vat"
            />}
            {/* {featureList.posPrinter === 1 && <Button  
              disabled={true}
              onClick={() => navigate("/admin/pos-printer")}
              label={getT("settings.options.posPrinter")}
              svgName="fiscal"
            />} */}
          </div>
        </>}
        <div className="admin-title">{getT("settings.options.orders")}</div>
        <div className="admin-button-group">
          <Button  
            onClick={() => navigate("/admin/orders")}
            label={getT("settings.options.previousOrders")}
            svgName="clock2"
          />
          <Button  
            onClick={() => navigate("/admin/statistics")}
            label={getT("settings.options.statistics")}
            svgName="pieChart"
          />
        </div>
        {featureList.reports === 1 && <>
          <div className="admin-title">{getT("settings.options.reports")}</div>
          <ReportsMenu />
        </>}
      </>}
    </div>
  )
}
export default Admin