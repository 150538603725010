import { useState, useEffect } from 'react'
import { useFiscatPrinter, useDevice } from "okeoke.client"
import Modal from "../Modal"

function CloseDay(props) {

  const { dayOpened } = props
  const [open, setOpen] = useState(false);
  const { deviceParams } = useDevice()
  const { fiscatCloseDay } = useFiscatPrinter(deviceParams?.printer)
  const [ message, setMessage ] = useState('')
  const [disabled, setDisabled] = useState(false)
  const handleClose = () => setOpen(false);
  const handleOpen = () => {!disabled && setOpen(true)};

  const handleSubmit = () => {
    setDisabled(true)
    fiscatCloseDay().then(res => {
      setOpen(false)
      setMessage(!res?.success ? `Hiba történt! (${res?.data?.message})` : 'OK!' )
      setDisabled(false)
    })
  }

  useEffect(() => {
    setDisabled(!dayOpened)
  }, [dayOpened])

  useEffect(() => {
    setMessage('')
  }, [open])

  return (
    <>
      <Modal open={open} >
        <div className="day-open-modal-content">
          <h1>Napzárás</h1>
          <div className="actions">
            <div className="btn" onClick={handleClose}>Mégsem</div>
            <div className={`btn ${disabled ? "disabled" : "primary"}`} onClick={() => {if(!disabled) handleSubmit() }}>Lezárom</div>
          </div>
          <div className="message">
            {message}
          </div>
        </div>
      </Modal>
      <div className={`btn ${disabled ? "disabled" : "primary"}`} onClick={handleOpen}>Napzárás</div>
    </>
  )
}
export default CloseDay