import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"

function Items(props) {

    const { items } = props
    const { getT, getName } = useMultilang()

    if(items == null) return null

    return (
        <div className="order-items-root">
            <div className="order-item-header">
                <div className="name">{getT("settings.transactions.items")}</div>
                <div className="amount">{getT("settings.transactions.amount")}</div>
                <div className="price">{getT("settings.transactions.price")}</div>
            </div>
            <div className="order-items-rows-container">
                {items
                .filter(orderItem => orderItem.count > 0)
                .map((orderItem, index) => 
                    <div key={index} className="item-rows">
                        <div className="row item-row">
                            <div className="name">{getName(orderItem.name)}</div>
                            <div className="count">{orderItem.count}</div>
                            <div className="price">
                                <NumberFormat
                                    value={+orderItem.discountPrice}
                                    decimalSeparator=","
                                    suffix=" Ft"
                                    thousandSeparator="."
                                    displayType="text"
                                />
                            </div>
                        </div>
                        <div className="row size-row">{getName(orderItem.sizeName)}</div>
                        {orderItem.notes !== "" && <div className="row notes-row">{orderItem.notes}</div>}
                        <div className="row price-row">
                            <div className="name">{getT("item.price")}</div>
                            <div className="count"></div>
                            <div className="price">
                                <NumberFormat
                                    value={+orderItem.price}
                                    decimalSeparator=","
                                    suffix=" Ft"
                                    thousandSeparator="."
                                    displayType="text"
                                />
                            </div>
                        </div>
                        {orderItem.modifiers
                        .filter(modifier => modifier.count > 0)
                        .map((modifier, index) => 
                            <div key={index} className="row modifier-row">
                                <div className="name">{getName(modifier?.name)}</div>
                                <div className="count">{modifier.count}</div>
                                <div className="price">
                                    <NumberFormat
                                        value={+modifier.price}
                                        decimalSeparator=","
                                        suffix=" Ft"
                                        thousandSeparator="."
                                        displayType="text"
                                    />
                                </div>
                            </div>
                        )}
                        {orderItem.takeAway === 1 && <div key={"takeAwayRow"} className="row modifier-row take-away">{getT("item.takeAway.takeAway")}</div>}
                        {orderItem.package?.name != null && +orderItem.packagePrice > 0 && <div key={"packageRow"} className="row modifier-row">
                            <div className="name">{getName(orderItem.package?.name)}</div>
                            <div className="count">1</div>
                            <div className="price">
                                <NumberFormat
                                    value={+orderItem.packagePrice}
                                    decimalSeparator=","
                                    suffix=" Ft"
                                    thousandSeparator="."
                                    displayType="text"
                                />
                            </div>
                        </div>}
                        <div className="row modifier-row totalPrice-row">
                            <div className="name">{getT("item.totalPrice")}</div>
                            <div className="count"></div>
                            <div className="price">
                                <NumberFormat
                                    value={+orderItem.totalPrice / orderItem.count}
                                    decimalSeparator=","
                                    suffix=" Ft"
                                    thousandSeparator="."
                                    displayType="text"
                                />
                            </div>
                        </div>
                        {+orderItem.totalPrice - +orderItem.discountPrice > 0 && <div className="row modifier-row discount-row">
                            <div className="name">{getT("item.discount")}</div>
                            <div className="count"></div>
                            <div className="price">-
                                <NumberFormat
                                    value={+orderItem.totalPrice - +orderItem.discountPrice}
                                    decimalSeparator=","
                                    suffix=" Ft"
                                    thousandSeparator="."
                                    displayType="text"
                                />
                            </div>
                        </div>}
                    </div>
                )}
            </div>
        </div>
    )
}
export default Items