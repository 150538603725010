import NumberFormat from "react-number-format"
import useMultilang from "intl/useMultilang"

function Delivery(props) {
    
    const { deliveryCost } = props
    const { getT } = useMultilang()

    if(+deliveryCost < 1) return null

    return(
        <div className="delivery-cost">
            <div className="label">{getT("settings.transactions.orderDeliveryCost")}</div>
            <div className="value">
            <NumberFormat
                value={+deliveryCost}
                decimalSeparator=","
                suffix=" Ft"
                thousandSeparator="."
                displayType="text"
            />
            </div>
        </div>
    )
}
export default Delivery