import SizeButton from "./SizeButton"
import useMultilang from "intl/useMultilang"

function Sizes(props) {

  const { sizes, selectedSizeUUID, setSize } = props
  const { getName, getT } = useMultilang()

  const sizeArray = (sizes || []).filter(size => size.enabled !== 0 && size.availability.available !== 0)

  if (sizeArray.length < 2) return null

  return (
    <div className="modifier-group">
      <div className="modifier-group-name">{getT("sell.itemModifier.sizes")}</div>
      <div className="modifiers-container">
        {sizeArray.map(size => 
          <SizeButton
            key={size.uuid}
            selectedSizeUUID={selectedSizeUUID}
            size={size}
            label={getName(size?.name)}
            setSelected={setSize}
          />
        )}
      </div>
    </div>
  )
}
export default Sizes