import React, { useState, useEffect, useContext } from 'react'
import { ModalContext } from 'contexts/ModalContext'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangePicker } from 'react-date-range'
import * as locales from 'react-date-range/dist/locale'
import useMultilang from 'intl/useMultilang'

function DatePickerModal(props) {

    const { fromDate, toDate, onRangeSelected, name } = props
    const { popModal } = useContext(ModalContext)
    const [selectionRange, setSelectionRange] = useState([{ startDate: new Date(), endDate: new Date(), key: 'selection' }])   
    const { getT, language } = useMultilang()
    
    useEffect(() => {
        if(fromDate != null && toDate != null)
        setSelectionRange([{ startDate: new Date(fromDate), endDate: new Date(toDate), key: 'selection' }])
    }, [fromDate, toDate])

    const handleRangeSelected = () => {
        if (typeof onRangeSelected === 'function' && selectionRange.length > 0) {
            let newRange = {
                name,
                fromDate: selectionRange[0].startDate,
                toDate: selectionRange[0].endDate,
            }
            onRangeSelected(newRange)
        }
    }    
    
    const handleSelectRange = item => setSelectionRange([item.selection])

    const handleCancel = () => popModal()

    const handleReset = () => setSelectionRange([{ startDate: new Date(), endDate: new Date(), key: 'selection' }])

    return(
        <>
            {/* <div className="font-extraLarge font-bold">{getT('date.datePicker.title')}</div> */}
            <DateRangePicker
                ranges={selectionRange}
                onChange={handleSelectRange}
                locale={locales?.[language]}
            />
            <div className="date-picker-actions">
                <div onClick={handleReset} className="btn">{getT('date.datePicker.reset')}</div>
                <div onClick={handleCancel} className="btn">{getT('date.datePicker.cancel')}</div>
                <div onClick={handleRangeSelected} className="btn btn-highlighted">{getT('date.datePicker.select')}</div>
            </div>
        </>
    )
}

export default DatePickerModal