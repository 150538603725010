import { useState, useEffect } from "react"
import useMultilang from "intl/useMultilang"
import LoadSvg from "components/LoadSvg-v2"
import InputFieldSelect from "components/InputFieldSelect"
import { toast } from "react-toastify"
import TextField from "components/TextField"
import useSizesApi from "apis/catalog/useSizesApi"
import UnitSelect from "components/UnitSelect"
import { useClient } from "okeoke.client"

const ntakUnits = {
    piece: {id:"piece", name:{hu:'darab',en:"piece"}},
    liter: {id:"liter", name:{hu:'liter',en:"liter"}},
    kg: {id:"kg", name:{hu:'kilógramm',en:"kilogramm"}},
    unit: {id:"unit", name:{hu:'egység',en:"unit"}}
}

function Size(props) {

    const { size, ntakCategories, defaultSizeUUID, setDefaultSize, sizeAvailability, setAvailability } = props

    const { getT, language } = useMultilang()
    const { setSize } = useSizesApi()
    const [localSize, setLocalSize] = useState(size)
    const [selectedNtakCategory, setSelectedNtakCategory] = useState("")
    const [ntakRowOpen, setNtakRowOpen] = useState(false)
    const { deviceLocationUUID } = useClient()

    useEffect(() => {

        if(size == null || ntakCategories == null) return null

        for(let category of Object.values(ntakCategories)) {
            for(let subcateg of Object.values(category.subCategories)) {
                if(subcateg.id === size.ntakCategoryID) setSelectedNtakCategory(category.code)
            }
        }

        setLocalSize(size)
    }, [size, ntakCategories])

    const handleUpdateSize = () => {    
        setSize(localSize).then(res => {
            if(res.success) {
              //toast.success(res.message)
            } else{
              toast.error(res.message)
            }
        })
    }

    const enableSize = () => {
        if(localSize.enabled && defaultSizeUUID === localSize.uuid) {
            toast.error(getT("editItem.cantDisableDefault"))
        }
        if(defaultSizeUUID !== localSize.uuid) {
            setSize({
                uuid: localSize.uuid,
                enabled: localSize.enabled ? 0 : 1,
            }).then(res => {
                if(res.success) {
                    setLocalSize({ ...res.data })
                    //toast.success(res.message)
                } else{
                    toast.error(res.message)
                }
            })
        }
    }
    
    // const handleAvailabilityChange = () => {
    //     setAvailability({
    //       itemUUID: localSize.uuid,
    //       available: assembledItems?.[localSize.itemUUID]?.availability?.available ? 0 : 1
    //     }).then(res => {console.log(res)})
    // }
    
    // const handleVisibilityChange = () => {
    //     setAvailability({
    //       itemUUID: localSize.uuid,
    //       visible: assembledItems?.[localSize.itemUUID]?.availability?.visible ? 0 : 1,
    //     }).then(res => {console.log(res)})
    // }
    

    const handleNtakCateg = (e) => {
        if(e.target.name === "ntakCategory") setSelectedNtakCategory(e.target.value)
        if(e.target.name === "ntakCategoryID") {
          let newValues = { ...localSize, ntakCategoryID: e.target.value }
          setSize(newValues).then(res => {
            if(res.success) {
                //toast.success(res.message)
            } else {
                toast.error(res.message)
            }
          })
          setLocalSize(newValues)
        }
    }

    const handleUnitType = (e) => {
        let newValues = { ...localSize, unitType: e.target.value }
        setSize(newValues).then(res => {
            if(res.success) {
                //toast.success(res.message)
            } else {
                toast.error(res.message)
            }
          })
          setLocalSize(newValues)
    }

    const handleSetDefault = () => {
        
        if(localSize.uuid === defaultSizeUUID) return null

        if(!localSize.enabled) {
            toast.error(getT("editItem.size.disabledCantBeDefault"))
        } else {
            setDefaultSize(size.uuid)
        }
    }

    const handleUnitChange = (e) => {        
        let newValues = { ...localSize, unit: e.target.value }
        setSize(newValues).then(res => {
            if(res.success) {
                //toast.success(res.message)
            } else {
                toast.error(res.message)
            }
        })
        setLocalSize(newValues)
    }

    const handleSubUnitChange = (subUnitID, unitType, unit) => {
        let newValues = { ...localSize, unitType, unit, attributes: { ...localSize.attributes, selectedSubUnitID: subUnitID }}
        setSize(newValues).then(res => {
            if(res.success) {
                //toast.success(res.message)
            } else {
                toast.error(res.message)
            }
        })
        setLocalSize(newValues)
    }

    const handleVisibilityChange = () => {
        let availability = {
            locationUUID: deviceLocationUUID,
            itemUUID: localSize.uuid,
            visible: sizeAvailability.visible === 0 ? 1 : 0,
        }
        setAvailability(availability)
    }

    const handleAvailabilityChange = () => {
        let availability = {
            locationUUID: deviceLocationUUID,
            itemUUID: localSize.uuid,
            available: sizeAvailability.available === 0 ? 1 : 0,
        }
        setAvailability(availability)
    }

    const handleChange = e => setLocalSize({ ...localSize, [e.target.name]: e.target.value })
    const handleChangeName = e => setLocalSize({ ...localSize, name: { ...localSize.name, [language]: e.target.value} })

    if(localSize == null) return null
    
    return (
        <div className={`size-row ${localSize.enabled ? "" : "disabled"}`} >
            <div className="col">
                <div className="row">
                    <div className="size-name">
                        <TextField
                            type="text"
                            name="name"
                            onChange={handleChangeName}
                            onBlur={handleUpdateSize}
                            value={localSize.name?.[language]}
                            label={getT("editItem.sizeName")}
                        />
                    </div>
                    <div className="size-price">
                        <TextField
                            type="text"
                            name="price"
                            onChange={handleChange}
                            onBlur={handleUpdateSize}
                            value={localSize.price}
                            label={getT("editItem.sizePrice")}
                        />
                    </div>
                    <UnitSelect 
                        onChange={handleUnitChange} 
                        unitValue={localSize?.unit}
                        selectedSubUnitID={localSize?.attributes?.selectedSubUnitID}
                        onSubUnitChange={handleSubUnitChange}
                    />
                </div>
                <div className={`row ${ntakRowOpen ? "open" : "closed"}`}>
                    <div className="toggler" onClick={() => setNtakRowOpen(!ntakRowOpen)}><LoadSvg name="arrowUp" /></div>
                    <div className="ntak-select">
                        <InputFieldSelect
                            className={"create-new-item-root-input-field-select"}
                            options={Object.values(ntakCategories || {}).map(categ => {
                                return { title: categ.name?.[language], value: categ.code }
                            })}
                            onChange={handleNtakCateg}
                            name="ntakCategory"
                            value={selectedNtakCategory}
                            displayedDefaultName={getT("createItem.modal.selectNtakMainCategory")}
                            label={getT("createItem.modal.ntakCategory")}
                        />
                        <InputFieldSelect
                            className={"create-new-item-root-input-field-select"}
                            options={Object.values(ntakCategories?.[selectedNtakCategory]?.subCategories || {}).map(categ => {
                                return { title: categ.name?.[language], value: categ.id }
                            })}
                            onChange={handleNtakCateg}
                            name="ntakCategoryID"
                            value={localSize.ntakCategoryID}
                            displayedDefaultName={getT("createItem.modal.selectNtakSubCategory")}
                            disabled={selectedNtakCategory === ""}
                            label={getT("createItem.modal.ntakSubCategory")}
                        />
                    </div>
                    <div className="unit-container">
                        <div className="input-field-main-container">
                            <TextField 
                                label={getT("createItem.modal.ntak.unit")}
                                name="unit"
                                type="number"
                                className="unit-field"
                                value={localSize?.unit}
                                onChange={handleChange}
                                onBlur={handleUpdateSize}
                                disabled
                            />
                        </div>
                        <InputFieldSelect 
                            label={getT("createItem.modal.ntak.unitType")}
                            name="unitType"
                            onChange={handleUnitType}
                            value={localSize?.unitType}
                            displayedDefaultName={getT("createItem.modal.ntak.selectUnitType")}
                            options={Object.values(ntakUnits || {}).map(unit => { return({ value: unit?.id, title: unit?.name?.[language] }) })}
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="size-function-buttons">
                    <div className={`btn ${localSize.uuid === defaultSizeUUID ? "btn-highlighted" : ""}`} onClick={handleSetDefault}>
                        {getT("createItem.modal.defaultSize")}
                    </div>
                    <div className={`btn ${localSize.enabled ? "btn-highlighted" : ""}`} onClick={enableSize}>
                        <div className="text">{getT("createItem.modal.enabled")}</div>
                    </div>
                    <div className={`btn ${sizeAvailability?.available ? "btn-highlighted" : ""}`} onClick={handleAvailabilityChange}>
                        <div className="text">{getT("createItem.modal.available")}</div>
                    </div>
                    <div className={`btn ${sizeAvailability?.visible ? "btn-highlighted" : ""}`} onClick={handleVisibilityChange}>
                        <div className="text">{getT("createItem.modal.visible")}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Size