import { useContext, useRef, useState, useEffect } from "react"
import TextField from "components/TextField"
import Clock from 'react-live-clock'
import LoadSvg from 'components/LoadSvg-v2'
import useMultilang from "intl/useMultilang"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import { ModalContext } from "contexts/ModalContext"
import axios from 'axios'
import { toast } from "react-toastify"

var axiosGlobal = axios.create({
    timeout: 15000,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
})

function LoginPanel(props) {

    const { location, deviceName, localUsers, submitPin, visible, brandID } = props
    const { getT } = useMultilang()

    const { addModal, popModal } = useContext(ModalContext)

    const email = useRef(null)

    const [pin, setPin] = useState("")

    useEffect(() => {
        if(localUsers.length === 1) {
            setPin(localUsers[0].pinCode)
        }
        // eslint-disable-next-line
    }, [localUsers])

    const changePin = (e) => {
        if(typeof e === "number") {
            setPin(""+pin+e)
        } else {
            setPin(pin.slice(0, -1))
        }
    }

    const handleBlur = e => email.current = e.target.value

    const handleSend = () => {

        let emailValid = validateEmail(email.current)

        if(emailValid) {
            try {
                let body = {
                  appName: "brands",
                  appID: brandID
                }
                axiosGlobal.post(`https://ipanel.okeoke.io/api/v1/global/users/app/pin/forgot/${email.current}`, body).then(res => {
                    console.log(res.data)
                    if(res.data.success) {
                        window.location.reload()
                    } else {
                        toast.error("Hiba történt", { autoClose: 2000, hideProgressBar: false })
                    }
                })
            } catch (error) {
                console.log(error)
                toast.error("Hiba történt", { autoClose: 2000, hideProgressBar: false })
            }
        }
    }

    const validateEmail = (email) => {
        return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }

    const openNewPinModal = () => {
        addModal(
            <Modal key={uuidv4()} className="new-pin-modal">
                <div className="title">{getT("login.changePinTitle")}</div>
                <div className="body">
                    <input className="input-field" onBlur={handleBlur} placeholder="myname@barsoft.hu" />
                </div>
                <div className="actions">
                    <div className="btn btn-cancel" onClick={popModal}>{getT("login.changePinCancel")}</div>
                    <div className="btn btn-save" onClick={handleSend}>{getT("login.changePinSave")}</div>
                </div>
            </Modal>
        )
    }

    if(!visible) return null

    return(
        <div className="login-panel">
            <div className="location-name">{location.name}</div>
            <div className="device-name">{deviceName}</div>
            <div className="login-date-time-container">
                <div className="login-date-container">
                    <Clock 
                        format={'YYYY-MM-DD'} 
                        ticking={true} 
                        timezone={'Europe/Budapest'}
                        interval={86400000}
                    />
                </div>
                <div className="login-time-container">
                    <Clock 
                        format={'HH:mm:ss'} 
                        ticking={true} 
                        timezone={'Europe/Budapest'} 
                    />
                </div>
            </div>
            {localUsers != null && localUsers.length > 1 && <>
            <div className={`login-pin-container`}>
                <div className="login-pin-input-container">                    
                    <TextField
                        id="outlined-password-input"
                        label=""
                        type="password"
                        className="login-input-field"
                        autoComplete="off"
                        variant="outlined"
                        fullWidth={true}
                        disabled
                        value={pin}
                    />
                </div>
                <div className="login-pin-numbers">
                    <div className="login-button" onClick={() => { changePin(1) }}><div className="inner-container">1</div></div>
                    <div className="login-button" onClick={() => { changePin(2) }}><div className="inner-container">2</div></div>
                    <div className="login-button" onClick={() => { changePin(3) }}><div className="inner-container">3</div></div>
                    <div className="login-button" onClick={() => { changePin(4) }}><div className="inner-container">4</div></div>
                    <div className="login-button" onClick={() => { changePin(5) }}><div className="inner-container">5</div></div>
                    <div className="login-button" onClick={() => { changePin(6) }}><div className="inner-container">6</div></div>
                    <div className="login-button" onClick={() => { changePin(7) }}><div className="inner-container">7</div></div>
                    <div className="login-button" onClick={() => { changePin(8) }}><div className="inner-container">8</div></div>
                    <div className="login-button" onClick={() => { changePin(9) }}><div className="inner-container">9</div></div>
                    <div className="login-button" onClick={() => { changePin(false) }}>
                        <div className="inner-container"><LoadSvg name={"ArrowLeftIcon"}/></div>
                    </div>
                    <div className="login-button" onClick={() => { changePin(0) }}><div className="inner-container">0</div></div>
                    <div className="login-button" onClick={() => submitPin(pin) }><div className="inner-container ok-btn">OK</div></div>
                </div>
            </div>
            <div className="change-pin-container" onClick={openNewPinModal}>{getT("login.changePin")}</div>
            </>}
            {localUsers != null && localUsers.length === 1 && <div className={`start-btn pulsate-fwd`} onClick={() => submitPin(pin) }>{getT("login.start")}</div>}
        </div>
    )
}
export default LoginPanel