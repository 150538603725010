import { useState, useEffect } from "react"
import useMultilang from "intl/useMultilang"
import DataTable from "components/DataTable"
import HourlySalesChart from "./HourlySalesChart"
import DashboardBox from "./DashboardBox"
import CountUp from "react-countup"
import { useReports, useClient } from "okeoke.client"
//import DatePicker from "./DatePicker"
import moment from "moment"

function BrandDashboard() {

  const { brandID } = useClient()
  const { getT, language } = useMultilang()
  const { fetchOrdersReport } = useReports()

  const [totalSum, setTotalSum] = useState(null)
  const [totalSumOld, setTotalSumOld] = useState(null)
  const [totalCount, setTotalCount] = useState(null)
  const [totalCountOld, setTotalCountOld] = useState(null)
  const [discountPriceAvg, setDiscountPriceAvg] = useState(null)
  const [discountPriceAvgOld, setDiscountPriceAvgOld] = useState(null)
  const [top5ItemsByCount, setTop5ItemsByCount] = useState([])
  const [top5ItemsBySum, setTop5ItemsBySum] = useState([])
  const [vatGroups, setVatGroups] = useState([])

  let from = `${moment().format("YYYY-MM-DD")} 00:00:00`
  let to = `${moment().format("YYYY-MM-DD")} 23:59:59`

  useEffect(() => {
    if (brandID != null && from != null && to != null) {
      loadTotalSum()
      loadTop5ProdByCount()
      loadTop5ProdBySum()
      loadVatGroups()
    }
    // eslint-disable-next-line
  }, [from, to, brandID]);

  const columns = [
    {
      name: "name",
      label: getT("statistics.dashboard.top5prod.name"),
      options: {
        sort: false,
        format: (row) => row?.name?.[language] || "N/A",
        type: false,
      },
    },
    {
      name: "amount",
      label: getT("statistics.dashboard.top5prod.amount"),
      options: {
        className: "head-align-right cell-align-right",
        sort: false,
        format: (row) =>
          `${row.totalCount} ${getT("statistics.dashboard.top5prod.piece")}`,
        type: false,
      },
    },
  ];

  const columns2 = [
    {
      name: "name",
      label: getT("statistics.dashboard.top5prod.name"),
      options: {
        sort: false,
        format: (row) => row?.name?.[language] || "N/A",
        type: false,
      },
    },
    {
      name: "discountPriceSum",
      label: getT("statistics.dashboard.top5prod.discountPriceSum"),
      options: {
        className: "head-align-right cell-align-right",
        sort: false,
        format: (row) => `${parseInt(row?.discountPriceSum || 0)} Ft`,
        type: false,
      },
    },
  ];

  const columns3 = [
    {
      name: "vat",
      label: getT("statistics.dashboard.vatGroups.vat"),
      options: {
        sort: false,
        format: (row) => `${parseInt(row?.vat || 0)} %`,
        type: false,
      },
    },
    {
      name: "amount",
      label: getT("statistics.dashboard.vatGroups.amount"),
      options: {
        className: "head-align-right cell-align-right",
        sort: false,
        format: (row) => `${parseInt(row?.discountPriceSum || 0)} Ft`,
        type: false,
      },
    },
  ]

  const loadTotalSum = () => {
    fetchOrdersReport("total/sum", { fromDate: from, toDate: to }).then(res => {
      if(res.success) {
        setTotalSumOld(totalSum || 0)
        setTotalSum(res.data[0]?.discountPriceSum || 0)
        setTotalCountOld(totalCount || 0)
        setTotalCount(res.data[0]?.totalCount || 0)
        setDiscountPriceAvgOld(discountPriceAvg || 0)
        setDiscountPriceAvg(res.data[0]?.discountPriceAvg || 0)
      } else {
        console.log(res)
      }
    })
  }

  const loadTop5ProdByCount = () => {
    fetchOrdersReport("top/items/count", {fromDate: from, toDate: to}).then(res => {
      if(res.success) {
        setTop5ItemsByCount(res.data)
      } else {
        console.log(res)
      }
    })
  }

  const loadTop5ProdBySum = () => {
    fetchOrdersReport("top/items/sum", { fromDate: from, toDate: to }).then(res => {
      if(res.success) {
        setTop5ItemsBySum(res.data)
      } else {
        console.log(res)
      }
    })
  }

  const loadVatGroups = () => {
    fetchOrdersReport("vat/sum", { fromDate: from, toDate: to }).then(res => {
      if(res.success) {
        setVatGroups(res.data)
      } else {
        console.log(res)
      }
    })
  }

  return (
    <div className="dashboard-root">
      {/* <DatePicker /> */}
      <div className="box-group flex-dir-col">
        <div className="box-group flex-dir-row">
          <DashboardBox
            className={"small"}
            label={getT("statistics.dashboard.totalSale")}
            value={
              <CountUp
                start={totalSumOld}
                end={totalSum}
                suffix=" Ft"
                separator="."
              />
            }
          />
          <DashboardBox
            className={"small"}
            label={getT("statistics.dashboard.totalOrders")}
            value={
              <CountUp start={totalCountOld} end={totalCount} separator="." />
            }
          />
          <DashboardBox
            className={"small"}
            label={getT("statistics.dashboard.avarageBasketPrice")}
            value={
              <CountUp
                start={discountPriceAvgOld}
                end={discountPriceAvg}
                suffix=" Ft"
                separator="."
              />
            }
          />
        </div>
        <div className="dashboard-box dashboard-chart-container">
          <HourlySalesChart fromDate={from} toDate={to} />
        </div>
      </div>
      <div className="box-group">
        <div className="dashboard-box">
          <div className="title">
            {getT("statistics.dashboard.top5ProductsByCount")}
          </div>
          {top5ItemsByCount.length > 0 && (
            <DataTable data={top5ItemsByCount} columns={columns} />
          )}
          {top5ItemsByCount.length === 0 && (
            <div className="text m-2">
              {getT("statistics.dashboard.noData")}
            </div>
          )}
        </div>
        <div className="dashboard-box">
          <div className="title">
            {getT("statistics.dashboard.top5Products")}
          </div>
          {top5ItemsBySum.length > 0 && (
            <DataTable data={top5ItemsBySum} columns={columns2} />
          )}
          {top5ItemsBySum.length === 0 && (
            <div className="text m-2">
              {getT("statistics.dashboard.noData")}
            </div>
          )}
        </div>
        <div className="dashboard-box">
          <div className="title t25">{getT("statistics.dashboard.vatGroups")}</div>
          {vatGroups.length > 0 && (
            <DataTable data={vatGroups} columns={columns3} />
          )}
          {vatGroups.length === 0 && (
            <div className="text m-2">
              {getT("statistics.dashboard.noData")}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BrandDashboard
