import { useState, useEffect } from "react"
import moment from "moment"
import useMultilang from "intl/useMultilang"
import ReportsMenu from "./ReportsMenu"
import CountUp from "react-countup"
import ReportsHead from "./ReportsHead"
import useStatsApi from "apis/useStatsApi"

function Summary() {

    const { getOrdersTotalSum, getOrdersBaseTotal, getOrdersByPaymentType, getTippSum, getOrdersByDiscount } = useStatsApi()
    const { getT, getName } = useMultilang()

    const [totalSum, setTotalSum] = useState(0)
    const [paymentTypes, setPaymentTypes] = useState({ bankCard: 0, cash: 0, online: 0 })
    const [tipp, setTipp] = useState(0)
    const [discounts, setDiscounts] = useState([])
    const [baseTotal, setBaseTotal] = useState({ paymentRoundingSum: 0, deliveryCostSum: 0 })
    const [filter, setFilter] = useState({from: `${moment().format("YYYY-MM-DD")} 00:00:00`, to: `${moment().format("YYYY-MM-DD")} 23:59:59`})

    useEffect(() => {
        loadTotalSum()
        loadPaymentTypes()
        loadTipps()
        loadDiscounts()
        loadBaseTotal()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        loadTotalSum()
        loadPaymentTypes()
        loadTipps()
        loadDiscounts()
        loadBaseTotal()
        // eslint-disable-next-line
    }, [filter])

    const loadTotalSum = () => {

        let filters = { fromDate: filter.from, toDate: filter.to }
  
        getOrdersTotalSum(filters).then(res => {
          if(res.success) {            
            setTotalSum(res.data[0])
          } else {
            console.log(res)
          }
        })
    }

    const loadBaseTotal = () => {

        let filters = { fromDate: filter.from, toDate: filter.to }
  
        getOrdersBaseTotal(filters).then(res => {
          if(res.success) {            
            setBaseTotal(res.data[0])
          } else {
            console.log(res)
          }
        })
    }

    const loadPaymentTypes = () => {

        let filters = { fromDate: filter.from, toDate: filter.to }
    
        getOrdersByPaymentType(filters).then(res => {
            if(res.success) {
                let sumBankcard = 0
                let sumCash = 0
                let sumOnline = 0
                for(let item of res.data) {
                    if(item.agentType === "bankCard") sumBankcard = sumBankcard + +item.paySum
                    if(item.agentType === "cash") sumCash = sumCash + +item.paySum
                    if(item.agentType === "online") sumOnline = sumOnline + +item.paySum
                }
                setPaymentTypes({ bankCard: sumBankcard, cash: sumCash, online: sumOnline })
            } else {
                console.log(res)
            }
        })
    }

    const loadTipps = () => {

        let filters = { fromDate: filter.from, toDate: filter.to }
    
        getTippSum(filters).then(res => {
            if(res.success) {
                let tippSum = res.data[0].tippSum
                setTipp(tippSum)
            } else {
                console.log(res)
            }
        })
    }

    const loadDiscounts = () => {

        let filters = { fromDate: filter.from, toDate: filter.to }

        getOrdersByDiscount(filters).then(res => {
            if(res.success) {
                setDiscounts(res.data)
            } else {
                console.log(res)
            }
        })
    }

    const handleDateChange = (values) => {
        setFilter(values)
    }

    return(
        <div className="reports-container summary-container">
            <ReportsHead title={getT("reports.summary.title")} handleDateChange={handleDateChange} filter={filter} />
            <div className="body">
                <div className="block">
                    <div className="block-title">{getT("reports.summary.total")}</div>
                    <div className="row">
                        <div className="label">{getT("reports.summary.sumTotal")}</div>
                        <div className="amount">
                            <CountUp
                                start={0}
                                end={+totalSum.discountPriceSum}
                                suffix=" Ft"
                                separator="."
                                duration={1}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">{getT("reports.summary.serviceFee")}</div>
                        <div className="amount">
                            <CountUp
                                start={0}
                                end={+totalSum.serviceFeeSum}
                                suffix=" Ft"
                                separator="."
                                duration={1}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">{getT("reports.summary.tippSum")}</div>
                        <div className="amount">
                            <CountUp
                                start={0}
                                end={+tipp}
                                suffix=" Ft"
                                separator="."
                                duration={1}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">{getT("reports.summary.packageFee")}</div>
                        <div className="amount">
                            <CountUp
                                start={0}
                                end={+totalSum.packagePriceSum}
                                suffix=" Ft"
                                separator="."
                                duration={1}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">{getT("reports.summary.deliveryCost")}</div>
                        <div className="amount">
                            <CountUp
                                start={0}
                                end={+baseTotal.deliveryCostSum}
                                suffix=" Ft"
                                separator="."
                                duration={1}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">{getT("reports.summary.paymentRounding")}</div>
                        <div className="amount">
                            <CountUp
                                start={0}
                                end={+baseTotal.paymentRoundingSum}
                                suffix=" Ft"
                                separator="."
                                duration={1}
                            />
                        </div>
                    </div>
                </div>
                <div className="block">
                    <div className="row">
                        <div className="title">{getT("reports.summary.totalIncome")}</div>
                        <div className="amount">
                            <CountUp
                                start={0}
                                end={+totalSum.discountPriceSum + +totalSum.serviceFeeSum +  +totalSum.packagePriceSum + +tipp + +baseTotal.deliveryCostSum - +baseTotal.paymentRoundingSum}
                                suffix=" Ft"
                                separator="."
                                duration={1}
                            />
                        </div>
                    </div>
                </div>
                <div className="block">
                    <div className="block-title">{getT("reports.summary.paymentTypes")}</div>
                    <div className="row">
                        <div className="label">{getT("reports.paymentType.cash")}</div>
                        <div className="amount">
                            <CountUp
                                start={0}
                                end={+paymentTypes.cash}
                                suffix=" Ft"
                                separator="."
                                duration={1}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">{getT("reports.paymentType.bankCard")}</div>
                        <div className="amount">
                            <CountUp
                                start={0}
                                end={+paymentTypes.bankCard}
                                suffix=" Ft"
                                separator="."
                                duration={1}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">{getT("reports.paymentType.online")}</div>
                        <div className="amount">
                            <CountUp
                                start={0}
                                end={+paymentTypes.online}
                                suffix=" Ft"
                                separator="."
                                duration={1}
                            />
                        </div>
                    </div>
                </div>
                <div className="block">
                    <div className="row">
                        <div className="title">{getT("reports.summary.totalPayments")}</div>
                        <div className="amount">
                            <CountUp
                                start={0}
                                end={+paymentTypes.cash + +paymentTypes.bankCard + +paymentTypes.online}
                                suffix=" Ft"
                                separator="."
                                duration={1}
                            />
                        </div>
                    </div>
                </div>
                <div className="block">
                    <div className="block-title">{getT("reports.summary.sell")}</div>
                    <div className="row">
                        <div className="title">{getT("reports.summary.transactions")}</div>
                        <div className="amount">
                            <CountUp
                                start={0}
                                end={totalSum.ordersCount}
                                suffix=""
                                separator="."
                                duration={1}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">{getT("reports.summary.avg")}</div>
                        <div className="amount">
                            <CountUp
                                start={0}
                                end={Math.round(+totalSum.discountPriceSum) / totalSum.ordersCount}
                                suffix=" Ft"
                                separator="."
                                duration={1}
                            />
                        </div>
                    </div>
                </div>
                <div className="block">
                    <div className="block-title">{getT("reports.summary.discounts")}</div>
                    {discounts.map((discount, index) => 
                        <div key={index} className="row">
                            <div className="title">{getName(discount.name)}</div>
                            <div className="amount">
                                <CountUp
                                    start={0}
                                    end={+discount.sumAmount}
                                    suffix=" Ft"
                                    separator="."
                                    duration={1}
                                />
                            </div>
                        </div>
                    )}
                    <div key={"sum"} className="row">
                        <div className="title">{getT("reports.summary.discountsSum")}</div>
                        <div className="amount">
                            <CountUp
                                start={0}
                                end={discounts.reduce((sum, a) => sum + +a.sumAmount, 0)}
                                suffix=" Ft"
                                separator="."
                                duration={1}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <ReportsMenu />
            </div>
        </div>
    )
}
export default Summary