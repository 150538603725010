import React, { useState, useEffect } from 'react'
import NumPad from 'views/RegisterDevice/Numpad'
import { isMobileOnly, isTablet } from "react-device-detect"
import QRCode from 'qrcode.react'
import LoadSvg from "components/LoadSvg-v2"
import { useOkeFrame } from "okeoke.client"

export default function Activate(props) {

  const { title, error: propError, requestCode, checkPin, activeScreen } = props
  const [error, setError] = useState(propError || null)
  const [selectedMode, setSelectedMode] = useState("POS")
  const [modeMessage, setModeMessage] = useState("")
  const { frameType, sendMessageToWebView } = useOkeFrame()

  useEffect(() => {
    setError(propError)
  }, [propError])
  
  const handleSelectedMode = (mode) => {
    if(mode === selectedMode) return null
    setSelectedMode(mode)
    setModeMessage(mode === "POS" ? "" : `Biztos, hogy ${mode}-ra váltasz?`)
  }

  const reset = () => {
    localStorage.clear()
    window.location.reload()
  }

  const switchMode = () => {
    if(frameType === "okeElectron") {
      let selectedModeCode
      switch(selectedMode) {
        case "POS": selectedModeCode = 1
        break
        case "KIOSK": selectedModeCode = 2
        break
        default: selectedModeCode = 1
      }
      sendMessageToWebView({ action: 'switchAppMode', data: selectedModeCode })
    }
  }

  if(activeScreen !== "activate") return null

  return <div className={`hidden appear device-activation-root ${isMobileOnly ? 'isMobile' : (isTablet ? 'isTablet' : '')}`}>
    <div className="left-side">
      <div className="logo">
        <img src="https://cdn.okeoke.io/global/barsoft/Barsoft%20logo%20logo+text.svg" alt="logo"/>
      </div>
      <div className='device-activation-request-code'>
        {requestCode === "" && <div className="" onClick={reset}>Mutasd az id-t!</div>}
        {requestCode !== "" && <div>{title}#{requestCode}</div>}
      </div>
      <div className="text">
        <h3>Ez az eszköz még nincs regisztrálva.</h3>Használatához aktiválás szükséges, amit a keretben megadott azonosító alapján az iPanelen tudsz megtenni. <br/><br/>Amennyiben rendelkezel érvényes aktiváló kóddal, annak beírásával is tovább tudsz lépni.
      </div>
    </div>
    <div className="mid">
      <NumPad onSubmit={checkPin} className="device-numPad"/>
    </div>
    <div className="right-side">
      {error != null && <div className='device-activation-error'>{error?.message}</div>}
    </div>
    {frameType === "okeElectron" && <div className="switch-container">
      <div className="switch-mode">
        {/*<div className={`mode ${selectedMode === "POS" ? "selected" : "not-selected"}`} onClick={() => handleSelectedMode("POS")}><LoadSvg name="pos" /></div>*/}
        <div className={`mode ${selectedMode === "KIOSK" ? "selected" : "not-selected"}`} onClick={() => handleSelectedMode("KIOSK")}><LoadSvg name="kiosk" /></div>
      </div>
      <div className="mode-message-container">
        <div className="mode-message">{modeMessage}</div>
        {modeMessage !== "" && <>
        <div className="btn" onClick={switchMode}>Igen</div>
        <div className="btn" onClick={() => handleSelectedMode("POS")}>Nem</div>
        </>}
      </div>
    </div>}
    <div className="qr-container">
      <div className="label">Go to iPanel</div>
      <a href="https://ipanel.okeoke.io/brand/devices" target="blank">
        <QRCode 
          value={`https://ipanel.okeoke.io/brand/devices`} 
          includeMargin={true} 
          renderAs="canvas" 
          size={60} 
          level="H"
        />
      </a>
    </div>
  </div>
}