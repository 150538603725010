import { useState, useEffect } from "react"
import moment from "moment"
import DataTable from "components/DataTable"
import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"
import ReportsMenu from "./ReportsMenu"
import ReportsHead from "./ReportsHead"
import { Bar } from "react-chartjs-2"
import { Chart, registerables } from "chart.js"
import useStatsApi from "apis/useStatsApi"

function Transactions() {

    Chart.register(...registerables)

    const { getOrderByHour } = useStatsApi()
    const { getT } = useMultilang()

    const [list, setList] = useState(null)
    const [sumAmount, setSumAmount] = useState(null)
    const [sumCount, setSumCount] = useState(null)
    const [labels, setLabels] = useState(null)
    const [dataset1, setDataset1] = useState(null)
    const [dataset2, setDataset2] = useState(null)
    const [filter, setFilter] = useState({from: `${moment().format("YYYY-MM-DD")} 00:00:00`, to: `${moment().format("YYYY-MM-DD")} 23:59:59`})

    useEffect(() => {
        loadOrdersHourly()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
      loadOrdersHourly()
      // eslint-disable-next-line
  }, [filter])

    useEffect(() => {
      if(list != null) {

        setLabels(list.map(item => item.hour))

        let data1 = {
          label: getT("reports.transactions.hourlyChart.totalCount"),
          data: list.map(row => row.ordersCount),
          type: "line",
          backgroundColor: ["rgba(255, 159, 64, 0.2)"],
          borderColor: ["rgba(255, 159, 64)"],
          borderWidth: 1,
          yAxisID: "y1",
        }
        setDataset1(data1)

        let data2 = {
          label: getT("reports.transactions.hourlyChart.totalPriceSum"),
          data: list.map(row => +row.totalPriceSum),
          type: "bar",
          borderColor: ["rgba(54, 162, 235)"],
          borderWidth: 1,
          backgroundColor: ["rgba(54, 162, 235, 0.2)"],
          order: 2,
        }
        setDataset2(data2)
      }
      // eslint-disable-next-line
    }, [list])

    const loadOrdersHourly = () => {

      let filters = { fromDate: filter.from, toDate: filter.to }

      getOrderByHour(filters).then(res => {
        if(res.success) {
          setList(res.data.sort((a, b) => a.hour > b.hour ? 1 : -1))
          let sum1 = 0
          let sum2 = 0
          for(let item of res.data) {
            sum1 = sum1 + +item.ordersCount
            sum2 = sum2 + +item.discountPriceSum
          }
          setSumCount(sum1)
          setSumAmount(sum2)
        } else {
          console.log(res)
        }
      })
    }

    const columns = [
      {
        name: "name",
        label: getT("reports.transactions.hour"),
        options: {
            className: "head-align-left cell-align-left",
            sort: false,
            format: row => row.hour,
            type: false,
        },
      },
      {
        name: "count",
        label: getT("reports.transactions.count"),
        options: {
          className: "head-align-right cell-align-right",
          sort: false,
          format: row => row.ordersCount,
          type: false,
        },
      },
      {
        name: "discountPriceSum",
        label: getT("reports.transactions.discountPriceSum"),
        options: {
          className: "head-align-right cell-align-right",
          sort: false,
          format: row => 
          <NumberFormat
            value={+row.discountPriceSum}
            decimalSeparator=","
            suffix=" Ft"
            thousandSeparator="."
            displayType="text"
          />,
          type: false,
        },
      },
      {
          name: "avg",
          label: getT("reports.transactions.avg"),
          options: {
              className: "head-align-right cell-align-right",
              sort: false,
              format: row =>
              <NumberFormat
                  value={Math.round(row.discountPriceSum / row.ordersCount)}
                  decimalSeparator=","
                  suffix=" Ft"
                  thousandSeparator="."
                  displayType="text"
              />,
              type: false,
          },
      }
    ]

    const handleDateChange = (values) => {
      setFilter(values)
    }

    return(
      <div className="reports-container transactions-container">
        <ReportsHead title={getT("reports.transactions.title")} handleDateChange={handleDateChange} filter={filter} />
        <div className="body">
        <div className="box-container">
          <div className="box">
            <div className="label">{getT("reports.transactions.sumAmount")}</div>
            <div className="value">
              <NumberFormat
                value={+sumAmount}
                decimalSeparator=","
                suffix=" Ft"
                thousandSeparator="."
                displayType="text"
              />
            </div>
          </div>
          <div className="box">
            <div className="label">{getT("reports.transactions.sumCount")}</div>
            <div className="value">{sumCount || 0}</div>
          </div>
          <div className="box">
            <div className="label">{getT("reports.transactions.avg")}</div>
            <div className="value">
              <NumberFormat
                value={Math.round(+sumAmount / sumCount)}
                decimalSeparator=","
                suffix=" Ft"
                thousandSeparator="."
                displayType="text"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="table-container">
              <DataTable data={list} columns={columns} />
          </div>
          <div className="chart-container">
            {list != null && labels != null && <Bar
              data={{
                datasets: [dataset1, dataset2],
                labels,
              }}
              width={"100%"}
              height={"100%"}
              options={{
                plugins: {
                  title: {
                    display: false,
                    text: getT("reports.transactions.saleByHours"),
                  },
                },
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    type: "linear",
                    display: true,
                    position: "left",
                  },
                  y1: {
                    type: "linear",
                    display: true,
                    position: "right",

                    // grid line settings
                    grid: {
                      drawOnChartArea: false, // only want the grid lines for one axis to show up
                    },
                  },
                },
              }}
            />}
          </div>
        </div>
      </div>
      <div className="footer">
        <ReportsMenu />
      </div>
    </div>
  )
}
export default Transactions