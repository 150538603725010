import { useState, useEffect, useRef } from "react";

function Pagination(props) {
  const {
    dataLength,
    setShowRowsFromTo,
    selectedPagination,
    setRefOfPagination,
  } = props;
  
  const [paginationIndex, setPaginationIndex] = useState(1);
  const [lastPaginationIndex, setLastPaginationIndex] = useState();
  const refOfPagination = useRef(null);
  const paginationJump = (index) => {
    if (index > lastPaginationIndex || index < 1) return null;
    setShowRowsFromTo([
      (index - 1) * selectedPagination,
      index * selectedPagination,
    ]);
    setPaginationIndex(index);
  };

  useEffect(() => {
    if (dataLength && !selectedPagination) {
      setShowRowsFromTo([0, dataLength]);
    }
    if (dataLength && selectedPagination) {
      let newLastPaginationIndex = Math.ceil(dataLength / selectedPagination);
      setLastPaginationIndex(newLastPaginationIndex);
      setPaginationIndex(1);
      setShowRowsFromTo([0, selectedPagination]);
    }
    // eslint-disable-next-line
  }, [dataLength, selectedPagination]);

    useEffect(() => {
      setRefOfPagination(refOfPagination)
      // eslint-disable-next-line
    },[])

  if (selectedPagination === 0) return <div ref={refOfPagination} className={"pagination-container"}></div>;

  if (lastPaginationIndex === 1) return null;

  return (
    <div ref={refOfPagination} className={"pagination-container"}>
      <div className="pagination-inner">
        <div
          className="pagination-button"
          onClick={() => paginationJump(paginationIndex - 1)}
        >
          {"<"}
        </div>
        {1 !== paginationIndex && (
          <div className="pagination-button" onClick={() => paginationJump(1)}>
            1
          </div>
        )}
        {1 < paginationIndex - 2 && (
          <div className="pagination-button">...</div>
        )}
        {1 < paginationIndex - 1 && (
          <div
            className="pagination-button"
            onClick={() => paginationJump(paginationIndex - 1)}
          >
            {" "}
            {paginationIndex - 1}
          </div>
        )}
        <div className="pagination-button selected">{paginationIndex}</div>
        {paginationIndex + 1 < lastPaginationIndex && (
          <div
            className="pagination-button"
            onClick={() => paginationJump(paginationIndex + 1)}
          >
            {paginationIndex + 1}
          </div>
        )}
        {paginationIndex + 2 < lastPaginationIndex && (
          <div className="pagination-button">...</div>
        )}
        {lastPaginationIndex !== paginationIndex && (
          <div
            className="pagination-button"
            onClick={() =>
              paginationJump(Math.ceil(dataLength / selectedPagination))
            }
          >
            {lastPaginationIndex}
          </div>
        )}
        <div
          className="pagination-button"
          onClick={() => paginationJump(paginationIndex + 1)}
        >
          {">"}
        </div>
      </div>
    </div>
  );
}

export default Pagination;
