import LoadSvg from "components/LoadSvg-v2"
import useMultilang from "intl/useMultilang"
import { OEAsset } from 'okeoke.client'

function ItemEdit(props) {

  const { itemUUID, assembledItem, selectedItemUUID, setSelectedItemUUID } = props
  const { getName } = useMultilang()

  const handleClickOnItem = () => setSelectedItemUUID(itemUUID)

  let itemStyle = {
    color: assembledItem?.attributes?.posColor,
    backgroundColor: assembledItem?.attributes?.posBg
  }

  return (
    <div id={`order-items-item_${itemUUID}`} className={`order-item-container hidden appear ${selectedItemUUID === itemUUID ? "selected" : ""}`} style={itemStyle}>
      {assembledItem.attributes.appImage !== "" && <div className="background-layer"><OEAsset uuid={assembledItem.attributes.appImage} addClass={`item-img`} size='s'/></div>}
      <div onClick={handleClickOnItem} className={`sell-item-count`}></div>
      <div className="order-item-name">
        {getName(assembledItem?.name)}
      </div>
      <div className="bottom-container">
        <div className="icon-btn">
          <LoadSvg name="EditIcon" />
        </div>
        <div className="order-item-price" style={{color: itemStyle.color}}>
          {assembledItem?.price} Ft
        </div>
      </div>
    </div>
  )
}
export default ItemEdit