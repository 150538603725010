import { useContext, useState, useEffect } from "react"
import Modifiers from "./Modifiers"
import Sizes from "./Sizes"
import useMultilang from "intl/useMultilang"
import { useOrderItem } from "okeoke.client"
import LoadSvg from "components/LoadSvg-v2"
import CountModal from "./CountModal"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import NotesModal from "../OrderSummary/NotesModal"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import { ModalContext } from "contexts/ModalContext"
import { MainContext } from "contexts/MainContext"

function OrderItemModal(props) {
  
  const { orderItemUUID, popModal } = props
  
  const { selectedOrderUUID } = useContext(SelectedOrderContext)
  const { language, getT } = useMultilang()
  
  const { assembledItem, setModifierCount, setSize, update, setTakeAway, setCount, setNotes } = useOrderItem(null, selectedOrderUUID, orderItemUUID, null)

  const { addModal } = useContext(ModalContext)
  const { featureList } = useContext(MainContext)

  const [localCount, setLocalCount] = useState(assembledItem?.count)

  useEffect(() => {
    if(assembledItem?.count != null) setLocalCount(assembledItem?.count)
  }, [assembledItem])

  const handleSetModifierCount = (id, count) => {
    setModifierCount(id, count)
  }

  const setItemTakeAway = (takeaway) =>{
    setTakeAway(takeaway)
  }

  const handleChangeCount = (count) => {
    if(count == null) return null
    setLocalCount(count)
    setCount(count)
  }

  const handleSaveAndClose = () => {
    update()
    popModal()
  }

  const trashItem = () => {
    setCount(0)
    handleSaveAndClose()
  }

  const openNotesModal = () => {
    addModal(
      <Modal
        key={uuidv4()}
        className="create-new-category-modal notes-modal"
        onClickLayout={popModal}
      >
        <NotesModal notes={assembledItem.notes} setNotes={setNotes} popModal={popModal}/>
      </Modal>
    )
  }

  const openCountModal = () => {
    addModal(
      <Modal
        key={uuidv4()}
        className="create-new-category-modal count-modal"
        onClickLayout={popModal}
      >
        <CountModal count={assembledItem.count} setCount={handleChangeCount} popModal={popModal}/>
      </Modal>
    )
  }

  return (
    <div className="modifier-root">
      <div className="header-container" >
        <div className="back-icon" onClick={popModal}>
          <LoadSvg name={"ArrowLeftIcon"} />
        </div>
        <div className="header-text"  onClick={popModal}>{getT("orderItem.view.back")}</div>
        <div className="item-name" onClick={openCountModal}>{`${localCount}X ${assembledItem?.name?.[language]}`}</div>
      </div>
      <div className="body">
        <div className="action-buttons">
          <div className={`action-button ${assembledItem?.takeAway == null ? "selected" : ""}`} onClick={()=>{ setItemTakeAway(null) }}>
            <div className="inner-bg"></div>
            <LoadSvg name="XCircle"/>
            <div className="action-text">{getT("orderItem.view.defaultTakeAway")}</div>
          </div>
          <div className={`action-button ${assembledItem?.takeAway ? "selected" : ""}`} onClick={()=>{setItemTakeAway(true)}}>
            <div className="inner-bg"></div>
            <LoadSvg name="TakeAwayIcon"/>
            <div className="action-text">{getT("orderItem.view.takeAway")}</div>
          </div>
          <div className={`action-button ${assembledItem?.takeAway === false ? "selected" : ""}`} onClick={()=>{setItemTakeAway(false)}}>
            <div className="inner-bg"></div>
            <LoadSvg name="EatInIcon"/>
            <div className="action-text">{getT("orderItem.view.eatIn")}</div>
          </div>
        </div>
        <div className="action-buttons">
          <div className={`action-button`} onClick={openNotesModal}>
            <div className="inner-bg"></div>
            <LoadSvg name="notes" />
            <div className="action-text">{getT("orderItem.view.notes")}</div>
          </div>
          <div className={`action-button`} onClick={openCountModal} >
            <div className="inner-bg"></div>
            {/* <LoadSvg name="notes" onClick={toggleNotesVisible}/> */}
            <div className="action-text">{getT("orderItem.view.count")}</div>
          </div>
        </div>
        {featureList.itemModifiersAndSizes === 1 && <div className="modifier-sub-content">
          <Sizes
            sizes={assembledItem?.sizes}
            setSize={setSize}
            selectedSizeUUID={assembledItem?.selectedSizeUUID}
          />
          <Modifiers
            modifierGroups={assembledItem?.modifierGroups}
            setModifierCount={handleSetModifierCount}
          />
        </div>}
      </div>
      <div className="footer">
        <div className="btn btn-warning" onClick={trashItem}>
            <div className="inner-bg"></div>
            <LoadSvg name="BinIcon"/>
            <div className="text">{getT("orderItem.view.deleteItem")}</div>
        </div>
        <div className="btn btn-highlighted" onClick={handleSaveAndClose}>{getT("orderItem.view.save")}</div>
      </div>
    </div>
  )
}

export default OrderItemModal