import useMultilang from "intl/useMultilang"
import moment from "moment"

function DayOpenNeeded(props) {

    const { visible, firstOrderTimeStamp, socketConnected, handleOpenDay, changeOfflineOpen } = props
    
    const { getT } = useMultilang()

    return(
        <div className={`sell-not-allowed day-open ${visible ? "appear" : "hidden"}`}>
            {<div className="text">{getT("sell.dayClosed")}</div>}
            {firstOrderTimeStamp != null && <div className="text">{`${getT("sell.firstOrderTimeStamp")}: ${firstOrderTimeStamp} `}</div>}
            {!socketConnected && <div className="text">{getT("sell.offlineOpen")}</div>}
            {socketConnected && <>
                <input type="date" defaultValue={moment().format("YYYY-MM-DD")} disabled />
                <input type="time" defaultValue={moment().format("HH:mm")} disabled />
                <div className="btn btn-highlighted pulsate-fwd" onClick={handleOpenDay} >{getT("sell.openDay")}</div>
            </>}
            {!socketConnected && <div className="btn btn-highlighted" onClick={() => changeOfflineOpen(true)} >{getT("sell.offlineOpenDay")}</div>}
        </div>
    )
}
export default DayOpenNeeded