import LoadSvg from "components/LoadSvg-v2"

function Button(props) {

    const { disabled, onClick, label, svgName, highlighted } = props

    const handleClick = () => {
        if(disabled) return null

        if(typeof onClick === "function") onClick()
    }

    return(
        <div 
            className={`admin-button ${disabled ? "disabled" : ""} ${highlighted ? "highlighted" : ""}`} 
            onClick={handleClick}>
                <div className="icon"><LoadSvg name={svgName} /></div>
                <div className="label">{label}</div>
        </div>
    )
}
export default Button