import { useContext, useEffect } from "react"
import LanguageDialog from "./LanguageDialog"
import DeviceDialog from "./DeviceDialog"
import { useOrders, useClient, useBaskets, useLocation } from "okeoke.client"
import { useNavigate, useLocation as routerLocation } from "react-router-dom"
import LoadSvg from "components/LoadSvg-v2"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import { ModalContext } from "contexts/ModalContext"
import { MainContext } from "contexts/MainContext"
import { PersonalSettingsContext } from "contexts/PersonalSettingsProvider"
import BasketsModal from "components/BasketsModal"
import NtakModal from "./NtakModal"
import PluggedModal from "./PluggedModal"
import { TutorialContext } from "contexts/TutorialContext"
import SettingsWizard from "components/SettingsWizard"
import { isMobileOnly } from "react-device-detect"

function LeftBar(props) {
  
  const { deviceType, leftBarClosed, setErrors, errors } = props
  const navigate = useNavigate()
  const pathname = routerLocation().pathname
  const { addModal, popModal } = useContext(ModalContext)
  const { reloadNeeded, featureList, settingsWizardDone } = useContext(MainContext)
  const { localAppParams } = useContext(PersonalSettingsContext)
  const { tutorialOn } = useContext(TutorialContext)
  const { orders } = useOrders()
  const { socketAuthenticated } = useClient()
  const { baskets } = useBaskets()
  const { location } = useLocation()

  useEffect(() => {
    if(errors == null) return null
    let values = {
      ...errors, 
      openOrders: featureList.orderManager && Object.values(orders || {}).length > 0,
      settingsWizard: (!isMobileOnly && !settingsWizardDone),
      offline: !socketAuthenticated,
      ntak: location?.ntakProviderID != null && location?.ntakProviderID,
      printerSettings: reloadNeeded
    }
    setErrors(values)
    // eslint-disable-next-line
  }, [orders, localAppParams, featureList, settingsWizardDone, reloadNeeded, socketAuthenticated, location])

  const loadComponentInModal = (modalContent) => {
    addModal(
      <Modal
        key={uuidv4()}
        className="pos-modal-base-skin"
        onClickLayout={popModal}
      >
        {modalContent}
      </Modal>
    )
  }  

  const handleNavigate = (path) => {
    if((!isMobileOnly && tutorialOn)) return null

    navigate(path)
  }

  const openSettingsWizard = () => {
    addModal(
      <Modal key={uuidv4()} className="settings-wizard-modal" onClickLayout={popModal}>
        <SettingsWizard location={location} />
      </Modal>
    )
  }

  return (
    <div className={`left-side-root ${leftBarClosed ? "closed" : "open"}`}>
      {deviceType !== "orderManager" && <div className="top-container">
        <div className={`icon-btn ${pathname === "/" ? "selected" : ""}`} onClick={() => navigate("/")}>
          <LoadSvg name="dollar" />
        </div>
        <div className={`icon-btn ${pathname === "/editor" ? "selected" : ""}`} onClick={() => navigate("/editor")}>
          <LoadSvg name="EditInSquereIcon" />
        </div>
        {featureList.multiBasket === 1 && <div className={`icon-btn ${(!isMobileOnly && tutorialOn) ? "disabled" : ""}`} onClick={() => loadComponentInModal(<BasketsModal/>)}>
          <LoadSvg name="shoppingBag" />
          {Object.values(baskets || {}).length > 1 && <div className="icon-btn-badge">
              {Object.values(baskets || {}).length}
          </div>}
        </div>}
        <div className={`icon-btn ${pathname === "/admin/main" ? "selected" : ""} ${(!isMobileOnly && tutorialOn) ? "disabled" : ""}`} onClick={() => handleNavigate("/admin/main")}>
          <LoadSvg name="settings" />
          {reloadNeeded && <div className="icon-btn-badge">!</div>}
        </div>
        {!isMobileOnly && !settingsWizardDone && <div className={`icon-btn warning pulsate2-fwd`} onClick={openSettingsWizard}>
          <LoadSvg name="settingsAlert" />
        </div>}
        {(featureList.orderManager === 1 || featureList.tableManager === 1) && (
          <div className={`icon-btn ${pathname === "/open-orders" ? "selected" : ""} ${(!isMobileOnly && tutorialOn) ? "disabled" : ""}`} onClick={() => handleNavigate("/open-orders")}>
            <LoadSvg name="PurchaseTagIcon" />
            {Object.values(orders || {}).length > 0 && (
              <div className="icon-btn-badge">
                {Object.values(orders || {}).length}
              </div>
            )}
          </div>
        )}
      </div>}
      <div className="bottom-container">
        <div className={`icon-btn ${location?.ntakProviderID != null && location?.ntakProviderID !== 0 ? "ntak-connected" : "ntak-not-connected"}`} onClick={() => loadComponentInModal(<NtakModal connected={location?.ntakProviderID != null && location?.ntakProviderID !== 0} popModal={popModal} />)}><LoadSvg name="ntak" /></div>
        <div className={`icon-btn ${socketAuthenticated ? "socket-connected-svg" : "socket-not-connected-svg"}`} onClick={() => loadComponentInModal(<PluggedModal plugged={socketAuthenticated} popModal={popModal} />)}>
          {socketAuthenticated ? <LoadSvg name={"Plugged"} /> : <LoadSvg name={"UnPlugged"} />}
        </div>
        <div className="icon-btn" onClick={() => loadComponentInModal(<LanguageDialog />)}>
          <LoadSvg name="globe" />
        </div>
        <div className="icon-btn" onClick={() => loadComponentInModal(<DeviceDialog />)}>
          <LoadSvg name="info" />
        </div>
      </div>
    </div>
  )
}

export default LeftBar
