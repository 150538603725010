import DateRangeSelector from "components/InputFieldDateRange"
import { isMobileOnly } from "react-device-detect"
import moment from "moment"
import useMultilang from "intl/useMultilang"

function DateSelector(props) {

    const { setFilters, filters } = props
    
    const { getT } = useMultilang()

    const onDateChanged = (e) => {
        let from = `${moment(e.fromDate).format("YYYY-MM-DD")} 00:00:00`
        let to = `${moment(e.toDate).format("YYYY-MM-DD")} 23:59:59`
        setFilters({ from, to })
    }
    
    const handleChangeStart = (e) => {
    let from = `${moment(e.fromDate).format("YYYY-MM-DD")} 00:00:00`
    setFilters({ from })
    }
    
    const handleChangeTo = (e) => {
    let to = `${moment(e.toDate).format("YYYY-MM-DD")} 23:59:59`
    setFilters({ to })
    }

    return(
        <>
        <div className="date-selector">
            {!isMobileOnly && <DateRangeSelector
                label={getT("statistics.dashboard.dateRange")}
                name={"dashboardDateRange"}
                onRangeSelected={onDateChanged}
                fromDate={filters.from || moment()}
                toDate={filters.to || moment()}
            />}
        </div>
        {isMobileOnly && <div className="">
            <label htmlFor="start">{getT("settings.transactions.from")}: </label>
            <input type="date" id="start" name="start" value={filters.from || moment().format("YYYY-DD-MM")} onChange={handleChangeStart}/>
            <br/>
            <br/>
            <label htmlFor="start">{getT("settings.transactions.to")}: </label>
            <input type="date" id="to" name="to" value={filters.to || moment().format("YYYY-DD-MM")} onChange={handleChangeTo}/>
        </div>}
        </>
    )
}
export default DateSelector