import useMultilang from "intl/useMultilang"
import LoadSvg from "components/LoadSvg-v2"

function ModifierGroupMultiple(props) {

  const { modifiers, setModifierCount, group } = props
  const { language } = useMultilang()

  const handleClick = (modifier) => {

    let sumModifierCount = modifiers.reduce((sum, a) => sum + +(a?.count || 0), 0)

    if(+group.max !== 0 && sumModifierCount >= group.max) return null

    if(+modifier.max > 0) {
      if(+modifier.count + 1 <= modifier.max) setModifierCount(modifier.uuid, +modifier.count + 1)
    }
    if(+modifier.max === 0) setModifierCount(modifier.uuid, +modifier.count > 0 ? (+modifier?.min > 0 ? +modifier?.min : 0) : 1)
    
    // if(availability?.available === 0 && count > 0){
    //   setModifierCount(id, 0)
    // }

    // if (+count + 1 <= max) {
    //   setModifierCount(id, count + 1);
    // } else if (max === 0) {
    //   setModifierCount(id, count + 1);
    // } else if (max === count && max !== 0) {
    //   setModifierCount(id, min ? min : 0);
    // }
  }

  const handleBin = (e, modifier) => {
    e.stopPropagation()
    setModifierCount(modifier.uuid, +modifier?.min > 0 ? +modifier?.min : 0)
  }
  
  if(modifiers == null) return null

  return (
    <>
      <div className="modifier-group-name">{`${group?.name?.[language]} ${+group?.max > 0 ? "(max: "+group?.max+")" : ""} ${+group?.min > 0 ? "(min: "+group?.min+")" : ""}`}</div>
      <div className="modifiers-container">
        {(modifiers || [])
        .filter(modifier => modifier?.availability?.available === 1 || modifier?.count > 0)
        .map(modifier =>
          <div key={modifier.uuid} className={`modifier-btn ${modifier.count > 0 ? "selected" : ""}`} onClick={() => handleClick(modifier)}>
            <div className="inner-bg"></div>
            {modifier?.availability?.visible === 0 && <div className="visibility-container"><LoadSvg name="visibility" /></div>}
            <div className={`modifier-btn-bin-container ${modifier.max !== 1 && modifier.count > 0 ? "active" : "passive" }`} onClick={(e) => handleBin(e, modifier)}>
              <LoadSvg name="BinIcon" />
            </div>
            <div className={`modifier-btn-text ${modifier.count > 0 ? "active" : ""}`}>
              <div className="modifier-btn-label">
                <div className="modifier-btn-name">{modifier.name?.[language]}</div>
                <div className="count">{`${modifier.count > 1 ? "("+modifier.count+"x)" : ""}`}</div>
              </div>
              <div className="modifier-btn-price">
                +{+modifier.price} Ft
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default ModifierGroupMultiple