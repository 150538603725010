import { useState, useEffect } from "react"
import moment from "moment"
import DataTable from "components/DataTable"
import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"
import ReportsMenu from "./ReportsMenu"
import ReportsHead from "./ReportsHead"
import useStatsApi from "apis/useStatsApi"

function ItemSales() {
    
    const { getTopItemsSum } = useStatsApi()
    const { getT, getName, language } = useMultilang()

    const [itemsSum, setItemsSum] = useState(null)
    const [sumAmount, setSumAmount] = useState(null)
    const [sumCount, setSumCount] = useState(null)
    const [filter, setFilter] = useState({from: `${moment().format("YYYY-MM-DD")} 00:00:00`, to: `${moment().format("YYYY-MM-DD")} 23:59:59`})

    useEffect(() => {
      loadItems()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
      loadItems()
      // eslint-disable-next-line
  }, [filter])

    const loadItems = () => {

      let filters = { fromDate: filter.from, toDate: filter.to, limit: 2000 }

      getTopItemsSum(filters).then(res => {
        if(res.success) {
          setItemsSum(res.data)
          let sum = 0
          let sum1 = 0
          for(let item of res.data) {
            sum = sum + parseInt(item.discountPriceSum)
            sum1 = sum1 + parseInt(item.totalCount)
          }
          setSumAmount(sum)
          setSumCount(sum1)
        } else {
          console.log(res)
        }
      })
    }

    const sortBy = (key, ascend) => {
      if (key === "name") {
        if (ascend)
          itemsSum.sort((a, b) => {
            return ((a.name?.[language] > b.name?.[language]) ? -1 : 1);
          })
        if (!ascend)
          itemsSum.sort((a, b) => {
            return ((a.name?.[language] > b.name?.[language]) ? 1 : -1);
          })
      }
      if (key === "count") {
        if (ascend)
          itemsSum.sort((a, b) => {
            return ((a.totalCount > b.totalCount) ? -1 : 1);
          })
        if (!ascend)
          itemsSum.sort((a, b) => {
            return ((a.totalCount > b.totalCount) ? 1 : -1);
          })
      }
      if (key === "amount") {
        if (ascend)
          itemsSum.sort((a, b) => {
            return ((parseInt(a.discountPriceSum) > parseInt(b.discountPriceSum)) ? -1 : 1);
          })
        if (!ascend)
          itemsSum.sort((a, b) => {
            return ((parseInt(a.discountPriceSum) > parseInt(b.discountPriceSum)) ? 1 : -1);
          })
      }
    }

    const columns = [
        {
          name: "name",
          label: getT("reports.itemName"),
          options: {
            className: "head-align-left cell-align-left",
            sort: sortBy,
            format: row => getName(row?.name),
            type: false,
          },
        },
        {
          name: "count",
          label: getT("reports.itemCount"),
          options: {
            className: "head-align-right cell-align-right",
            sort: sortBy,
            format: row => row.totalCount,
            type: false,
          },
        },
    ]

    const columns2 = [
        {
          name: "name",
          label: getT("reports.itemName"),
          options: {
            className: "head-align-left cell-align-left",
            sort: sortBy,
            format: row => getName(row?.name),
            type: false,
          },
        },
        {
          name: "amount",
          label: getT("reports.itemPriceSum"),
          options: {
            className: "head-align-right cell-align-right",
            sort: sortBy,
            format: row => 
                <NumberFormat
                    value={parseInt(row.discountPriceSum)}
                    decimalSeparator=","
                    suffix=" Ft"
                    thousandSeparator="."
                    displayType="text"
                />,
            type: false,
          },
        },
    ]

    const handleDateChange = (values) => {
      setFilter(values)
    }

    return(
      <div className="reports-container item-sales-container">
        <ReportsHead title={getT("reports.itemSales.title")} handleDateChange={handleDateChange} filter={filter} />
        <div className="body">
          <div className="box-container">
            <div className="box">
              <div className="label">{getT("reports.itemSales.sumAmount")}</div>
              <div className="value">
                <NumberFormat
                  value={parseInt(sumAmount)}
                  decimalSeparator=","
                  suffix=" Ft"
                  thousandSeparator="."
                  displayType="text"
                />
              </div>
            </div>
            <div className="box">
              <div className="label">{getT("reports.itemSales.sumCount")}</div>
              <div className="value">{`${sumCount || ""} ${getT("reports.itemSales.piece")}`}</div>
            </div>
          </div>
          <div className="table-container">
              <DataTable data={itemsSum} columns={columns2} />
              <DataTable data={itemsSum} columns={columns} />
          </div>
        </div>
        <div className="footer">
          <ReportsMenu />
        </div>
      </div>
    )
}
export default ItemSales