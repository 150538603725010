import OrderManager from "views/OrderManager"
import Sell from "views/Sell"
import Editor from "views/Editor"
import EditItem from "views/Editor/EditItem"
import Settings from "views/Admin"
import Statistics from "views/Admin/Statistics"
import Orders from "views/Admin/Orders"
import DeviceSettings from "views/Admin/Device/DeviceSettings"
import FiscalPrinterSettings from "views/Admin/Printers/FiscalPrinterSettings"
import PosPrinterSettings from "views/Admin/Printers/PosPrinterSettings"
import CommonPrinterSettings from "views/Admin/Printers/CommonPrinterSettings"
import DeviceCustomization from "views/Admin/Device/DeviceCustomization"
import ItemSales from "views/Admin/Reports/ItemSales"
import VatSum from "views/Admin/Reports/VatSum"
import Transactions from "views/Admin/Reports/Transactions"
import Discounts from "views/Admin/Reports/Discounts"
import Summary from "views/Admin/Reports/Summary"
import PaymentType from "views/Admin/Reports/PaymentType"
import Users from "views/Admin/Reports/Users"
import Devices from "views/Admin/Reports/Devices"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorFallback } from "utils/ErrorHandler"
import { Route, Routes } from "react-router-dom"
import { PreviousOrderProvider } from "contexts/PreviousOrderContext"

function Router() {

  return (
    <Routes>
      <Route path={"/"} exact element={<ErrorBoundary FallbackComponent={ErrorFallback}><Sell /></ErrorBoundary>}/>
      <Route path={"editor"} exact element={<ErrorBoundary FallbackComponent={ErrorFallback}><Editor/></ErrorBoundary>}/>
      <Route path={"edit-item/:itemUUID"} exact element={<ErrorBoundary FallbackComponent={ErrorFallback}><EditItem/></ErrorBoundary>}/>
      <Route path={"open-orders"} exact element={<ErrorBoundary FallbackComponent={ErrorFallback}><OrderManager /></ErrorBoundary>}/>
      <Route path={"admin"} >
        <Route path={"main"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><Settings /></ErrorBoundary>} />
        <Route path={"orders"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><PreviousOrderProvider><Orders /></PreviousOrderProvider></ErrorBoundary>} />
        <Route path={"statistics"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><Statistics /></ErrorBoundary>}/>
        <Route path={"device"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><DeviceSettings /></ErrorBoundary>}/>
        <Route path={"fiscal-printer"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><FiscalPrinterSettings /></ErrorBoundary>}/>
        <Route path={"pos-printer"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><PosPrinterSettings /></ErrorBoundary>}/>
        <Route path={"common-printer"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><CommonPrinterSettings /></ErrorBoundary>}/>
        <Route path={"device-customization"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><DeviceCustomization /></ErrorBoundary>}/>
        <Route path={"item-sales"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><ItemSales /></ErrorBoundary>}/>
        <Route path={"vat-sum"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><VatSum /></ErrorBoundary>}/>
        <Route path={"transactions"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><Transactions /></ErrorBoundary>}/>
        <Route path={"discounts"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><Discounts /></ErrorBoundary>}/>
        <Route path={"summary"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><Summary /></ErrorBoundary>}/>
        <Route path={"payment-type"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><PaymentType /></ErrorBoundary>}/>
        <Route path={"users"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><Users /></ErrorBoundary>}/>
        <Route path={"devices"} element={<ErrorBoundary FallbackComponent={ErrorFallback}><Devices /></ErrorBoundary>}/>
      </Route>
    </Routes>
  )
}
export default Router
