import { useState, useEffect, useContext } from "react"
//import LoadSvg from "components/LoadSvg-v2"
import TextField from "components/TextField"
import InputFieldSelect from "components/InputFieldSelect"
import useMultilang from "intl/useMultilang"
import InputFieldMultiSelect from "components/InputFieldMultiSelect"
import Sizes from "./Sizes"
import { toast } from "react-toastify"
import { ModalContext } from "contexts/ModalContext"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import useItemsApi from "apis/catalog/useItemsApi"
import useCategoriesApi from "apis/catalog/useCategoriesApi"
import useVatGroupsApi from "apis/catalog/useVatGroupsApi"
import useAvailabilityApi from "apis/catalog/useAvailabilityApi"

function EditItem(props) {

  const { itemUUID } = props

  const { getT, language } = useMultilang()
  const { addModal, popModal } = useContext(ModalContext)
  const { getItem, getItemCategories, setItem, setItemCategories } = useItemsApi()
  const { getCategories } = useCategoriesApi()
  const { getVatGroups } = useVatGroupsApi()
  const { getLocationAvailability, setAvailabilities } = useAvailabilityApi()

  const [localItem, setLocalItem] = useState(null)
  const [categories, setCategories] = useState({})
  const [vatGroups, setVatGroups] = useState([])
  const [itemAvailability, setItemAvailability] = useState(null)
  const [itemEnabled, setItemEnabled] = useState(false)

  useEffect(() => {
    getCategories().then(res => {
      if(res.success) {
        let obj = {}
        for(let categ of res.data) {
          obj[categ.uuid] = categ
        }
        setCategories(obj)
      } else {
        console.log(res)
      }
    })
    getVatGroups().then(res => {
      if(res.success) {
        setVatGroups(res.data)
      } else {
        console.log(res)
      }
    })
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(itemUUID != null) {
      loadItem()
    }
    // eslint-disable-next-line
  }, [itemUUID])

  useEffect(() => {
    if(itemAvailability != null) {
      let availabilityArray = []
      for(let row of Object.values(itemAvailability)) {
        availabilityArray.push(row.available)
      }
      setItemEnabled(availabilityArray.includes(1))
    }
  }, [itemAvailability])

  const loadAvailability = (item) => {
    getLocationAvailability("size").then(res => {
      if(res.success) {
        let itemSizeAvailability = {}
        for(let size of item.sizes) {
          itemSizeAvailability[size.uuid] = (res.data?.[size.uuid])          
        }
        setItemAvailability(itemSizeAvailability)
      } else {
        console.log(res)
      }
    })
  }

  const setItemName = value => setLocalItem({ ...localItem, name: { ...localItem.name, [language]: value } })

  const handleChange = (e) => {
    let newValues = { ...localItem, [e.target.name]: e.target.value }
    setLocalItem(newValues)
    handleUpdate(newValues)
  }

  const enableItem = (value) => {
    let availabilities = []
    for(let row of Object.values(itemAvailability)) {
      availabilities.push({
        locationUUID: row.locationUUID,
        itemUUID: row.itemUUID,
        available: value
      })
    }
    setAvailabilities(availabilities).then(res => {
      if(res.success) {
        loadAvailability(localItem)
      } else {
        console.log(res)
      }
    })
  }

  const inputFieldMultiSelectHeaderFormat = (e) => {
    if (e.length === 0) return getT("editItem.view.category.noneSelected")
    if (e.length === 1) return categories[e[0]]?.name?.[language]
    return e.length + " " + getT("editItem.view.category.selected")
  }

  const inputFieldMultiSelectOnChange = (e) => {
    let itemCategories = {}
    let foundItemDefault = 0
    for (let categ of localItem.categories) {
      if (categ.itemDefault) {
        foundItemDefault = categ.itemDefault
      }
      itemCategories = {
        ...itemCategories,
        [categ.uuid]: {
          itemUUID: localItem.uuid,
          categoryUUID: categ.uuid,
          enabled: 0,
          itemDefault: categ.itemDefault,
        },
      }
    }
    for (let categUUID of e) {
      itemCategories = {
        ...itemCategories,
        [categUUID]: {
          itemUUID: localItem.uuid,
          categoryUUID: categUUID,
          enabled: 1,
          itemDefault:
            localItem.categories.filter(categ => categUUID === categ.uuid && categ.itemDefault === 1).length > 0 ? 1 : 0,
        },
      }
    }
    itemCategories = Object.values(itemCategories || {})
    if (foundItemDefault === 0 && itemCategories.length > 0) {
      itemCategories[0] = { ...itemCategories[0], itemDefault: 1 }
    }
    setItemCategories(itemCategories)
  }

  const loadItem = () => {
    getItem(itemUUID, { getItemSizes: true }).then(res => {
      if(res.success) {
        getItemCategories(itemUUID).then(categRes => {
          if(categRes.success) {
            let itemData = {...res.data, categories: categRes.data}
            setLocalItem(itemData)
            loadAvailability(itemData)
          } else {
            console.log(categRes)
          }
        })
      } else {
        console.log(res)
        toast.error(res.message)
      }
    })
  }

  const handleUpdate = newValues => {
    setItem(newValues || localItem).then(res => {
      if(res.success) {
        toast.success(res.message)
      } else {
        toast.error(res.message)
      }
    })
  }
  
  const openSizesModal = () => {
    addModal(
      <Modal
        key={uuidv4()}
        className="create-new-item-modal sizes-modal"
        onClickLayout={popModal}
      >
        <Sizes itemUUID={localItem.uuid} />
      </Modal>
    )
  }

  if (localItem === null) return <div className="summary-root item-editor-container-root"></div>

  return (
    <div className="summary-root item-editor-container-root">
      <div className="header">
        <div className="text">{localItem.name?.[language]}</div>
      </div>
      <div className="editor-container">
        <div className="enabled-container">
            <div className={`switch-btn enabled ${itemEnabled ? "active" : "inactive"}`} onClick={() => enableItem(1)}>{getT("editItem.enabled")}</div>
            <div className={`switch-btn not-enabled ${!itemEnabled ? "active" : "inactive"}`} onClick={() => enableItem(0)}>{getT("editItem.notEnabled")}</div>
        </div>
        <div className="inputfield-containers">
          <div className="item-editor-inputfield">
            <div className="upper-label">
              {getT("editItem.view.itemName")}
            </div>
            <TextField
              type="text"
              name="name"
              onChange={e => setItemName(e.target.value)}
              onBlur={() => handleUpdate()}
              value={localItem.name?.[language]}
            />
          </div>
          <div className="item-editor-inputfield">
            <div className="upper-label">
              {getT("editItem.view.Category")}
            </div>
            <InputFieldMultiSelect
              className="multi-select-pos-design"
              headerFormat={inputFieldMultiSelectHeaderFormat}
              onChange={inputFieldMultiSelectOnChange}
              selectedElements={(localItem?.categories || []).map(categ => categ.uuid)}
              options={Object.values(categories || {}).map(categ => { return { title: categ.name?.[language], value: categ.uuid } })}
              onClose={() => { loadItem() }}
            />
          </div>
          <div className="item-editor-inputfield">
            <div className="upper-label">
              {getT("editItem.view.VATGroup")}
            </div>
            <InputFieldSelect
              className={"create-new-item-root-input-field-select"}
              options={(vatGroups || []).map(vat => { return { value: vat.id, title: `${vat.name} (${parseInt(vat?.vat || 0)}% / ${parseInt(vat?.vatTA || 0)}%)` } })}
              name="vatGroupID"
              onChange={handleChange}
              value={localItem.vatGroupID}
            />
          </div>
        </div>
        <div className="btn" onClick={openSizesModal}>{getT("editItem.view.sizes")}</div>
      </div>
    </div>
  )
}

export default EditItem