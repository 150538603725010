import { useMemo } from "react"
import { useDevice } from "okeoke.client"
import LoadSvg from "components/LoadSvg-v2"
import useMultilang from "intl/useMultilang"
import moment from "moment"
import consts from "const"

function OrderIcons(props) {

    const { insertTimestamp, orderIdentifier, insertDeviceType, paymentsNeeded, selectedOrderUUID, sysUserUUID } = props
    
    const { localUsers } = useDevice()
    const { getT } = useMultilang()

    const sysUserName = useMemo(() => {
        if(sysUserUUID == null) return ""
        let name = getT("openOrders.orderCard.noName")
        for(let localUser of localUsers) {
          if(localUser.userUUID === sysUserUUID) name = localUser.firstName
        }
        return name
      // eslint-disable-next-line
    }, [selectedOrderUUID, localUsers])

    return(
        <div className="icons">
          <div className="icon-container">
            <LoadSvg name="clock" />
            <div className="text">
              {moment(insertTimestamp).format("HH:mm:ss")}
            </div>
          </div>
          <div className="icon-container">
            <LoadSvg name="avatar" />
            <div className="text">
              {orderIdentifier === "" ? sysUserName : orderIdentifier}
            </div>
          </div>
          <div className="icon-container">
            <LoadSvg name={consts.DEVICENAMES[insertDeviceType]} />
            <div className="text">
              {consts.DEVICENAMES[insertDeviceType]}
            </div>
          </div>
          <div className="icon-container">
            <LoadSvg name="status" />
            <div className="text">
              {getT(`openOrder.orderCard.${paymentsNeeded > 0 ? "paymentsNeeded" : "paid"}`)}
            </div>
          </div>
        </div>
    )
}
export default OrderIcons