import { useIPanelApi, useClient } from "okeoke.client"

export default function useItemsApi() {

  const { post, get } = useIPanelApi()
  const { brandID, selectedLocationUUID } = useClient()


  const getItems = async (filter, options) => {
    try {
      const response = await get(`brand/catalog/items/all?brandID=${brandID}&filter=${JSON.stringify(filter || {})}&options=${JSON.stringify(options || {})}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const getItem = async (itemUUID, options) => {
    try {
      const response = await get(`brand/catalog/items/${itemUUID}?brandID=${brandID}&options=${JSON.stringify(options || {})}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const getItemCategories = async (itemUUID) => {
    try {
      const response = await get(`brand/catalog/items/categories/${itemUUID}?brandID=${brandID}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const getItemModifierGroups = async (itemUUID) => {
    try {
      const response = await get(`brand/catalog/items/modifierGroups/${itemUUID}?brandID=${brandID}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const getItemSizes = async (itemUUID) => {
    try {
      const response = await get(`brand/catalog/items/sizes/${itemUUID}?brandID=${brandID}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const getItemUpsales = async (itemUUID) => {
    try {
      const response = await get(`brand/catalog/items/upsales/${itemUUID}?brandID=${brandID}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setItem = async (item) => {
    let body = {
      brandID,
      item: {...item, locations: [selectedLocationUUID]},
    }
    try {
      const response = await post(`brand/catalog/items`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setItems = async (items) => {
    let body = {
      brandID,
      items,
      locations: [selectedLocationUUID]
    }
    try {
      const response = await post(`brand/catalog/items/bulk`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setItemCategory = async (itemCategory) => {
    let body = {
      brandID,
      itemCategory
      /*itemCategory: {
        itemUUID,
        categoryUUID,
        enabled,
        itemDefault
      }*/ 
    }
    try {
      const response = await post(`brand/catalog/items/category`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setItemPrepCounter = async (prepCounter) => {
    let body = {
      brandID,
      prepCounter
      /*prepCounter: {
        itemUUID,
        prepCounterUUID,
        enabled
      }*/ 
    }
    try {
      const response = await post(`brand/catalog/items/prepCounter`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setItemPrepCounters = async (itemPrepCounters) => {
    let body = {
      brandID,
      itemPrepCounters
      /*itemPrepCounters: [{
        itemUUID,
        prepCounterUUID,
        enabled
      }]*/ 
    }
    try {
      const response = await post(`brand/catalog/items/prepCounters`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setItemPrepCountersOrder = async (itemUUID, prepCounters) => {
    let body = {
      brandID,
      itemUUID,
      prepCounters
    }
    try {
      const response = await post(`brand/catalog/items/prepCounters/order`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setItemCategories = async (itemCategories) => {
    let body = {
      brandID,
      itemCategories
      /*itemCategoris: [{
        itemUUID,
        categoryUUID,
        enabled,
        itemDefault
      }]*/ 
    }
    try {
      const response = await post(`brand/catalog/items/categories`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setItemModifierGroup = async (itemModifierGroup) => {
    let body = {
      brandID,
      itemModifierGroup
      /*modifierGroup: {
        itemUUID,
        modifierGroupUUID,
        enabled
      }*/ 
    }
    try {
      const response = await post(`brand/catalog/items/modifierGroup`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setItemModifierGroups = async (itemModifierGroups) => {
    let body = {
      brandID,
      itemModifierGroups
      /*modifierGroups: [{
        itemUUID,
        modifierGroupUUID,
        enabled
      }]*/ 
    }
    try {
      const response = await post(`brand/catalog/items/modifierGroups`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setItemModifierGroupsOrder = async (itemUUID, modifierGroups) => {
    let body = {
      brandID,
      itemUUID,
      modifierGroups
    }
    try {
      const response = await post(`brand/catalog/items/modifierGroups/order`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setItemUpsale = async (itemUpsale) => {
    let body = {
      brandID,
      itemUpsale
      /*itemUpsale: {
        itemUUID,
        upsaleTemplateUUID,
        enabled
      }*/
    }
    try {
      const response = await post(`brand/catalog/items/upsale`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setItemUpsales = async (itemUpsales) => {
    let body = {
      brandID,
      itemUpsales
      /*itemUpsale: {
        itemUUID,
        upsaleTemplateUUID,
        enabled
      }*/
    }
    try {
      const response = await post(`brand/catalog/items/upsales`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setItemSizesOrder = async (itemUUID, sizes) => {
    let body = {
      brandID,
      itemUUID,
      sizes
    }
    try {
      const response = await post(`brand/catalog/items/sizes/order`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  return {
    getItems,
    getItem,
    getItemCategories,
    getItemModifierGroups,
    getItemSizes,
    getItemUpsales,
    setItem,
    setItems,
    setItemCategory,
    setItemPrepCounter,
    setItemPrepCounters,
    setItemPrepCountersOrder,
    setItemCategories,
    setItemModifierGroup,
    setItemModifierGroups,
    setItemModifierGroupsOrder,
    setItemUpsale,
    setItemUpsales,
    setItemSizesOrder
  }
}