function setParams() {

    document.documentElement.style.setProperty("--orderItemsColumns", "3")
    document.documentElement.style.setProperty("--orderItemHeight", "8em")
    document.documentElement.style.setProperty("--orderItemFontWeight", "400")
    document.documentElement.style.setProperty("--orderItemFontSize", "16px")
    document.documentElement.style.setProperty("--orderItemShadow", "unset")
    document.documentElement.style.setProperty("--ten-color", "#3F9AB7")
    document.documentElement.style.setProperty("--sixty-color", "#252836")
    document.documentElement.style.setProperty("--thirty-color", "#1F1D2B")
    document.documentElement.style.setProperty("--text-color", "#FFFFFF")
    document.documentElement.style.setProperty("--header-background","#17151F")
    document.documentElement.style.setProperty("--border-color","#393C49")
    document.documentElement.style.setProperty("--fontFamily","'Nunito', sans-serif")
    document.documentElement.style.setProperty("--sellRootFlexDirection", "row")  
    document.documentElement.style.setProperty("--verticalCategWidth", "190px")  
  
    let localCustomParams = localStorage["pos-saved-customization"]
    let customParams

    try {
        customParams = localCustomParams != null ? JSON.parse(localCustomParams) : null
    } catch (e) {
        console.log(e)
    }
    if (customParams != null) {
      if (customParams?.orderItemsColumns != null) {
        document.documentElement.style.setProperty(
          "--orderItemsColumns",
          //"1fr ".repeat(customParams?.orderItemsColumns)
          customParams?.orderItemsColumns
        )
      }
      if (customParams?.categoriesTop != null) {
        document.documentElement.style.setProperty(
          "--sellRootFlexDirection",
          customParams?.categoriesTop ? "column" : "row"
        ) 
      }  
      if (customParams?.orderItemHeight != null) {
        document.documentElement.style.setProperty(
          "--orderItemHeight",
          `${customParams?.orderItemHeight}em`
        )
      }
      if (customParams?.orderItemFontWeight != null) {
        document.documentElement.style.setProperty(
          "--orderItemFontWeight",
          `${customParams?.orderItemFontWeight}`
        )
      }
      if (customParams?.orderItemFontSize != null) {
        document.documentElement.style.setProperty(
          "--orderItemFontSize",
          `${customParams?.orderItemFontSize}px`
        )
      }
      if (customParams?.buttonColor != null) {
        document.documentElement.style.setProperty(
          "--ten-color",
          `${customParams?.buttonColor}`
        )
      }
      if (customParams?.orderItemShadow != null) {
        document.documentElement.style.setProperty(
          "--orderItemShadow",
          `${customParams?.orderItemShadow}`
        )
      }
      if (customParams?.fontFamily != null) {
        document.documentElement.style.setProperty(
          "--fontFamily",
          `${customParams?.fontFamily}`
        )
      }
      if (customParams?.verticalCategWidth != null) {
        document.documentElement.style.setProperty(
          "--verticalCategWidth",
          `${customParams?.verticalCategWidth}px`
        )
      }
      if (customParams?.colorMode === "light") {
        document.documentElement.style.setProperty(
          "--sixty-color",
          "#F5F5F5"
        )
        document.documentElement.style.setProperty(
          "--thirty-color",
          "#FAFAFA"
        )
        document.documentElement.style.setProperty(
          "--text-color",
          "#000000"
        )
        document.documentElement.style.setProperty(
          "--border-color",
          "#D9D9D9"
        )
        document.documentElement.style.setProperty(
          "--orderItemShadow",
          "drop-shadow(0 0 2px rgba(217, 217, 217, 0.66))"
        )
      }
      if(customParams?.colorMode === "dark") {
        document.documentElement.style.setProperty(
          "--sixty-color",
          "#252836"
        )
        document.documentElement.style.setProperty(
          "--thirty-color",
          "#1F1D2B"
        )
        document.documentElement.style.setProperty(
          "--text-color",
          "#FFFFFF"
        )
        document.documentElement.style.setProperty(
          "--header-background",
          "#17151F"
        )
        document.documentElement.style.setProperty(
          "--border-color",
          "#393C49"
        )
      }
    }
}

export { setParams }