import { useContext, useEffect, useState } from "react"
import { MainContext } from "contexts/MainContext"
import useMultilang from "intl/useMultilang"
import { useClient } from "okeoke.client"
import Item from "./Item"
//import { toast } from "react-toastify"
import PageSelector from "components/Pagination-v2/PageSelector"
import useItemsApi from "apis/catalog/useItemsApi"

function EditItems(props) {

  const { rows, cols, setSelectedItemUUID, selectedItemUUID } = props

  const { selectedCategUUID } = useContext(MainContext)
  const { language } = useMultilang()
  const { getItems } = useItemsApi()
  const { catalogUpdated } = useClient()

  const [categItems, setCategItems] = useState(null)
  const [paginationNames, setPaginationNames] = useState([])

  const sortFunction = (a, b) => a.name?.[language] > b.name?.[language] ? 1 : -1

  const loadItems = () => {
    getItems({ categoryUUID: selectedCategUUID }).then(res => {
      if(res.success) {
        setCategItems(res.data.sort(sortFunction))
      } else {
        console.log(res)
      }
    })
  }

  useEffect(() => {
    if(selectedCategUUID) loadItems()
    // eslint-disable-next-line
  }, [selectedCategUUID])

  useEffect(() => {
    setPaginationNames((categItems || []).filter((x, index) => index % (rows*cols) === 0).map(item => { return({name: item?.name, uuid: item.uuid}) }))
    // eslint-disable-next-line
  }, [categItems])

  useEffect(() => {
    loadItems()
    // eslint-disable-next-line
  }, [catalogUpdated])

  if(categItems == null) return null

  return (
    <>
    {categItems.map((item, index) => 
      <Item 
        key={index} 
        itemUUID={item.uuid} 
        assembledItem={item} 
        setSelectedItemUUID={setSelectedItemUUID}
        selectedItemUUID={selectedItemUUID}
      />)}
    <PageSelector selectableItems={paginationNames} />
    </>
  )
}
export default EditItems