import { useContext } from "react"
import { useItems, useOrderItem } from "okeoke.client"
import LoadSvg from "components/LoadSvg-v2"
import useMultilang from "intl/useMultilang"
import { MainContainerContext } from "contexts/MainContainerProvider"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import NumberFormat from "react-number-format"

function Item(props) {

  const { assembledItem, openOrderItemModal, showDetails } = props
  const { itemMultiplier, setItemMultiplier } = useContext(MainContainerContext)
  const { selectedOrderUUID } = useContext(SelectedOrderContext)
  const { getName, getT } = useMultilang()
  const { setCount, update } = useOrderItem(null, selectedOrderUUID, assembledItem.orderItemUUID, null)
  const { sizes } = useItems()

  const handleOnClick = () => {
    if(itemMultiplier > 0) {
      setCount(itemMultiplier)
      update()
      setItemMultiplier(0)
    } else {
      openOrderItemModal()
    }
  }

  return (
    <div className={`basket-item-container`}>
      <div className="item-container" onClick={handleOnClick}>
        <div className="top-row">
          <div className="name">{`${assembledItem?.count}x ${getName(assembledItem?.name)} ${Object.values(assembledItem?.sizes || {}).length > 1 ? `(${getName(sizes?.[assembledItem?.selectedSizeUUID]?.name)})` : ""} ${assembledItem?.takeAway ? `(${getT("item.takeAway.takeAway")})` : ""}`}</div>
          <div className="price">
            <NumberFormat
              value={+assembledItem?.discountPrice}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
        </div>
        {showDetails && <div className="modifier-rows-container">
          <div className={`modifier-row`}>
            <div className="name">{getT("item.price")}</div>
            <div className="price">
              <NumberFormat
                value={+assembledItem?.price}
                decimalSeparator=","
                suffix=" Ft"
                thousandSeparator="."
                displayType="text"
              />
            </div>
          </div>
          <>
            {(assembledItem?.modifierGroups || []).length > 0 && (assembledItem?.modifierGroups || []).map((modifiergroup) =>
              modifiergroup?.modifiers
                ?.filter((modifier) => modifier?.count > 0)
                .map((modifier, index) => 
                  <div key={index} className={`modifier-row ${modifier.availability.available === 1 ? "" : "not-available"}`}>
                    <div className="name">{`${modifier.count}x ${getName(modifier.name)}`}</div>
                    <div className="price">
                      <NumberFormat
                        value={+modifier?.price}
                        decimalSeparator=","
                        suffix=" Ft"
                        thousandSeparator="."
                        displayType="text"
                      />
                    </div>
                  </div>
                )
            )}
            {assembledItem?.packagePrice > 0 &&
              <div className="modifier-row">
                <div className="name">{getName(assembledItem?.package.name)}</div>
                <div className="price">
                  <NumberFormat
                    value={+assembledItem?.packagePrice}
                    decimalSeparator=","
                    suffix=" Ft"
                    thousandSeparator="."
                    displayType="text"
                  />
                </div>
              </div>}
            <div className={`modifier-row`}>
              <div className="name">{getT("item.serviceFee")}</div>
              <div className="price">
                <NumberFormat
                  value={+assembledItem?.serviceFeeNoDiscount / +assembledItem?.count}
                  decimalSeparator=","
                  suffix=" Ft"
                  thousandSeparator="."
                  displayType="text"
                  />
              </div>
            </div>
            <div className={`modifier-row border-top`}>
              <div className="name">{getT("item.sumPrice")}</div>
              <div className="price">
                <NumberFormat
                  value={+assembledItem?.totalPriceUnit}
                  decimalSeparator=","
                  suffix=" Ft"
                  thousandSeparator="."
                  displayType="text"
                  />
              </div>
            </div>
            {(assembledItem?.discounts || []).map(discount =>
              <div key={discount.id} className="modifier-row discount">
                <div className="name">{getName(discount.name)}</div>
                <div className="price">
                  <NumberFormat
                    value={-(+discount.amount+discount.packageAmount)}
                    decimalSeparator=","
                    suffix=" Ft"
                    thousandSeparator="."
                    displayType="text"
                  />
                </div>
              </div>
            )}
            {assembledItem?.notes != null && assembledItem?.notes !== "" && <div className="modifier-row notes">{`${getT("item.notes")}: ${assembledItem?.notes}`}</div>}
          </>
          {assembledItem?.takeAway != null && <div className="take-away-row">
            <div className="icon-btn take-away-icon">
              <LoadSvg
                name={assembledItem?.takeAway ? "TakeAwayIcon" : "EatInIcon"}
              />
            </div>
            <div className="take-away-text">({getT(`item.takeAway.${assembledItem?.takeAway ? "takeAway" : "eatIn"}`)})</div>
            </div>
          }
        </div>}
      </div>
    </div>
  )
}
export default Item