import { useIPanelApi, useClient } from "okeoke.client"

export default function useCatalogAvailability() {

  const { post, get } = useIPanelApi()
  const { brandID, deviceLocationUUID } = useClient()

  const getAnyAvailability = async (itemUUID) => {
    try {
      const response = await get(`brand/catalog/availability/any/${itemUUID}?brandID=${brandID}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const getLocationAvailability = async (type, filter) => {
    let filterString = filter != null ? `&filter=${JSON.stringify(filter || {})}` : ""
    try {
      const response = await get(`brand/catalog/availability/location/${deviceLocationUUID}?brandID=${brandID}&type=${type || ""}${filterString}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const getLocationAllAvailability = async (filter) => {
    try {
      const response = await get(`brand/catalog/availability/location/all?brandID=${brandID}&filter=${JSON.stringify(filter || {})}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setAvailability = async (availability) => {
    let body = {
        brandID,
        availability
        /*availability: {
            locationUUID,
            itemUUID,
            visible,
            forceHide
        }*/
    }
    try {
      const response = await post(`brand/catalog/availability`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setAvailabilities = async (availabilities) => {
    let body = {
        brandID,
        availabilities
        /*availability: {
            locationUUID,
            itemUUID,
            visible,
            forceHide
        }*/
    }
    try {
      const response = await post(`brand/catalog/availability/bulk`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  return {
    getAnyAvailability,
    getLocationAvailability,
    getLocationAllAvailability,
    setAvailability,
    setAvailabilities
  }
}