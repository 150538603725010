import { useEffect, useMemo, useRef, useState } from "react"
import { useOrder, OrderStatus, OrderStates, useDevice, useClient } from "okeoke.client"
import moment from "moment"
import useMultilang from "intl/useMultilang"
import OrderController from "./OrderController"
import OrderItemController from "./OrderItemController"
import OrderCardItem from "./OrderCardItem"
import LoadSvg from "components/LoadSvg-v2"
import { toast } from "react-toastify"
import consts from "const"

function OrderCard(props) {

  const { orderUUID, pingedOrderUUID, setPingedOrderUUID, readOnly } = props
  const {
    orderItems,
    orderState,
    orderIdentifier,
    orderNumber,
    statuses,
    notes,
    paymentsNeeded,
    takeAway,
    order
  } = useOrder(null, orderUUID)

  const { localUsers } = useDevice()
  const { getT } = useMultilang()
  const { socketAuthenticated } = useClient()

  const [showOrderController, setShowOrderController] = useState(false)
  const [showOrderItemController, setShowOrderItemController] = useState(false)
  const [showHideOrder, setShowHideOrder] = useState(false)
  const [showOrderItemUUID, setShowOrderItemUUID] = useState(null)
  const [orderHidden, setOrderHidden] = useState(false)
  const [timeEstimate, setTimeEstimate] = useState()
  const [loading, setLoading] = useState(false)

  moment.updateLocale('en', {
    relativeTime : {
        future: "%s később",
        past:   "%s ezelőtt",
        s  : 'másodpercekkel',
        ss : '%d másodperccel',
        m:  "egy perccel",
        mm: "%d perccel",
        h:  "egy órával",
        hh: "%d órával",
        d:  "egy nappal",
        dd: "%d nappal",
        w:  "egy héttel",
        ww: "%d héttel",
        M:  "egy hónappal",
        MM: "%d hónappal",
        y:  "egy évvel",
        yy: "%d évvel"
    }
  });

  const handleClickOnOrder = () => {
    if(loading) return null
    if(!socketAuthenticated) {
      toast.error(getT("openOrders.orderCard.offline"))
    } else {
      if(readOnly) {
        if(!showHideOrder) setShowHideOrder(true)
      } else {
        if(!showOrderController) setShowOrderController(true)
      }
    }
  }

  const handleClickOnOrderItem = (orderItemUUID) => {
    if(readOnly) return null
    if(!showOrderItemController) setShowOrderItemController(true)
    setShowOrderItemUUID(orderItemUUID)
  }

  const [flash, setFlash] = useState("")
  const timer1 = useRef(null)

  const sysUserName = useMemo(() => {
    let name = getT("openOrders.orderCard.noName")
    for(let localUser of localUsers) {
      if(localUser.userUUID === order?.order?.sysUserUUID) name = localUser.firstName
    }
    return name
  // eslint-disable-next-line
  }, [orderUUID, localUsers])

  useEffect(() => {
    if(flash !== "") {
      timer1.current = setTimeout(() => {
        setFlash("")
        if (pingedOrderUUID === orderUUID) {
          setPingedOrderUUID("")
          setOrderHidden(false)
        }
      }, 1050)
    }
    return () => clearTimeout(timer1.current)
    // eslint-disable-next-line
  }, [flash])

  useEffect(() => {
    if(pingedOrderUUID === orderUUID) setFlash("flash")
    // eslint-disable-next-line
  }, [pingedOrderUUID])

  useEffect(() => {
    for (let status of (statuses || [])) {
      if(status.statusID === OrderStatus.ORDER_TIME_ESTIMATE) {
        setTimeEstimate(
          moment(status.insertTimestamp)
          .add(status?.notes?.minutes, "minutes")
          .format("HH:mm")
        )
      }
    }
  }, [statuses])

  const hideOrder = () => {
    setOrderHidden(true)
    setShowHideOrder(false)
  }

  if(orderHidden) return null

  let itemsArray = Object.values(orderItems || {}).filter(item => item.count > 0)

  return (
    <div 
      name={orderUUID} 
      className={`order-card-root ${flash} ${readOnly ? "readOnly" : ""} ${orderState <= 10 ? "highlighted" : ""} ${orderState >= 60 ? "ready" : ""} ${paymentsNeeded > 0 ? "warning" : ""}`}
    >
      {loading && <div className="loading-container hidden quickAppear">{getT("openOrders.orderCard.loading")}</div>}
      <div className="order-card-header" onClick={handleClickOnOrder}>
        <div className="row space-between">
          <div className="order-card-order-takeAway">{takeAway ? getT("openOrders.orderCard.takeAway") : getT("openOrders.orderCard.eatIn")}</div>
          <div className="order-card-order-number">{orderNumber}</div>
        </div>
        <div className="row center">
          <div className={`order-state ${orderState > 10 ? "" : "font-bold"}`}>{getT(`openOrders.orderCard.orderState-${orderState}`)}</div>
        </div>
        <div className="row space-around">
          <div className="icon-container">
            <LoadSvg name="clock" />
            <div className="text">
              {moment(order?.order?.insertTimestamp).local().format("HH:mm")}
            </div>
          </div>
          <div className="icon-container">
            <LoadSvg name="users" />
            <div className="text">
              {orderIdentifier === "" ? sysUserName : orderIdentifier}
            </div>
          </div>
          <div className="icon-container">
            <LoadSvg name={consts.DEVICENAMES[order?.order?.insertDeviceType]} />
            <div className="text">
              {consts.DEVICENAMES[order?.order?.insertDeviceType]}
            </div>
          </div>
          <div className="icon-container">
            <LoadSvg name="status" />
            <div className="text">
              {getT(`openOrder.orderCard.${paymentsNeeded > 0 ? "paymentsNeeded" : "paid"}`)}
            </div>
          </div>
          <div className="icon-container">
            <LoadSvg name="exclamMark" />
            <div className="text">
              {timeEstimate != null ? timeEstimate : getT("openOrders.orderCard.timeEstimateNull")}
            </div>
          </div>
        </div>
      </div>
      <div className={`order-card-body ${orderState >= OrderStates.ORDER_DONE ? "ready" : ""}`}>
        {itemsArray.length < 1 && getT("openOrders.orderCard.noItems")}
        {itemsArray.length >= 1 && <div className="text">{getT("openOrders.orderCard.basketItems")}</div>}
        <div className="items-container">
          {itemsArray.map((item, index) =>
            <OrderCardItem
              key={index}
              orderUUID={orderUUID}
              item={item}
              orderState={orderState}
              onClick={() => handleClickOnOrderItem(item.orderItemUUID)}
            />
          )}
        </div>
        {notes != null && notes !== "" && <div className="order-notes">{notes}</div>}
      </div>
      <div className="order-card-footer" onClick={handleClickOnOrder}>
        <div className="order-card-insert-time">{moment(order?.order?.insertTimestamp).local().fromNow()}</div>
      </div>
      {showOrderController && (
        <OrderController
          orderUUID={orderUUID}
          onClose={() => setShowOrderController(false)}
          setLoading={setLoading}
        />
      )}
      {showOrderItemController && (
        <OrderItemController
          orderUUID={orderUUID}
          orderItemUUID={showOrderItemUUID}
          onClose={() => setShowOrderItemController(false)}
        />
      )}
      {showHideOrder && <>
        <div className="card-order-controller-background animation-appear-seventy" />
        <div className="card-order-controller animation-appear-full">
          <div className="btn" onClick={hideOrder}>{getT("openOrders.orderCard.hideOrder")}</div>
          <div className="btn" onClick={() => setShowHideOrder(false)}>{getT("openOrders.orderCard.close")}</div>
        </div>
      </>}
    </div>
  )
}
export default OrderCard