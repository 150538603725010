import { useContext } from "react"
import { MainContainerContext } from "contexts/MainContainerProvider"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import LoadSvg from "components/LoadSvg-v2"
import useMultilang from "intl/useMultilang"
import { isMobileOnly } from "react-device-detect"

function SearchField() {
    
    const { setSearchedText, searchedText } = useContext(MainContainerContext)
    const { swiper } = useContext(SelectedOrderContext)
    const { getT } = useMultilang()

    const handleChange = (e) => {
        setSearchedText(e.target.value)
    }

    const clear = () => setSearchedText("")

    const handleSearchFocus = () => {
        if(swiper != null && typeof swiper?.slideTo === "function") swiper.slideTo(1)
    }

    return(
        <div className="search-field-root">
            {!isMobileOnly && <div className="search-icon"><LoadSvg name="search" /></div>}
            <input type="text" value={searchedText} onChange={handleChange} placeholder={getT("searchField.placeholder")} onFocus={handleSearchFocus}/>
            {searchedText.length > 0 && <div className="hidden appear clear-icon" onClick={clear}><LoadSvg name="BinIcon" /></div>}
        </div>
    )
}
export default SearchField