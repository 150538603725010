import { useEffect, useContext, useState } from "react"
import Methods from "./Methods-v2"
//import Numpad from "./Numpad-v2"
import NumpadV3 from "./Numpad-v3"
import { PaymentProvider } from "contexts/PaymentContext"
import PaymentButtons from "views/Payment/PaymentButtons"
import { useMain, usePrinter } from 'okeoke.client'
import { isMobileOnly } from "react-device-detect"
import { ModalContext } from "contexts/ModalContext"
import PrinterErrorModal from "./PrinterErrorModal"
import LoadSvg from "components/LoadSvg-v2"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import useMultilang from "intl/useMultilang"
import Summary from "./Summary"
import PaymentOrderSummary from "./PaymentOrderSummary"
import InfoBox from "views/TopBar/InfoBox"

function Payment(props) {
  
  const { startOrderAfterPayment, swiper } = props
  
  const { generalError } = usePrinter()
  const { popModal, loadModal, closeAllModal } = useContext(ModalContext)
  const { takeAway, orderIdentifier } = useContext(SelectedOrderContext)
  const { getT } = useMultilang()

  const [paymentContainerOpen, setPaymentContainerOpen] = useState(isMobileOnly ? false : true)
  const [selectedPage, setSelectedPage] = useState("paymentTypes")

  const getData = (message) => {
    //console.log(message)
  }

  const { messageSecondary } = useMain(getData)

  useEffect(() => {
    messageSecondary({ action: "navigate", data: "/tipp-screens/type1" })
    messageSecondary({ action: "updateStatus", data: "payment" })
    if(generalError === "1") loadModal(<PrinterErrorModal popModal={popModal} closeAllModal={closeAllModal} />, popModal)
    // eslint-disable-next-line
  }, [])

  const backToSell = () => {
    if(typeof swiper?.slideTo === "function") swiper.slideTo(0)
    messageSecondary({ action: "updateStatus", data: "sell" })
    messageSecondary({ action: "tipChange", data: true })
    popModal()
  }

  if(isMobileOnly) return (
    <PaymentProvider >
      <div className="payment-container-root is-mobile">
        <div className="top-container" onClick={backToSell}>
          <div className={`back-to-sell-icon`} >
            <LoadSvg name={"ArrowLeftIcon"} />
          </div>
          <div className="order-identifier">{orderIdentifier === "" ? getT("sell.basket.order") : orderIdentifier}</div>
          <div className="order-take-away">{takeAway ? getT("sell.basket.takeAway") : getT("sell.basket.eatIn")}</div>
        </div>
        <div className="page-selector">
          <div className={`element payment-types ${selectedPage === "paymentTypes" ? "selected" : ""}`} onClick={() => setSelectedPage("paymentTypes")}>{getT("sell.basket.paymentTypes")}</div>
          <div className={`element payments ${selectedPage === "payments" ? "selected" : ""}`} onClick={() => setSelectedPage("payments")}>{getT("sell.basket.payments")}</div>
        </div>
        <div className={`middle-container ${selectedPage === "paymentTypes" ? "payment-types-on" : "payment-types-off"}`}>
          <NumpadV3 visible={selectedPage === "paymentTypes"} />
          <Methods hidden={selectedPage !== "payments"} />
        </div>
        {selectedPage === "paymentTypes" && <div className={`slide-up-container ${paymentContainerOpen ? "open" : "closed"}`}>
          <div className="toggle" onClick={() => setPaymentContainerOpen(!paymentContainerOpen)}><LoadSvg name="arrowUp"/></div>
          <PaymentButtons startOrderAfterPayment={startOrderAfterPayment} />
        </div>}
        <Summary />
      </div>
    </PaymentProvider>
  )


  return (
    <PaymentProvider >
      <div className="header-root">
        <InfoBox />
      </div>
      <div className="payment-container-root">
        <div className="left-container">
          <NumpadV3 visible={true} />
          <div className={`slide-up-container ${paymentContainerOpen ? "open" : "closed"}`}>
            <div className="toggle" onClick={() => setPaymentContainerOpen(!paymentContainerOpen)}><LoadSvg name="arrowUp"/></div>
            <PaymentButtons startOrderAfterPayment={startOrderAfterPayment} />
          </div>
        </div>
        <div className="middle-container">
          <Methods />
        </div>
        <div className="right-container">
          <PaymentOrderSummary />
        </div>
      </div>
    </PaymentProvider>
  )
}

export default Payment
