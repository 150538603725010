import { useState, useEffect } from "react"
import InputFieldSelect from "components/InputFieldSelect"
import TextField from 'components/TextField'
import useMultilang from 'intl/useMultilang'
import { toast } from 'react-toastify'
import merge from 'lodash.merge'

function PrinterModal(props) {

    const { params, onSave, popModal } = props
    const { getT } = useMultilang()
    const [localParams, setLocalParams] = useState(null)

    const defaultValues = {
        "port": "",
        "type": "",
        "ipAddress": "",
        "vat": {
            "products": {
              "5.00": "1",
              "18.00": "2",
              "27.00": "3"
            },
            "serviceFee": {
              "5.00": "4",
              "18.00": "5",
              "27.00": "6"
            },
            "tipp": "0"
        }
    }

    useEffect(() => {
        let newValues = merge(defaultValues, params)
        console.log(newValues)
        setLocalParams(newValues)
        // eslint-disable-next-line
    }, [params]) 


    const validate = () => {

        let validation = true

        if(localParams?.type === "Fiscat") {
            const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
            if(!ipRegex.test(localParams?.ipAddress)) {
                toast.error("Invalid ip")
                validation = false
            }
        }

        return validation
    }

    const changeFieldData = (e) => {
        setLocalParams({...localParams, [e.target.name]: e.target.value})
    }

    const changeVatServiceFeeFieldData = (e) => {
        setLocalParams({
            ...localParams, 
            vat: {
                ...localParams.vat, serviceFee: {
                    ...localParams.vat.serviceFee, [e.target.name]: e.target.value
                }
            }
        })
    }

    const changeVatProductsFieldData = (e) => {
        setLocalParams({
            ...localParams, 
            vat: {
                ...localParams.vat, products: {
                    ...localParams.vat.products, [e.target.name]: e.target.value
                }
            }
        })
    }

    const changeVatTippFieldData = (e) => {
        setLocalParams({
            ...localParams, 
            vat: {
                ...localParams.vat, tipp: e.target.value
            }
        })
    }

    const handleSave = () => {
        let valid = validate()
        if(valid) onSave(localParams)
    }

    return(
        <div className="printer-modal scrollable">
            <div className="modal-title">{getT('devices.printer.modalTitle')}</div>
            <div className="modal-body">
                <InputFieldSelect 
                    label={getT("devices.printerType")}
                    name="type"
                    onChange={changeFieldData}
                    value={localParams?.type}
                    displayedDefaultName={getT("devices.selectPrinterType")}
                    options={[{ title: "No printer", value: ""}, { title: "Fiscat", value: "Fiscat"}, { title: "FiscatIPalm", value: "FiscatIPalm"}]}
                    fullWidth
                />
                <TextField 
                    label={getT("devices.ipAddress")}
                    name="ipAddress"
                    onChange={changeFieldData}
                    value={localParams?.ipAddress || ""}
                    type="text"
                    fullWidth
                    disabled={localParams?.type !== "Fiscat"}
                />
                <TextField 
                    label={getT("devices.port")}
                    name="port"
                    onChange={changeFieldData}
                    value={localParams?.port || ""}
                    type="text"
                    fullWidth
                    disabled={localParams?.type !== "Fiscat"}
                />
                <div className="box">
                    <div className="title">{getT("devices.vat")}</div>
                    <div className="row">
                        <div className="col">
                            <div className="sub-title">{getT("devices.vat.products")}</div>
                            <div className="mt-1">
                                <TextField 
                                    label={"5.00%"}
                                    name="5.00"
                                    onChange={changeVatProductsFieldData}
                                    value={localParams?.vat?.products?.['5.00']}
                                    type="text"
                                    disabled={localParams?.type === ""}
                                />
                            </div>
                            <div className="mt-1">
                                <TextField 
                                    label={"18.00%"}
                                    name="18.00"
                                    onChange={changeVatProductsFieldData}
                                    value={localParams?.vat?.products?.['18.00']}
                                    type="text"
                                    disabled={localParams?.type === ""}
                                />
                            </div>
                            <div className="mt-1">
                                <TextField 
                                    label={"27.00%"}
                                    name="27.00"
                                    onChange={changeVatProductsFieldData}
                                    value={localParams?.vat?.products?.['27.00']}
                                    type="text"
                                    disabled={localParams?.type === ""}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="sub-title">{getT("devices.vat.serviceFee")}</div>
                            <div className="mt-1">
                                <TextField 
                                    label={"5.00%"}
                                    name="5.00"
                                    onChange={changeVatServiceFeeFieldData}
                                    value={localParams?.vat?.serviceFee?.['5.00']}
                                    type="text"
                                    disabled={localParams?.type === ""}
                                />
                            </div>
                            <div className="mt-1">
                                <TextField 
                                    label={"18.00%"}
                                    name="18.00"
                                    onChange={changeVatServiceFeeFieldData}
                                    value={localParams?.vat?.serviceFee?.['18.00']}
                                    type="text"
                                    disabled={localParams?.type === ""}
                                />
                            </div>
                            <div className="mt-1">
                                <TextField 
                                    label={"27.00%"}
                                    name="27.00"
                                    onChange={changeVatServiceFeeFieldData}
                                    value={localParams?.vat?.serviceFee?.['27.00']}
                                    type="text"
                                    disabled={localParams?.type === ""}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-1">
                        <TextField 
                            label={getT("devices.vat.tipp")}
                            name="tipp"
                            onChange={changeVatTippFieldData}
                            value={localParams?.vat?.tipp || ""}
                            type="text"
                            disabled={localParams?.type === ""}
                        />
                    </div>
                </div>
            </div>
            <div className="buttons-container">
                <div className="btn btn-cancel" onClick={popModal}>{getT("devices.printer.cancel")}</div>
                <div className="btn btn-save" onClick={handleSave}>{getT("devices.printer.save")}</div>
            </div>
        </div>
    )
}
export default PrinterModal