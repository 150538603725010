import { useEffect, useState } from "react"
import TextField from "components/TextField"
import useMultilang from "intl/useMultilang"
import {toast}  from "react-toastify"
import InputFieldSelect from "components/InputFieldSelect"
import useSizesApi from "apis/catalog/useSizesApi"

function SizeCreateModal(props) {

  const { itemUUID, popModal, onCreate, ntakCategories, defaultNtakCategoryID } = props

  const { getT, language } = useMultilang()
  const { setSize: saveSize } = useSizesApi()
  const [size, setSize] = useState({name: { en: "", hu: "" }, price: 0, ntakCategoryID: 0})
  const [selectedNtakCategory, setSelectedNtakCategory] = useState("")
  const [valid, setValid] = useState(false)

  useEffect(() => {
    if(defaultNtakCategoryID != null) {
      setSize({...size, ntakCategoryID: defaultNtakCategoryID})
      let ntakCategoryName = getCategNameBySubCategID(defaultNtakCategoryID)
      setSelectedNtakCategory(ntakCategoryName)
    }
    // eslint-disable-next-line
  }, [defaultNtakCategoryID])

  const getCategNameBySubCategID = (subCategID) => {
    let categName = ""
    for(let categ of Object.values(ntakCategories)) {
      if(categ.subCategories?.[subCategID] != null) categName = categ.code
    }
    return categName
  }

  const handleCreateSize = () => {

    let dataValid = validate()
  
    if(!dataValid) return null

    let values = {
      itemUUID: itemUUID,
      name: size.name,
      price: size.price,
      ntakCategoryID: size.ntakCategoryID
    }

    saveSize(values).then(res => {
      if(res.success) {
        //toast.success(res.message)
        onCreate()
        popModal()
      } else {
        toast.error(res.message)
      }
    })
  }

  const validate = (data) => {
    let sizeData = data || size
    
    let response = true
    
    if(sizeData == null) response = false
    if(sizeData?.name?.[language] === "") response = false
    if(sizeData?.price === 0) response = false
    if(sizeData?.ntakCategoryID === 0) response = false
    
    setValid(response)
    return response
  }

  const handleChange = e => {
    let newValues = { ...size, [e.target.name]: e.target.value }
    setSize(newValues)
    validate(newValues)
  }

  const handleChangeName = e => {
    let newValues = { ...size, name: { en: e.target.value, hu: e.target.value }}
    setSize(newValues)
    validate(newValues)
  }

  const handleNtakCateg = (e) => {
    if(e.target.name === "ntakCategory") {
      let newValues = { ...size, ntakCategoryID: 0 }
      setSize(newValues)
      setSelectedNtakCategory(e.target.value)
    }
    if(e.target.name === "ntakCategoryID") {
      let newValues = { ...size, ntakCategoryID: e.target.value }
      setSize(newValues)
      validate(newValues)
    }
  }

  return (
    <div className="size-modal-root">
      <div className="size-modal-title">{getT("createSize.modal.CreateNewSize")}</div>
      <div className="size-modal-textfield">
        <div>{getT("createSize.modal.sizeName")}</div>
        <TextField
          name="name"
          type="text"
          onChange={handleChangeName}
          placeholder={getT("createSize.modal.size")}
          value={size.name?.[language]}
        />
      </div>
      <div className="size-modal-textfield">
        <div>{getT("createSize.modal.sizePrice")}</div>
        <TextField
          name="price"
          type="number"
          onChange={handleChange}
          placeholder={500}
          value={size.price}
        />
      </div>
      <div className="ntak-select">
        <InputFieldSelect
          className={"create-new-item-root-input-field-select"}
          options={Object.values(ntakCategories || {}).map(categ => {
              return { title: categ.name?.[language], value: categ.code }
          })}
          onChange={handleNtakCateg}
          name="ntakCategory"
          value={selectedNtakCategory}
          displayedDefaultName={getT("createItem.modal.selectNtakMainCategory")}
          label={getT("createItem.modal.ntakCategory")}
        />
        <InputFieldSelect
          className={"create-new-item-root-input-field-select"}
          options={Object.values(ntakCategories?.[selectedNtakCategory]?.subCategories || {}).map(categ => {
              return { title: categ.name?.[language], value: categ.id }
          })}
          onChange={handleNtakCateg}
          name="ntakCategoryID"
          value={size.ntakCategoryID}
          displayedDefaultName={getT("createItem.modal.selectNtakSubCategory")}
          disabled={selectedNtakCategory === ""}
          label={getT("createItem.modal.ntakSubCategory")}
        />
      </div>
      <div className="button-container">
        <div className="btn btn-cancel"  onClick={popModal}>{getT("createSize.modal.cancel")}</div>
        <div className={`btn ${valid ? "btn-save" : "inactive"}`} onClick={handleCreateSize}>{getT("createSize.modal.save")}</div>
      </div>
    </div>
  )
}
export default SizeCreateModal
