import useMultilang from "intl/useMultilang"

function OpenDayHelpWindow() {

    const { getT } = useMultilang()

    return(
        <div className="open-day-help-window border-glow-effect">
            <div className="title">{getT("tutorial.openDay.title")}</div>
            <div className="message">{getT("tutorial.openDay.message")}</div>
        </div>
    )
}
export default OpenDayHelpWindow