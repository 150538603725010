import { useContext, useEffect, useState } from "react"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import SizeCreateModal from "views/Editor/EditItem/SizeCreateModal"
import { ModalContext } from "contexts/ModalContext"
import { MainContext } from "contexts/MainContext"
import useMultilang from "intl/useMultilang"
import Size from "./Size"
import useNtakApi from "apis/useNtakApi"
import useItemsApi from "apis/catalog/useItemsApi"
import useAvailabilityApi from "apis/catalog/useAvailabilityApi"
import { toast } from "react-toastify"

function Sizes(props) {

  const { itemUUID } = props
  const { addModal, popModal } = useContext(ModalContext)
  const { featureList } = useContext(MainContext)
  const { getT } = useMultilang()
  const { getNtakCategories } = useNtakApi()
  const { getItem } = useItemsApi()
  const { getLocationAvailability, setAvailability } = useAvailabilityApi()
  
  const [ntakCategories, setNtakCategories] = useState(null)
  const [item, setItem] = useState(null)
  const [sizeAvailability, setSizeAvailability] = useState()

  useEffect(() => {
    getNtakCategories().then(res => {
      if(res.success) {
      let obj = {}
      for(let categ of Object.values(res.data || {})) {
          obj[categ.code] = categ
      }
      setNtakCategories(obj)
      } else {
        console.log(res)
      }
    })
    loadAvailability()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(itemUUID != null) loadItem()
    // eslint-disable-next-line
  }, [itemUUID])

  const loadAvailability = () => {
    getLocationAvailability("size").then(res => {
      if(res.success) {
        setSizeAvailability(res.data)
      } else {
        console.log(res)
      }
    })
  }

  const loadItem = () => {
    getItem(itemUUID, { getItemSizes: true }).then(res => {
      if(res.success) {
        setItem(res.data)
      } else {
        console.log(res)
      }
    })
  }

  const setDefaultSize = (defaultSize) => {
    let newValues = { ...item, defaultSize }
    setItem(newValues)
    //handleUpdate(newValues)
  }

  const handleAddSizeToItem = () => {
    addModal(
      <Modal
        key={uuidv4()}
        className="pos-modal-base-skin"
        onClickLayout={popModal}
      >
        <SizeCreateModal 
          itemUUID={item.uuid} 
          popModal={popModal} 
          onCreate={loadItem} 
          ntakCategories={ntakCategories}
          defaultNtakCategoryID={item?.sizes[0]?.ntakCategoryID}
        />
      </Modal>
    )
  }

  const handleAvailabilityChange = (availability) => {
    if(availability.itemUUID !== item.defaultSize) {
      setAvailability(availability).then(res => {
        if(res.success) {
          loadAvailability()
        }
      })
    } else {
      toast.warning(getT("editItem.view.sizes.cantTurnOutDefaultSize"))
    }
  }

  return (
    <div className="sizes-editor-root">
      <div className="title">
        {getT("editItem.view.sizes")}
      </div>
      <div className="body">
        <div className="sizes-container">
          {(item?.sizes ||[]).map((size, index) => 
            <Size 
              key={index} 
              size={size} 
              ntakCategories={ntakCategories} 
              defaultSizeUUID={item.defaultSize} 
              setDefaultSize={setDefaultSize}
              sizeAvailability={sizeAvailability?.[size.uuid]}
              setAvailability={handleAvailabilityChange}
            />)}
        </div>
      </div>
      <div className="footer">
        <div className="btn btn-cancel" onClick={popModal}>{getT("editItem.view.sizes.close")}</div>
        {featureList.itemModifiersAndSizes === 1 && <div className="btn btn-save" onClick={handleAddSizeToItem}>+ {getT("editItem.view.sizes.CreateNewSize")}</div>}
      </div>
    </div>
  )
}
export default Sizes