import { useState, useEffect } from 'react'
import { useFiscatPrinter, useDevice } from "okeoke.client"
import Modal from "../Modal"

function OpenDay(props) {

  const { dayOpened } = props
  const { deviceParams } = useDevice()
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [cashAmount, setCashAmount] = useState('0')
  const { fiscatOpenDay } = useFiscatPrinter(deviceParams?.printer)
  const [ message, setMessage ] = useState('')
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    setDisabled(dayOpened)
  }, [dayOpened])

  const handleSubmit = () => {
    if(!disabled) {
      setDisabled(true)
      fiscatOpenDay(cashAmount).then(res => {
        setMessage(!res.success ? `Hiba történt! (${(res?.data?.message)})` : 'OK!' )
        setDisabled(false)
        if(res.success) handleClose()
      })
    }
  }

  useEffect(() => {
    setMessage('')
  }, [open])

  return (
    <>
      <Modal open={open} >
        <div className="day-open-modal-content">
          <h1>Napnyitás</h1>
          <div className="input-root">
            <input type='number' value={cashAmount} onChange={(e) => setCashAmount(e.target.value)}/><div className="currency">Ft</div>
          </div>
          <div className="actions">
            <div className="btn" onClick={handleClose}>Mégsem</div>
            <div className={`btn ${disabled ? "disabled" : "primary btn-highlighted"}`} onClick={handleSubmit}>Rendben</div>
          </div>
          <div className="message">
            {message}
          </div>
        </div>
      </Modal>
      <div className={`btn ${disabled ? "disabled" : "primary btn-highlighted"}`} onClick={handleOpen}>Napnyitás</div>
    </>
  )
}
export default OpenDay