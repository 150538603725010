import { useContext, useState } from "react"
import TextField from "components/TextField"
import { ModalContext } from "contexts/ModalContext"
import useMultilang from "intl/useMultilang"
import {toast}  from "react-toastify"
import { useNavigate } from "react-router-dom"
import { MainContext } from "contexts/MainContext"
import useCategoriesApi from "apis/catalog/useCategoriesApi"

function CreateNewItem(props) {
  
  const { parentUUID, navigateToEditor } = props
  
  const { popModal } = useContext(ModalContext)
  const { handleSelectCategory } = useContext(MainContext)
  const { getT } = useMultilang()
  const { setCategory } = useCategoriesApi()
  const navigate = useNavigate()

  const [name, setName] = useState("")

  const handleClose = () => popModal()
  
  const handleCreate = () => {

    let category = {
      name: { hu: name, en: name },
      parentUUID: parentUUID || ""
    }

    setCategory(category).then(res => {
      if(res.success){
        handleSelectCategory(res.data.uuid)
        if(navigateToEditor) {
          navigate("/editor")
        }
      } else{
        toast.error(res.message)
      }
      popModal()
    })
  }

  return (
    <div className="create-new-category-root">
      <div className="create-new-category-title">
        {getT("createCategory.modal.addCategory")}
      </div>
      <div className="create-new-category-container">
        <div className="new-category-single-data">
          <div className="data-name">{getT("createCategory.modal.categoryName")}</div>
          <TextField
            type="text"
            autoComplete="off"
            variant="outlined"
            onChange={e => setName(e.target.value)}
            value={name}
            placeholder={getT("createCategory.modal.newCategory")}
          />
          </div>
        </div>
        <div className="button-container">
          <div className="btn btn-cancel" onClick={handleClose}>
            {getT("createCategory.modal.cancel")}
          </div>
          <div className={`btn btn-save ${name === "" ? "inactive" : ""}`} onClick={handleCreate}>
            {getT("createCategory.modal.save")}
          </div>
      </div>
    </div>
  )
}
export default CreateNewItem