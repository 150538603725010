import { useContext } from "react"
import config from "config"
import Moment from "moment"
import { useClient, useLocation, useDevice, useOkeFrame } from "okeoke.client"
import useMultilang from "intl/useMultilang"
import { ModalContext } from "contexts/ModalContext"
import LoadSvg from "components/LoadSvg-v2"

function DeviceDialog() {

  const { getT } = useMultilang()
  const { popModal } = useContext(ModalContext)
  const { location } = useLocation()
  const { deviceName, logoutUser, loggedInUser } = useDevice()
  const { client } = useClient()
  const { sendMessageToWebView } = useOkeFrame()

  const handleClose = () => {
    popModal()
  }

  const handleLogOut = () => {
    logoutUser()
    popModal()
  }

  const exitApp = () => {
    popModal()
    sendMessageToWebView({ action: 'exitApp' })
  }

  const restartApp = () => {
    popModal()
    sendMessageToWebView({ action: 'restartApp' }).then(res => console.log(res))
  }

  return (
    <div className="device-container-root">
      <div className="dialog-title">
        {getT("deviceDialog.modal.deviceInformation")}
      </div>
      <div className="dialog-content">
        <div className="device-info-row">
          <div className="device-info-label">
            {getT("deviceDialog.modal.version")}:
          </div>
          <div className="device-info-data">{config.version}</div>
        </div>
        <div className="device-info-row">
          <div className="device-info-label">
            {getT("deviceDialog.modal.lactionName")}:
          </div>
          <div className="device-info-data">{location?.name}</div>
        </div>
        <div className="device-info-row">
          <div className="device-info-label">
            {getT("deviceDialog.modal.deviceName")}:
          </div>
          <div className="device-info-data">
            {/* {getT("deviceDialog.modal.cashRegister")} 1 */}
            {deviceName}
          </div>
        </div>
        <div className="device-info-row">
          <div className="device-info-label">
            {getT("deviceDialog.modal.lastSync")}:
          </div>
          <div className="device-info-data">
            {Moment(client?.catalog?.timestamp).format("YYYY.MM.DD hh:mm")}
          </div>
        </div>
        <div className="device-info-row">
          <div className="device-info-label">
            {getT("deviceDialog.modal.userName")}:
          </div>
          <div className="device-info-data">
          {loggedInUser?.firstName || "Not available"}            
          </div>
        </div>
      </div>
      <div className="device-info-reg-btn-container">
        <div className="btn" onClick={restartApp}>
          <LoadSvg name="restart" />
          {getT("deviceDialog.modal.restartApp")}
        </div>
        <div className="btn" onClick={exitApp}>
          <LoadSvg name="exit" />
          {getT("deviceDialog.modal.exitApp")}
        </div>
        <div className="btn" onClick={handleLogOut}>
          {getT("deviceDialog.modal.logOut")}
        </div>
        <div className="btn btn-highlighted" onClick={handleClose}>
          {getT("deviceDialog.modal.close")}
        </div>
      </div>
    </div>
  )
}

export default DeviceDialog
