import { useOkeFrame } from "okeoke.client"
import LoadSvg from "components/LoadSvg-v2"

function Welcome(props) {

    const { activeScreen, setActiveScreen, requestCode, activateDemo } = props

    const { sendMessageToWebView } = useOkeFrame()

    const exitApp = () => {
        sendMessageToWebView({ action: 'exitApp' })
    }

    if(activeScreen !== "welcome") return null

    return(
        <div className="hidden appear device-login-welcome">
            <div className="logo">
                <img src="https://cdn.okeoke.io/global/barsoft/Barsoft%20logo%20logo+text.svg" alt="logo"/>
            </div>
            <div className="welcome-message">
                Üdvözlünk a BarSoft rendszerében!
            </div>
            <div className="welcome-message2">
                Rendelkezel fiókkal?
            </div>
            <div className="btn-container">
                <div className="btn highlighted" onClick={() => setActiveScreen("loginUser")}>Igen, belépek</div>
                <div className="btn highlighted" onClick={() => setActiveScreen("activate")}>{`Igen, távolról aktiválom ${(requestCode != null && requestCode !== "") ? "("+requestCode+")" : ""}`}</div>
                <div className="btn" onClick={() => setActiveScreen("reg-step1")}>Még nincs fiókom, regisztrálok</div>
                <div className="btn" onClick={activateDemo}>Kipróbálom demo módban</div>
            </div>
            <div className="btn exit-btn" onClick={exitApp}><LoadSvg name="exit"/></div>
        </div>
    )
}
export default Welcome