import useMultilang from "intl/useMultilang"

function TutorialEndedModal(props) {

    const { popModal } = props

    const { getT } = useMultilang()

    return(
        <>
        <div className="title">{getT("tutorial.endedModal.title")}</div>
        <div className="body">{getT("tutorial.endedModal.message")}</div>
        <div className="actions">
            <div className="btn btn-highlighted" onClick={popModal}>{getT("tutorial.endedModalBtn")}</div>    
        </div>
        </>
    )
}
export default TutorialEndedModal