import {useContext, useState} from 'react'
import { DataTableContext} from './DataTableContext'
import LoadSvg from "components/LoadSvg-v2"
import InputSwitch from 'components/InputSwitch'

function Header (props) {
    const {sortOrder, refOfHeader, orderingData} = props
    const {data, setData, columns, checkbox, dndHandle } = useContext(DataTableContext);
    const [allSelected, setAllSelected] = useState(false)

    const selectAllRow = (value) => {
      setAllSelected(value)
      let array = data.map(row => { return {...row, _checked: value} })
      setData(array)
    };

    return (
        <thead ref={refOfHeader} className={`data-header`}>
          <tr className={`data-row`}>
            {dndHandle && <th className={`data-cell dnd`}></th>}
            {checkbox && <th className={`data-cell checkbox-cell head-align-right`}>
              <InputSwitch
                onChange={() => selectAllRow(!allSelected)}
                checked={allSelected === true}
                isCheckbox
              />
            </th>}
            {columns.map((column, index) => {
              return (
                <th
                  key={index}
                  className={`data-cell ${column.name} ${
                    column.options?.className != null
                      ? column.options?.className
                      : ""
                  } ${
                    column.options?.sort != null && column.options?.sort
                      ? "sortable"
                      : ""
                  }`}
                  onClick={() => {
                    orderingData(index);
                  }}
                >
                  {column.label}
                  {column.options?.sort != null && column.options?.sort && (
                    <span
                      className={`sort-icon ${
                        sortOrder[0] === index ? "active" : ""
                      }`}
                    >
                      {sortOrder[0] !== index && <LoadSvg name="sortIconNone" />}
                      {sortOrder[0] === index && !sortOrder[1] && (
                        <LoadSvg name="sortIconUp" />
                      )}
                      {sortOrder[0] === index && sortOrder[1] && (
                        <LoadSvg name="sortIconDown" />
                      )}
                    </span>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
      );
}

export default Header