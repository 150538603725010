function SizeButton(props) {

  const { label, size, selectedSizeUUID, setSelected } = props

  return (
    <div className={`radio-button-root ${selectedSizeUUID === size.uuid ? "selected" : ""}`}  onClick={() => setSelected(size.uuid) }>
      <div className="inner-bg"></div>
      <div className="radio-button-label">{label}</div>
      <div className="radio-button-price">{`${size.price || 0} Ft`}</div>
    </div>
  )
}
export default SizeButton