import { useEffect, useState } from "react"
import "swiper/swiper.scss"
import "./style.scss"
import "typeface-roboto"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorFallback } from "utils/ErrorHandler"
import Router from "utils/Router"
import { MainContainerProvider } from "contexts/MainContainerProvider"
import { ModalProvider } from "contexts/ModalContext"
import { useClient, useMain, useDevice, useOkeFrame } from "okeoke.client"
import "react-toastify/dist/ReactToastify.css"
import { InputHandlerProvider } from "contexts/InputHandlerProvider"
//import { ClearanceHandlerProvider } from "contexts/ClearanceHandlerContext"
import { isMobileOnly, isTablet } from "react-device-detect"
import { MainContextProvider } from "contexts/MainContext"
import { SelectedOrderContextProvider } from "contexts/SelectedOrderContext"
import StartPrinterStateRequest from "utils/StartPrinterStateRequest"
import { setParams } from "utils/Params"
import config from "config"
import { TutorialContextProvider } from "contexts/TutorialContext"

function App() {

  const { catalogUpdated, refreshCatalog, brandID, demoMode, sandboxMode, setSandboxMode } = useClient(null)
  const { deviceParams } = useDevice()
  const { sendMessageToWebView } = useOkeFrame()

  const [newUpdateFound, setNewUpdateFound] = useState(false)

  const getData = (message) => {
    if(message.action === "getBrandID") {
      messageSecondary({action: "setBrand", data: brandID})
    }
  }
  
  const { messageSecondary } = useMain(getData)

  useEffect(() => {
    window.addEventListener("contextmenu", (e) => e.preventDefault())
    setParams()
    const broadcast = new BroadcastChannel('update-channel')
    broadcast.onmessage = (event) => {
      if(event.data.payload === "UPDATE") setNewUpdateFound(true)
    }
  }, [])

  useEffect(() => {
    if(catalogUpdated) refreshCatalog()
    // eslint-disable-next-line
  }, [catalogUpdated])

  const restartApp = () => {
    sendMessageToWebView({ action: 'restartApp' }).then(res => console.log(res))
  }

  const reset = () => {
    localStorage.clear()
    window.location.reload()
  }

  const handleClickVersion = () => {
    localStorage['firstStartTutorialOn'] == null ? localStorage['firstStartTutorialOn'] = 1 : localStorage.removeItem("firstStartTutorialOn")
  }

  const turnOffSandboxMode = () => {
    if(!deviceParams?.sandbox) setSandboxMode(false)
  }

  //return <img src="https://img.joomcdn.net/a30585d5deefb6905f2740fbf8449bca86e9b3b9_original.jpeg" />

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
          <div className={`demo-mode-bar ${demoMode ? "visible" : "not-visible"}`} onClick={reset}>Az applikáció demo módban fut, kilépéshez kattints a sávra</div>
          <div className={`sandbox-mode-bar ${(!demoMode && sandboxMode) ? "visible" : "not-visible"}`} onClick={turnOffSandboxMode} >{`Az applikáció sandbox módban fut ${!deviceParams?.sandbox ? ", kilépéshez kattints a sávra" : ""}`}</div>
          <div className={`hidden appear main-container ${isMobileOnly ? "is-mobile" : isTablet ? "is-tablet" : ""} ${demoMode ? "demo-mode" : ""} ${sandboxMode ? "sandbox-mode" : ""}`}>
            {deviceParams?.printer?.type === "Fiscat" && <StartPrinterStateRequest />}
            <InputHandlerProvider>
              <SelectedOrderContextProvider>
                <MainContextProvider>
                  <ModalProvider>
                    <TutorialContextProvider>
                      <MainContainerProvider>
                        <Router />
                      </MainContainerProvider>
                    </TutorialContextProvider>
                  </ModalProvider>
                </MainContextProvider>
              </SelectedOrderContextProvider>
            </InputHandlerProvider>
            <div className="pos-version" onClick={handleClickVersion}>{`v${config?.version || ""}`}</div>
            {newUpdateFound && <div className="update-container hidden appear" onClick={restartApp}>Új verzió érhető el, frissítéshez zárd be az appot és indítsd újra!</div>}
          </div>
      </ErrorBoundary>
    </>
  )
}
export default App