import { useState, useEffect } from "react"
import moment from "moment"
import DataTable from "components/DataTable"
import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"
import ReportsMenu from "./ReportsMenu"
import ReportsHead from "./ReportsHead"
import useStatsApi from "apis/useStatsApi"

function Discounts() {

    const { getOrdersByDiscount } = useStatsApi()
    const { getT, getName } = useMultilang()

    const [list, setList] = useState(null)
    const [sumAmount, setSumAmount] = useState(null)
    const [sumCount, setSumCount] = useState(null)
    const [filter, setFilter] = useState({from: `${moment().format("YYYY-MM-DD")} 00:00:00`, to: `${moment().format("YYYY-MM-DD")} 23:59:59`})

    useEffect(() => {
        loadDiscounts()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        loadDiscounts()
        // eslint-disable-next-line
    }, [filter])

    const loadDiscounts = () => {

        let filters = { fromDate: filter.from, toDate: filter.to }

        getOrdersByDiscount(filters).then(res => {
            if(res.success) {
                setList(res.data)
                let sum1 = 0
                let sum2 = 0
                for(let item of res.data) {
                    sum1 = sum1 + parseInt(item.useCount)
                    sum2 = sum2 + parseInt(item.sumAmount)
                }
                setSumCount(sum1)
                setSumAmount(sum2)
            } else {
                console.log(res)
            }
        })
    }

    const columns = [
        {
            name: "type",
            label: getT("reports.discounts.type"),
            options: {
                className: "head-align-left cell-align-left",
                sort: false,
                format: row => {
                    switch(row.type) {
                        case 1: return getT("reports.discounts.vipPoints")
                        case 2: return getT("reports.discounts.coupons")
                        case 3: return getName(row.name)
                        default: return getT("reports.discounts.discount")
                    }
                },
                type: false,
            },
        },
        {
            name: "sumAmount",
            label: getT("reports.discounts.sumAmount"),
            options: {
                className: "head-align-right cell-align-right",
                sort: false,
                format: row =>
                <NumberFormat
                    value={parseInt(row.sumAmount)}
                    decimalSeparator=","
                    suffix=" Ft"
                    thousandSeparator="."
                    displayType="text"
                />,
                type: false,
            },
        },
        {
            name: "useCount",
            label: getT("reports.discounts.useCount"),
            options: {
                className: "head-align-right cell-align-right",
                sort: false,
                format: row => 
                <NumberFormat
                    value={parseInt(row.useCount)}
                    decimalSeparator=","
                    suffix=""
                    thousandSeparator="."
                    displayType="text"
                />,
                type: false,
            },
        }
    ]

    const handleDateChange = (values) => {
        setFilter(values)
    }

    return(
        <div className="reports-container discounts-container">
            <ReportsHead title={getT("reports.discounts.title")} handleDateChange={handleDateChange} filter={filter}/>
            <div className="body">
                <div className="box-container">
                    <div className="box">
                    <div className="label">{getT("reports.discounts.sumAmount")}</div>
                    <div className="value">
                        <NumberFormat
                        value={parseInt(sumAmount)}
                        decimalSeparator=","
                        suffix=" Ft"
                        thousandSeparator="."
                        displayType="text"
                        />
                    </div>
                    </div>
                    <div className="box">
                    <div className="label">{getT("reports.discounts.sumCount")}</div>
                    <div className="value">{sumCount}</div>
                    </div>
                </div>
                <div className="table-container">
                    <DataTable data={list} columns={columns} />
                </div>
            </div>
            <div className="footer">
                <ReportsMenu />
            </div>
      </div>
    )
}
export default Discounts