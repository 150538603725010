import { useIPanelApi, useClient } from "okeoke.client"

export default function useCategoriesApi() {

  const { post, get } = useIPanelApi()
  const { brandID } = useClient()

  const getCategories = async (filter, options) => {
    try {
      const response = await get(`brand/catalog/categories/all?brandID=${brandID}&filter=${JSON.stringify(filter || {})}&options=${JSON.stringify(options || {})}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const getCategory = async (categoryUUID, options) => {
    try {
      const response = await get(`brand/catalog/categories/${categoryUUID}?brandID=${brandID}&options=${JSON.stringify(options || {})}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const getCategoryItems = async (categoryUUID) => {
    try {
      const response = await get(`brand/catalog/categories/items/${categoryUUID}?brandID=${brandID}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setCategory = async (category) => {
    let body = {
      brandID,
      category
    }
    try {
      const response = await post(`brand/catalog/categories`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setCategoriesOrder = async (categories) => {
    let body = {
      brandID,
      categories
    }
    try {
      const response = await post(`brand/catalog/categories/order`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setCategoryItemsOrder = async (categoryUUID, items) => {
    let body = {
      brandID,
      categoryUUID,
      items
    }
    try {
      const response = await post(`brand/catalog/categories/items/order`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  return {
    getCategories,
    getCategory,
    getCategoryItems,
    setCategory,
    setCategoriesOrder,
    setCategoryItemsOrder
  }
}