import { useContext, useState } from "react"
import { useDevice, usePosPrinter } from "okeoke.client"
import useMultilang from "intl/useMultilang"
import { ModalContext } from "contexts/ModalContext"
import { MainContext } from "contexts/MainContext"
import PosPrinterModal from "./PosPrinterModal"
import PrinterModal from "./PrinterModal"
import { toast } from "react-toastify"
import { PersonalSettingsContext } from "contexts/PersonalSettingsProvider"
import useDevicesApi from "apis/useDevicesApi"
import usePrintersApi from "apis/usePrintersApi"

function CommonPrinterSettings() {
  
  const { deviceName, deviceParams, deviceUUID } = useDevice()
  const { loadModal, popModal } = useContext(ModalContext)
  const { setReloadNeeded, featureList, reloadNeeded } = useContext(MainContext)
  const { localAppParams, changeLocalAppParam } = useContext(PersonalSettingsContext)
  const { setDevice } = useDevicesApi()
  const { printers } = usePosPrinter()
  const { setPrinter } = usePrintersApi()

  const [posPrinter, setPosPrinter] = useState(printers[deviceParams?.posPrinter?.uuid])
  const [fiscalPrinter, setFiscalPrinter] = useState(deviceParams?.printer)

  const { getT } = useMultilang()

  const onChangePosPrinter = (printerUUID) => {
    const device = {
      uuid: deviceUUID,
      deviceParams: {
        posPrinter: { uuid: printerUUID }
      }
    }
    setDevice(device).then(res => {
      if(res.success) {
        toast.success(getT("saved.successfully"))
        setReloadNeeded(true)
      } else {
        console.log("Pos printer save error", res)
        toast.error(getT("error"))
      }
    })
  }

  const onChangePrinterData = (params) => {
    const device = {
      uuid: deviceUUID,
      deviceParams: {
        printer: params
      }
    }
    setDevice(device).then(res => {
      if(res.success) {
        setFiscalPrinter(res.data.deviceParams.printer)
        toast.success(getT("saved.successfully"))
        setReloadNeeded(true)
      } else {
        console.log("Pos printer save error", res)
        toast.error(getT("error"))
      }
      popModal()
    })
  }

  const handleSavePosPrinter = (printerData) => {
    setPrinter(printerData).then(res => {
      if(res.success) {
        setPosPrinter(res.data)
        onChangePosPrinter(res.data.uuid)
        setReloadNeeded(true)
        popModal()
      } else {
        console.log(res)
      }
    })
  }

  const handleDeletePosPrinter = () => {
    onChangePosPrinter(null)
    setPosPrinter(null)
    popModal()
  }

  const handleSelectPosPrinter = (printerUUID) => {
    onChangePosPrinter(printerUUID)
    setPosPrinter(printers?.[printerUUID])
    popModal()
  }

  const openPosPrinterModal = () => {
    loadModal(
      <PosPrinterModal 
        popModal={popModal}
        onSave={handleSavePosPrinter}
        onDelete={handleDeletePosPrinter}
        params={posPrinter}
        printers={printers}
        onSelect={handleSelectPosPrinter}
      />, popModal
    )
  }

  const openPrinterModal = () => {
    loadModal(
      <PrinterModal 
        popModal={popModal}
        onSave={onChangePrinterData}
        params={fiscalPrinter}
      />, popModal
    )
  }

  const checkOption = (name, value) => {
    changeLocalAppParam("behaviour", name, value)
  }

  const reload = () => {
    window.location.reload()
  }

  return (
    <div className="common-printer-control-panel-container">
      {reloadNeeded && <div className="reload-needed-container" onClick={reload}>{getT("settings.reload.needed")}</div>}
      <div className="title">{getT("settings.options.commonPrinter")}</div>
      <div className="name">{deviceName}</div>
      <div className="box-container">
        {featureList.posPrinter === 1 && <div className="device-data-box device-params printer-params">
          <div className="title">{getT("devices.posPrinterParams")}</div>
          {posPrinter != null && <div className="btn btn-highlighted" onClick={() => openPosPrinterModal()}>{posPrinter?.name || "Missing name"}</div>}
          {posPrinter == null && <div className="btn btn-highlighted" onClick={() => openPosPrinterModal()}>{getT('devices.addPosPrinter')}</div>}
        </div>}
        {featureList.fiscalPrinter === 1 && <div className="device-data-box device-params printer-params">
          <div className="title">{getT("devices.printerParams")}</div>       
            {(fiscalPrinter?.type == null || fiscalPrinter?.type === "") && <div className="btn btn-highlighted" onClick={() => openPrinterModal()}>{getT('devices.addPrinter')}</div>}
            {fiscalPrinter?.type != null && fiscalPrinter?.type !== "" && <div className="btn btn-highlighted" onClick={() => openPrinterModal()}>{fiscalPrinter?.type}</div>}
        </div>}
      </div>
      <div className="sub-title">{getT("settings.device.behaviour")}</div>
      {localAppParams?.behaviour?.localSettingsOverRideAllowed === false && <p>{getT("settings.device.paramsSettingNotAllowed")}</p>}
      <div className="app-params">
        <div className={`option ${localAppParams?.behaviour?.printQRCode ? "selected" : ""}`} onClick={() => checkOption("printQRCode", !localAppParams?.behaviour?.printQRCode)}>
          <div className="inner-bg"></div>
          <div className="param-name">{getT(`modal.personalSettings.option.printQRCode`)}</div>
        </div>
        <div className={`option ${localAppParams?.behaviour?.printCustomerBankcardSlip ? "selected" : ""}`} onClick={() => checkOption("printCustomerBankcardSlip", !localAppParams?.behaviour?.printCustomerBankcardSlip)}>
          <div className="inner-bg" ></div>
          <div className="param-name">{getT(`modal.personalSettings.option.printCustomerBankcardSlip`)}</div>
        </div>
      </div>
    </div>
  )
}
export default CommonPrinterSettings