import { useRef } from "react"
import useMultilang from "intl/useMultilang"

function OrderIdentifierModal(props) {

    const { orderIdentifier, setOrderIdentifier, popModal } = props

    const { getT } = useMultilang()

    const value = useRef("")

    const handleBlur = (e) => value.current = e.target.value

    const handleSave = () => {
        setOrderIdentifier(value.current)
        popModal()
    }

    return(
        <>
            <h2>{getT("sell.basket.orderIdentifier")}</h2>
            <div className="textField-root order-identifier-field">
                <div className="textField-base">
                    <input className="textField-input" style={{padding: "12px"}} defaultValue={orderIdentifier || ""} onBlur={handleBlur} />
                </div>
            </div>
            <div className="button-container" style={{display: "flex", justifyContent: "center", gap: "12px", marginTop: "12px"}}>
                <div className="btn btn-cancel" onClick={popModal}>
                    {getT("identifier.modal.cancel")}
                </div>
                <div className={`btn btn-save`} onClick={handleSave}>
                    {getT("identifier.modal.next")}
                </div>
            </div>
        </>
    )
}
export default OrderIdentifierModal