import useMultilang from "intl/useMultilang"

function SetItem(props) {

    const { goToNext } = props
    
    const { getT } = useMultilang()

    return(
        <div className="hidden appear create-item-root" onClick={goToNext}>
            <div className="text-box message border-glow-effect">
                {getT("tutorial.setItemText")}
            </div>
            <div className="text-box action" onClick={goToNext}>
                <div className="btn btn-highlighted border-glow-effect pulsate-fwd">{getT("tutorial.goToSell")}</div>
            </div>
        </div>
    )
}
export default SetItem