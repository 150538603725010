function Modal(props) {

    const { open, onClickBg, children } = props

    const handleClickOnBgLayer = () => {
        if(typeof onClickBg === "function") onClickBg()
    }

    if(!open) return null

    return(
        <div className="fiscat-modal hidden quickAppear">
            <div className="fiscat-modal-bg-layer" onClick={handleClickOnBgLayer}></div>
            <div className="fiscat-modal-content">
                {children}
            </div>
        </div>
    )
}
export default Modal