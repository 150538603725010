import { useContext } from "react";
import { DataTableContext } from "./DataTableContext";
import InputSwitch from "components/InputSwitch";
import Row from "./Row";
import Cell from "./Cell";

function Body(props) {
  const {
    refMatrix,
    showRowsFromTo,
  } = props;

  const { data, setData, columns, checkbox, underRowName, showScrollBar, underRowContent } =
    useContext(DataTableContext);

  const moveRow = (dragIndex, hoverIndex) => {
    if (typeof dragIndex === "number" && typeof hoverIndex === "number") {
      setData(
        Object.values({
          ...data,
          [dragIndex]: { ...data[hoverIndex] },
          [hoverIndex]: { ...data[dragIndex] },
        })
      );
    }
  };

  const selectRow = (row, index) => {
    setData(
      Object.values({
        ...data,
        [index]: {
          ...row,
          _checked: row._checked ? false : true,
        },
      })
    );
  };

  const switchContainerState = (row, index) => {
    setData(
      Object.values({
        ...data,
        [index]: {
          ...row,
          _open: underRowName ? (row._open ? false : true) : true,
        },
      })
    );
  };

  //   return null

  if (!data) return null;
  return (
    <tbody className={`data-body ${showScrollBar ? "hidescrollbar" : ""}`}>
      {data
        .filter((row, rowIndex) => {
          return showRowsFromTo[0] <= rowIndex && rowIndex < showRowsFromTo[1];
        })
        .map((row, rowIndex) => {
          return [
            <Row
              key={rowIndex}
              rowIndex={rowIndex}
              moveRow={moveRow}
            >
              {checkbox && typeof setData === "function" &&
                <td className="data-cell checkbox-cell">
                  <InputSwitch
                    onChange={() => selectRow(row, rowIndex)}
                    checked={row?._checked === true}
                    isCheckbox
                  />
                </td>}
              {columns.map((cell, cellIndex) => {
                if (underRowName && cell?.name === underRowName) {
                  return (
                    <td
                      key={cellIndex}
                      className={`data-cell ${cell.name}`}
                      onClick={() => {
                        switchContainerState(row, rowIndex);
                      }}
                    >
                      {row?._open ? "^" : "V"}
                    </td>
                  );
                } else {
                  return (
                    <Cell
                      key={rowIndex + "c" + cellIndex}
                      rowIndex={rowIndex}
                      cellIndex={cellIndex}
                      cell={cell}
                    >
                      {cell.options.format
                        ? cell.options.format({...row, ...{"dataTableRowRef":refMatrix.current[rowIndex]}})
                        : row[cell.name]}
                    </Cell>
                  );
                }
              })}
            </Row>,
            underRowContent && (
              <tr
                key={rowIndex + "a"}
                className={`under-row-container ${
                  row?._open ? "" : "under-row-container-hidden"
                }`}
              >
                <td className={`${underRowName}`} colSpan={columns.length + 1}>
                  {underRowContent(row, rowIndex)}
                </td>
              </tr>
            ),
          ];
        })}
    </tbody>
  );
}

export default Body;