import { useContext, useEffect, useState } from "react"
import useMultilang from "intl/useMultilang"
import Modal from "components/Modal/Modal"
import Payment from "views/Payment"
import { v4 as uuidv4 } from "uuid"
import { ModalContext } from "contexts/ModalContext"
import { MainContext } from "contexts/MainContext"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import NumberFormat from "react-number-format"
import { useMain, useLocation } from "okeoke.client"
import LoadSvg from "components/LoadSvg-v2"
import TutorialEndedModal from "./TutorialEndedModal"
import SettingsWizard from "components/SettingsWizard"

function TotalPriceOfOrder(props) {

  const { priceAndSubmitOpen, togglePanel, setTutorialStep, endTutorial } = props

  const { paymentStartAllowed } = useContext(MainContext)
  const { discountPrice, paymentsNeeded, totalPrice, swiper, sumPrice, discountAmount, sumServiceFee, tipAmount, packagingPrice, deliveryCost, tableInfo, submitAndStartNewOrder, itemCount } = useContext(SelectedOrderContext)
  const { addModal, popModal } = useContext(ModalContext)
  const { getT } = useMultilang()
  const [update, setUpdate] = useState(null)
  const { location } = useLocation()

  const getData = (message) => {
    if(message?.action === "update") setUpdate(message?.data)
  }
  const { messageSecondary } = useMain(getData)

  useEffect(() => {
    messageSecondary({ action: "updateDiscountPrice", data: discountPrice })
    // eslint-disable-next-line
  }, [discountPrice, update])

  useEffect(() => {
    messageSecondary({ action: "updateTotalPrice", data: totalPrice })
    // eslint-disable-next-line
  }, [totalPrice, update])
  
  useEffect(() => {
    if(sumServiceFee > 0) messageSecondary({ action: "updateServiceFee", data: sumServiceFee })
    // eslint-disable-next-line
  }, [sumServiceFee, update])

  useEffect(() => {
    messageSecondary({ action: "updatePaymentsNeed", data: paymentsNeeded })
    // eslint-disable-next-line
  }, [paymentsNeeded, update])

  const onClosePaymentModal = () => {
    if(localStorage['firstStartTutorialFinished'] == null) {
      endTutorial()
      addModal(
        <Modal key={uuidv4()} className="tutorial-ended-modal" closeCallback={openSettingsWizard}>
          <TutorialEndedModal popModal={popModal} />
        </Modal>
      )
    }
  }

  const openPaymentModal = () => {
    if(paymentStartAllowed) {
      setTutorialStep("paymentPresentation")
      addModal(
        <Modal key={uuidv4()} className="payment-modal" closeCallback={onClosePaymentModal}>
          <Payment startOrderAfterPayment={true} swiper={swiper} />
        </Modal>
      )
    }
  }

  const openSettingsWizard = () => {
    addModal(
      <Modal key={uuidv4()} className="settings-wizard-modal" onClickLayout={popModal}>
        <SettingsWizard location={location} />
      </Modal>
    )
  }

  const handleSubmitOrder = () => {
    if(itemCount > 0) submitAndStartNewOrder()
  }

  return (
    <div className={`order-summary-payment-container ${priceAndSubmitOpen ? "open" : "closed"} ${tableInfo != null ? "submit-on" : ""}`}>
      <div className="toggler" onClick={togglePanel}><LoadSvg name="arrowUp" /></div>
      <div className="details">
        <div className="order-payment-container">
          <div className="">{getT("sell.basket.sumPrice")}:</div>
          <div className="">
            <NumberFormat
              value={sumPrice}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
        </div>
        <div className="order-payment-container">
          <div className="">{getT("sell.basket.package")}:</div>
          <div className="">
            <NumberFormat
              value={packagingPrice}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
        </div>
        <div className="order-payment-container">
          <div className="">{getT("sell.basket.deliveryCost")}:</div>
          <div className="">
            <NumberFormat
              value={deliveryCost}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
        </div>
        <div className="order-payment-container">
          <div className="">{getT("sell.basket.serviceFeeTotal")}:</div>
          <div className="">
            <NumberFormat
              value={sumServiceFee}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
        </div>
        <div className="order-payment-container">
          <div className="">{getT("sell.basket.tipAmount")}:</div>
          <div className="">
            <NumberFormat
              value={tipAmount}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
        </div>
        <div className="order-payment-container">
          <div className="">{getT("sell.basket.totalDiscountsValue")}:</div>
          <div className="">
            <NumberFormat
              value={-(discountAmount)}
              decimalSeparator=","
              suffix=" Ft"
              thousandSeparator="."
              displayType="text"
            />
          </div>
        </div>
      </div>
      <div className="btn-container">
        <div className={`btn btn-highlighted ${paymentStartAllowed ? "" : "inactive"}`} onClick={openPaymentModal}>
          <span style={{ marginRight: "4px" }}>{`${getT("sell.basket.payment")}: `}</span>
          <NumberFormat
            value={discountPrice}
            decimalSeparator=","
            suffix=" Ft"
            thousandSeparator="."
            displayType="text"
          />
        </div>
        {tableInfo != null && <div className={`btn submit-btn ${itemCount > 0 ? "" : "inactive"}`} onClick={handleSubmitOrder}>{getT("sell.basket.submit")}</div>}
      </div>
    </div>
  )
}
export default TotalPriceOfOrder