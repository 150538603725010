import useMultilang from "intl/useMultilang"

function SellItemPresentation(props) {

    const { goToNext } = props

    const { getT } = useMultilang()

    return(
        <div className="sell-item-presentation-root" onClick={goToNext}>
            <div className="text-box border-glow-effect">
                {getT("tutorial.sellItemPresentation")}
            </div>
        </div>
    )
}
export default SellItemPresentation