import { useContext, useEffect } from "react"
import moment from "moment"
import Order from "./Order"
import useMultilang from "intl/useMultilang"
import { PreviousOrderContext } from "contexts/PreviousOrderContext"

function OrderList() {

    const { refreshOrders, orders, selectedOrderUUID, setSelectedOrderUUID } = useContext(PreviousOrderContext)
    const { getT } = useMultilang()

    useEffect(() => {
        refreshOrders()
        // eslint-disable-next-line
    }, [])

    return(
        <div className="orders-root">
            <div className="title">
                {getT("settings.transactions.closedSuccesfulorders")}
            </div>
            <div className="orders-container">
            {Object.values(orders || {})
            .sort((a, b) => moment(a.insertTimestamp) > moment(b.insertTimestamp) ? 1 : 0)
            .map((order, index) => 
                <Order 
                    key={index} 
                    order={order}
                    selectedOrderUUID={selectedOrderUUID}
                    setSelectedOrderUUID={setSelectedOrderUUID}
                />
            )}
            </div>
        </div>
    )
}
export default OrderList