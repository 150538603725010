import { useContext } from "react"
import CreateNewCategory from "views/Editor/CreateNewCategory"
import useMultilang from "intl/useMultilang"
import Modal from "components/Modal/Modal"
import LoadSvg from "components/LoadSvg-v2"
import { ModalContext } from "contexts/ModalContext"
import { MainContext } from "contexts/MainContext"
import { v4 as uuidv4 } from "uuid"
import { useItems}  from "okeoke.client"
import { useLocation } from "react-router-dom"

function CreateNewCategoryButton() {

  const { getT } = useMultilang()
  const { categories } = useItems()
  const { addModal, popModal } = useContext(ModalContext)
  const { selectedCategUUID } = useContext(MainContext)
  const editorMode = useLocation().pathname === "/editor"

  const openCreateCategoryInModal = (parentUUID) => {
    addModal(
      <Modal
        key={uuidv4()}
        className="create-new-category-modal"
        onClickLayout={popModal}
      >
        <CreateNewCategory parentUUID={parentUUID} />
      </Modal>
    )
  }

  if(!editorMode || selectedCategUUID == null || categories?.[selectedCategUUID]?.parentUUID !== "") return null

  return (
    <div className="hidden appear order-item-container order-dummy-item-container" onClick={() => openCreateCategoryInModal(selectedCategUUID)}>
      <div className="inner-container">
        <div className="svg-container"><LoadSvg name={"subCateg"} /></div>
        <div className="text">{selectedCategUUID ? getT("sell.edit.subCreateCategory") : getT("sell.edit.CreateCategory")}</div>
      </div>
    </div>
  )
}

export default CreateNewCategoryButton
