import useMultilang from "intl/useMultilang"

function WelcomeDemoModal(props) {

    const { popModal } = props
    const { getT } = useMultilang()

    const handleClickDone = () => {
        localStorage['welcomeDone'] = 1
        popModal()
    }

    return(
        <div className="content">
            <div className="title">{getT("demo.welcome.title")}</div>
            <div className="body">
                <div className="text">{getT("demo.welcome.text")}</div>
                <div className="text-do text-can-do">{getT("demo.welcome.canDoText")}</div>
                <ul className="list list-can-do">
                    <li>{getT("demo.welcome.canDoText.element1")}</li>
                    <li>{getT("demo.welcome.canDoText.element2")}</li>
                    <li>{getT("demo.welcome.canDoText.element3")}</li>
                </ul>
                <div className="text-do text-cant-do">{getT("demo.welcome.cantDoText")}</div>
                <ul className="list list-cant-do">
                    <li>{getT("demo.welcome.cantDoText.element1")}</li>
                    <li>{getT("demo.welcome.cantDoText.element2")}</li>
                    <li>{getT("demo.welcome.cantDoText.element3")}</li>                    
                </ul>
            </div>
            <div className="actions">
                <div className="btn btn-highlighted" onClick={handleClickDone}>{getT("demo.welcome.done")}</div>
            </div>
        </div>
    )
}
export default WelcomeDemoModal