import { useContext } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import useMultilang from "intl/useMultilang"
import Button from "../Button"
import { MainContext } from "contexts/MainContext"

function ReportsMenu() {

    const { featureList } = useContext(MainContext)

    const { getT } = useMultilang()
    const navigate = useNavigate()
    const path = useLocation().pathname

    if(!featureList.reports) return null
    
    return(
        <div className="admin-button-group">
            <Button  
                highlighted={path === "/admin/summary"}
                onClick={() => navigate("/admin/summary")}
                label={getT("settings.options.summary")}
                svgName="list"
            />
            <Button  
                highlighted={path === "/admin/payment-type"}
                onClick={() => navigate("/admin/payment-type")}
                label={getT("settings.options.paymentType")}
                svgName="creditCard"
            />
            <Button  
                highlighted={path === "/admin/item-sales"}
                onClick={() => navigate("/admin/item-sales")}
                label={getT("settings.options.itemSales")}
                svgName="tag"
            />
            <Button  
                highlighted={path === "/admin/vat-sum"}
                onClick={() => navigate("/admin/vat-sum")}
                label={getT("settings.options.vatSum")}
                svgName="vat"
            />
            <Button  
                highlighted={path === "/admin/transactions"}
                onClick={() => navigate("/admin/transactions")}
                label={getT("settings.options.transactions")}
                svgName="transactions"
            />
            {featureList.localDiscounts === 1 && <Button  
                highlighted={path === "/admin/discounts"}
                onClick={() => navigate("/admin/discounts")}
                label={getT("settings.options.discounts")}
                svgName="percent"
            />}
            {featureList.multiUser === 1 && <Button  
                highlighted={path === "/admin/users"}
                onClick={() => navigate("/admin/users")}
                label={getT("settings.options.users")}
                svgName="users"
            />}
            <Button  
                highlighted={path === "/admin/devices"}
                onClick={() => navigate("/admin/devices")}
                label={getT("settings.options.devices")}
                svgName="monitor"
            />
        </div>
    )
}
export default ReportsMenu