//import { useDevice } from "okeoke.client"
//import useMultilang from "intl/useMultilang"

function PosPrinterSettings() {

  //const { deviceName, deviceParams} = useDevice()
  //const printerType = deviceParams?.printer?.type

  //const { getT } = useMultilang()

  return (
    <div className="pos-printer-control-panel-container">
     Coming soon...
    </div>
  )
}
export default PosPrinterSettings