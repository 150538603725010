import { useEffect, useState } from "react"
import useCategoriesApi from "apis/catalog/useCategoriesApi"
import useMultilang from "intl/useMultilang"
import { toast } from "react-toastify"
import TextField from "components/TextField"
import InputFieldSelect from "components/InputFieldSelect"
import useNtakApi from "apis/useNtakApi"
import useVatGroupsApi from "apis/catalog/useVatGroupsApi"

function EditCateg(props) {

    const { selectedCategUUID } = props

    const { getCategory, setCategory, getCategories } = useCategoriesApi()
    const { getNtakCategories } = useNtakApi()
    const { language, getT } = useMultilang()
    const { getVatGroups } = useVatGroupsApi()
    const [categData, setCategData] = useState(null)
    const [categories, setCategories] = useState(null)
    const [ntakCategories, setNtakCategories] = useState(null)
    const [selectedNtakCategory, setSelectedNtakCategory] = useState("")
    const [vatGroups, setVatGroups] = useState([])

    useEffect(() => {
        loadCategories()
        
        getNtakCategories().then(res => {
          if(res.success) {
            let obj = {}
            for(let categ of Object.values(res.data || {})) {
                obj[categ.code] = categ
            }
            setNtakCategories(obj)
          } else {
            console.log(res)
          }
        })

        getVatGroups().then(res => {
            if(res.success) {
              setVatGroups(res.data)
            } else {
              console.log(res)
            }
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(selectedCategUUID != null) loadCateg()
        // eslint-disable-next-line
    }, [selectedCategUUID])

    useEffect(() => {
        if(categData != null && categData.attributes?.ntakCategoryID != null) {
          let ntakCategoryName = getCategNameBySubCategID(categData.attributes?.ntakCategoryID)
          setSelectedNtakCategory(ntakCategoryName)
        } else setSelectedNtakCategory("")
        // eslint-disable-next-line
    }, [categData])
    
    const getCategNameBySubCategID = (subCategID) => {
        let categName = ""
        for(let categ of Object.values(ntakCategories || {})) {
          if(categ.subCategories?.[subCategID] != null) categName = categ.code
        }
        return categName
    }

    const loadCateg = () => {
        getCategory(selectedCategUUID).then(res => {
            if(res.success) {
                setCategData(res.data)
                loadCategories()
            } else {
                console.log(res)
            }
        })
    }

    const loadCategories = () => {
        getCategories().then(res => {
            if(res.success) {
                let categArray = res.data.filter(categ => categ.parentUUID === "")
                setCategories(categArray)
            } else {
                console.log(res)
            }
        })
    }

    const saveCateg = (values) => {
        setCategory(values).then(res => {
            if(res.success) {
                setCategData(res.data)
                //toast.success(getT("editCateg.saved"))
            } else {
                toast.error(res.message)
            }
        })
    }

    const setCategName = (name) => {
        setCategData({ ...categData, name: {...categData.name, [language]: name}})
    }

    const handleChangeParent = (e) => {
        saveCateg({ ...categData, parentUUID: e.target.value })
    }

    const enableCateg = (value) => {
        if(value !== categData.enabled) saveCateg({ ...categData, enabled: value })
    }

    const handleNtakCategChange = (e) => {
        if(e.target.name === "ntakCategory") {
            setSelectedNtakCategory(e.target.value)
        }
        if(e.target.name === "ntakCategoryID") {
            saveCateg({ ...categData, attributes: {...categData.attributes, ntakCategoryID: e.target.value }})
        }
    }

    const handleChangeVatGroupID = (e) => {
        saveCateg({ ...categData, attributes: {...categData.attributes, vatGroupID: e.target.value }})
    }

    if(categData == null) return null

    return(
        <div className="summary-root item-editor-container-root">
            <div className="header">
                <div className="text">{categData.name?.[language]}</div>
            </div>
            <div className="editor-container">
                <div className="enabled-container">
                    <div className={`switch-btn enabled ${categData.enabled ? "active" : "inactive"}`} onClick={() => enableCateg(1)}>{getT("editCateg.enabled")}</div>
                    <div className={`switch-btn not-enabled ${!categData.enabled ? "active" : "inactive"}`} onClick={() => enableCateg(0)}>{getT("editCateg.notEnabled")}</div>
                </div>
                <div className="inputfield-containers">
                    <div className="item-editor-inputfield">
                        <div className="upper-label">
                            {getT("editCateg.categName")}
                        </div>
                        <TextField
                            type="text"
                            name="name"
                            onChange={e => setCategName(e.target.value)}
                            onBlur={() => saveCateg(categData)}
                            value={categData.name?.[language]}
                        />
                    </div>
                    <div className="item-editor-inputfield">
                        <div className="upper-label">
                            {getT("editCateg.parentName")}
                        </div>
                        <InputFieldSelect
                            className={"create-new-item-root-input-field-select"}
                            options={[...(categories || []).map(categ => { return { value: categ.uuid, title: categ.name?.[language] } }), { value: "", title: getT("editCateg.noParent")}]}
                            name="parentUUID"
                            onChange={handleChangeParent}
                            value={categData.parentUUID}
                        />
                    </div>
                    <div className="item-editor-inputfield">
                        <div className="upper-label">
                            {getT("editItem.view.VATGroup")}
                        </div>
                        <InputFieldSelect
                            className={"create-new-item-root-input-field-select"}
                            options={(vatGroups || []).map(vat => { return { value: vat.id, title: `${vat.name} (${parseInt(vat?.vat || 0)}% / ${parseInt(vat?.vatTA || 0)}%)` } })}
                            name="vatGroupID"
                            onChange={handleChangeVatGroupID}
                            value={categData.attributes.vatGroupID}
                            displayedDefaultName={getT("editCateg.selectVatGroup")}
                        />
                    </div>
                    <div className="ntak-select">
                        <InputFieldSelect
                            className={"create-new-item-root-input-field-select"}
                            options={Object.values(ntakCategories || {}).map(categ => {
                                return { title: categ.name?.[language], value: categ.code }
                            })}
                            onChange={handleNtakCategChange}
                            name="ntakCategory"
                            value={selectedNtakCategory}
                            displayedDefaultName={getT("createItem.modal.selectNtakMainCategory")}
                            label={getT("createItem.modal.ntakCategory")}
                        />
                        <InputFieldSelect
                            className={"create-new-item-root-input-field-select"}
                            options={Object.values(ntakCategories?.[selectedNtakCategory]?.subCategories || {}).map(categ => {
                                return { title: categ.name?.[language], value: categ.id }
                            })}
                            onChange={handleNtakCategChange}
                            name="ntakCategoryID"
                            value={categData.attributes?.ntakCategoryID}
                            displayedDefaultName={getT("createItem.modal.selectNtakSubCategory")}
                            disabled={selectedNtakCategory === ""}
                            label={getT("createItem.modal.ntakSubCategory")}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EditCateg