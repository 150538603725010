import { useState, useEffect } from "react"
import moment from "moment"
import useMultilang from "intl/useMultilang"

function CloseModal(props) {

    const { closeDay, popModal, dayState } = props

    const { getT } = useMultilang()

    const [closeTimestamp, setCloseTimestamp] = useState(moment())

    useEffect(() => {
        let closeTime = moment()
        
        if(closeTime.diff(dayState?.openTimestamp, "m") > 1440) {
            closeTime = moment(dayState?.openTimestamp).add(1440, 'minutes')
        }
        
        setCloseTimestamp(closeTime)
        // eslint-disable-next-line
    }, [])

    const handleCloseDay = () => {
        closeDay(moment(dayState?.openTimestamp).format("YYYY-MM-DD"), moment(closeTimestamp).toISOString())
        popModal()
    }

    const handleDateChange = (e) => {
        console.log(e.target.value)
        if(moment(e.target.value).diff(dayState?.openTimestamp, "m") > 1440) return null
        if(moment(e.target.value).isBefore(dayState?.openTimestamp, "minutes")) return null
        if(moment(e.target.value).isSame(dayState?.openTimestamp, "minutes")) return null

        setCloseTimestamp(moment(e.target.value))
    }

    return(
        <div className="work-day-modal">
            <div className="title">{getT("workDayModal.close.title")}</div>
            {/* <div className="text">{`${getT("workDayModal.close.lastOpenTimestamp")}: ${moment(dayState?.openTimestamp).format("MM-DD HH:mm:ss")}`}</div> */}
            <div className="last-open">
                <div className="label">{getT("workDayModal.close.lastOpen")}</div>
                <div className="date-time-container">
                    <input type="datetime-local" value={moment(dayState?.openTimestamp).format("YYYY-MM-DD HH:mm")} disabled/>
                </div>
            </div>
            <div className="last-open">
                <div className="label">{getT("workDayModal.close.closeTime")}</div>
                <div className="date-time-container">
                    <input type="datetime-local" value={closeTimestamp.format("YYYY-MM-DD HH:mm")} onChange={handleDateChange}/>
                </div>
            </div>
            <div className="buttons-container">
                <div className="btn" onClick={popModal}>{getT("workDayModal.close.cancel")}</div>
                <div className="btn btn-highlighted" onClick={handleCloseDay}>{getT("workDayModal.close.close")}</div>
            </div>
        </div>
    )
}
export default CloseModal