import { useEffect, useState } from 'react'
import LoadSvg from 'components/LoadSvg-v2'

function ModifierGroupRadio(props) {

  const { modifiers, setModifierCount, name } = props
  const [idSelected, setIDSelected] = useState(null)

  useEffect(() => {
    for(let modifier of modifiers) {
      if(modifier.count > 0) setIDSelected(modifier.uuid)
    }
    // eslint-disable-next-line
  }, [])

  const setSelected = (id) => {
    if (idSelected !== id) {
      setModifierCount(id, 1)
      setModifierCount(idSelected, 0)
    }
    setIDSelected(id)
  }

  if(modifiers == null) return null

  return (
    <>
      <div className="modifier-group-name">{name}</div>
      <div className="modifiers-container">
        {modifiers
        .filter(modifier => modifier?.availability?.available === 1 || idSelected === modifier.uuid)
        .map(modifier => (
          <div key={modifier.uuid} className={`radio-button-root ${idSelected === modifier.uuid ? "selected" : ""}`} onClick={() => setSelected(modifier.uuid)}>
            <div className="inner-bg"></div>
            {modifier?.availability?.visible === 0 && 
              <div className="visibility-container">
                <LoadSvg name="visibility" />
              </div>
            }
            <div className="radio-button-label">{modifier?.name?.hu}</div>
            {modifier?.price != null && <div className="radio-button-price">+ {+modifier?.price} Ft</div>}
          </div>
        ))}
      </div>
    </>
  )
}
export default ModifierGroupRadio