import { ReactComponent as Warning } from "assets/svg/gebasz.svg";
import { ReactComponent as Edit } from "assets/svg/edit.svg";
import { ReactComponent as InfoIcon } from "assets/svg/akar-icons_info-fill.svg";
import { ReactComponent as HouseIcon } from "assets/svg/ant-design_home-filled.svg";
import { ReactComponent as CogIcon } from "assets/svg/ci_settings-filled.svg";
import { ReactComponent as GlobeIcon } from "assets/svg/clarity_world-line.svg";
import { ReactComponent as BinIcon } from "assets/svg/trash_icon.svg";
import { ReactComponent as PurchaseTagIcon } from "assets/svg/bxs_purchase-tag.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/svg/arrow_left.svg";
import { ReactComponent as CartIcon } from "assets/svg/cart.svg";
import { ReactComponent as ModifierIcon } from "assets/svg/modifier-icon.svg";
import { ReactComponent as ArrowDeleteIcon } from "assets/svg/arrow_delete.svg";
import { ReactComponent as NumberRowSwitchIcon } from "assets/svg/number_row_switch.svg";
import { ReactComponent as CreateNewItemIcon } from "assets/svg/create_new_item.svg";
import { ReactComponent as ClockIcon } from "assets/svg/clock_icon.svg";
import { ReactComponent as PrinterIcon } from "assets/svg/printer_icon.svg";
import { ReactComponent as Printer } from "assets/svg/printer.svg";
import { ReactComponent as FlagRoundedIconHU } from "assets/svg/flag_round_hu.svg";
import { ReactComponent as FlagRoundedIconEN } from "assets/svg/flag_round_en.svg";
import { ReactComponent as FlagRoundedIconDE } from "assets/svg/flag_round_de.svg";
import { ReactComponent as FlagRoundedIconRU } from "assets/svg/flag_round_ru.svg";
import { ReactComponent as FlagRoundedIconUA } from "assets/svg/flag_round_ua.svg";
import { ReactComponent as EditInSquereIcon } from "assets/svg/edit_in_squere.svg";
import { ReactComponent as CategoryIcon } from "assets/svg/category_icon.svg";
import { ReactComponent as EditIcon } from "assets/svg/edit.svg";
import { ReactComponent as EditorModeIcon } from "assets/svg/editor_mode_icon.svg";
import { ReactComponent as SellModeIcon } from "assets/svg/sell_mode_icon.svg";
import { ReactComponent as PlusIcon } from "assets/svg/plus_icon.svg";
import { ReactComponent as CheckInCircleIcon } from "assets/svg/check_in_circle.svg";
import { ReactComponent as XInCircleIcon } from "assets/svg/x_in_circle.svg";
import { ReactComponent as UpArrowCurveLeftIcon } from "assets/svg/up-arrow-curve-left-icon.svg";
import { ReactComponent as Check } from "assets/svg/multiselect-check-icon.svg";
import { ReactComponent as SizeChangeIcon } from "assets/svg/size_change_icon.svg";
import { ReactComponent as Customize } from "assets/svg/customize.svg";
import { ReactComponent as Plugged } from "assets/svg/plugged.svg";
import { ReactComponent as UnPlugged } from "assets/svg/unplugged.svg";
import { ReactComponent as Basket } from "assets/svg/basket.svg";
import { ReactComponent as XCircle } from "assets/svg/x-circle.svg";
import { ReactComponent as TakeAwayIcon } from "assets/svg/material-symbols_shopping-bag-outline.svg";
import { ReactComponent as EatInIcon } from "assets/svg/eatInIcon.svg";
import { ReactComponent as Visible } from "assets/svg/visible.svg";
import { ReactComponent as Visibility } from "assets/svg/visibility.svg";
import { ReactComponent as NotVisible } from "assets/svg/not-visible.svg";
import { ReactComponent as Percent } from "assets/svg/percent.svg";
import { ReactComponent as Pos } from "assets/svg/pos.svg";
import { ReactComponent as Kiosk } from "assets/svg/kiosk.svg";
import { ReactComponent as Refresh } from "assets/svg/ep_refresh-left.svg";
import { ReactComponent as Pos2 } from "assets/svg/pos2.svg";
import { ReactComponent as Mobile } from "assets/svg/mobile.svg";
import { ReactComponent as Desktop } from "assets/svg/desktop.svg";
import { ReactComponent as Tablet } from "assets/svg/tablet.svg";
import { ReactComponent as Clock } from "assets/svg/ic_round-access-time-filled.svg";
import { ReactComponent as Avatar } from "assets/svg/carbon_user-avatar-filled.svg";
import { ReactComponent as Status } from "assets/svg/heroicons-solid_status-online.svg";
import { ReactComponent as ExclamMark } from "assets/svg/fluent-emoji-high-contrast_white-exclamation-mark.svg";
import { ReactComponent as PayCard } from "assets/svg/payCard.svg";
import { ReactComponent as Notes } from "assets/svg/charm_notes.svg";
import { ReactComponent as Ntak } from "assets/svg/ntak.svg";
import { ReactComponent as SortIconNone } from "assets/svg/sortIconNone.svg"
import { ReactComponent as SortIconUp } from "assets/svg/sortIconUp.svg"
import { ReactComponent as SortIconDown } from "assets/svg/sortIconDown.svg"
import { ReactComponent as GridDots } from "assets/svg/grid_dots.svg"
import { ReactComponent as BrandApp } from "assets/svg/akar-icons_mobile-device.svg"
import { ReactComponent as BarSoftLogo } from "assets/svg/barsoftlogo.svg"
import { ReactComponent as MenuOpen } from "assets/svg/ic_round-menu-open.svg"
import { ReactComponent as Fiscal } from "assets/svg/fiscal.svg"
import { ReactComponent as Dollar } from "assets/svg/dollar-sign.svg"
import { ReactComponent as CreditCard } from "assets/svg/credit-card.svg"
import { ReactComponent as Clock2 } from "assets/svg/clock.svg"
import { ReactComponent as Users } from "assets/svg/users.svg"
import { ReactComponent as Tag } from "assets/svg/tag.svg"
import { ReactComponent as Sun } from "assets/svg/sun.svg"
import { ReactComponent as SubCateg } from "assets/svg/subcategory.svg"
import { ReactComponent as ShoppingBag } from "assets/svg/shopping-bag.svg"
import { ReactComponent as Moon } from "assets/svg/moon.svg"
import { ReactComponent as Plus } from "assets/svg/plus.svg"
import { ReactComponent as PieChart } from "assets/svg/pie-chart.svg"
import { ReactComponent as Monitor } from "assets/svg/monitor.svg"
import { ReactComponent as Menu } from "assets/svg/menu.svg"
import { ReactComponent as List } from "assets/svg/list.svg"
import { ReactComponent as Layout } from "assets/svg/layout.svg"
import { ReactComponent as Transactions } from "assets/svg/grommet-icons_transaction.svg"
import { ReactComponent as Globe } from "assets/svg/globe.svg"
import { ReactComponent as Settings } from "assets/svg/settings.svg"
import { ReactComponent as Info } from "assets/svg/info.svg"
import { ReactComponent as Folder } from "assets/svg/material-symbols_folder-open-outline.svg"
import { ReactComponent as Vat } from "assets/svg/vat.svg"
import { ReactComponent as Exit } from "assets/svg/ion_exit-outline.svg"
import { ReactComponent as Restart } from "assets/svg/material-symbols_restart-alt-rounded.svg"
import { ReactComponent as Tipp } from "assets/svg/ri_hand-coin-line.svg"
import { ReactComponent as Coins } from "assets/svg/bx_coin-stack.svg"
import { ReactComponent as ArrowUp } from "assets/svg/material-symbols_keyboard-arrow-up-rounded.svg"
import { ReactComponent as Details } from "assets/svg/gg_details-more.svg"
import { ReactComponent as Locked } from "assets/svg/fluent-emoji-high-contrast_locked.svg"
import { ReactComponent as Search } from "assets/svg/ic_round-manage-search.svg"
import { ReactComponent as SettingsAlert } from "assets/svg/fluent-mdl2_alert-settings.svg"
import { ReactComponent as Upload } from "assets/svg/dashicons_upload.svg"
import { ReactComponent as Table } from "assets/svg/material-symbols_table-restaurant-outline.svg"

function LoadSvg(props) {
  const { name, onClick, className } = props;

  const handleClick = (e) => {
    if (typeof onClick === "function" && !className) onClick(e);
  };

  const handleDivClick = (e) => {
    if (typeof onClick === "function") onClick(e);
  }

  if (name == null) return null;

  const svgObject = {
    percent: <Percent onClick={handleClick} />,
    Visible: <Visible onClick={handleClick} />,
    NotVisible: <NotVisible onClick={handleClick} />,
    EatInIcon: <EatInIcon onClick={handleClick} />,
    TakeAwayIcon: <TakeAwayIcon onClick={handleClick} />,
    XCircle: <XCircle onClick={handleClick} />,
    Basket: <Basket onClick={handleClick} />,
    Plugged: <Plugged onClick={handleClick} />,
    UnPlugged: <UnPlugged onClick={handleClick} />,
    Customize: <Customize onClick={handleClick} />,
    SizeChangeIcon: <SizeChangeIcon onClick={handleClick} />,
    check: <Check onClick={handleClick} />,
    UpArrowCurveLeftIcon: <UpArrowCurveLeftIcon onClick={handleClick} />,
    XInCircleIcon: <XInCircleIcon onClick={handleClick} />,
    CheckInCircleIcon: <CheckInCircleIcon onClick={handleClick} />,
    PlusIcon: <PlusIcon onClick={handleClick} />,
    SellModeIcon: <SellModeIcon onClick={handleClick} />,
    EditorModeIcon: <EditorModeIcon onClick={handleClick} />,
    EditIcon: <EditIcon onClick={handleClick} />,
    CategoryIcon: <CategoryIcon onClick={handleClick} />,
    EditInSquereIcon: <EditInSquereIcon onClick={handleClick} />,
    FlagRoundedIconHU: <FlagRoundedIconHU onClick={handleClick} />,
    FlagRoundedIconEN: <FlagRoundedIconEN onClick={handleClick} />,
    FlagRoundedIconDE: <FlagRoundedIconDE onClick={handleClick} />,
    FlagRoundedIconRU: <FlagRoundedIconRU onClick={handleClick} />,
    FlagRoundedIconUA: <FlagRoundedIconUA onClick={handleClick} />,
    PrinterIcon: <PrinterIcon onClick={handleClick} />,
    ClockIcon: <ClockIcon onClick={handleClick} />,
    CreateNewItemIcon: <CreateNewItemIcon onClick={handleClick} />,
    NumberRowSwitchIcon: <NumberRowSwitchIcon onClick={handleClick} />,
    ArrowDeleteIcon: <ArrowDeleteIcon onClick={handleClick} />,
    ModifierIcon: <ModifierIcon onClick={handleClick} />,
    CartIcon: <CartIcon onClick={handleClick} />,
    ArrowLeftIcon: <ArrowLeftIcon onClick={handleClick} />,
    PurchaseTagIcon: <PurchaseTagIcon onClick={handleClick} />,
    BinIcon: <BinIcon onClick={handleClick} />,
    InfoIcon: <InfoIcon onClick={handleClick} />,
    HouseIcon: <HouseIcon onClick={handleClick} />,
    CogIcon: <CogIcon onClick={handleClick} />,
    GlobeIcon: <GlobeIcon onClick={handleClick} />,
    Edit: <Edit onClick={handleClick} />,
    pos: <Pos onClick={handleClick} />,
    kiosk: <Kiosk onClick={handleClick} />,
    refresh: <Refresh onClick={handleClick} />,
    desktop: <Desktop onClick={handleClick} />,
    mobile: <Mobile onClick={handleClick} />,
    pos2: <Pos2 onClick={handleClick} />,
    tablet: <Tablet onClick={handleClick} />,
    clock: <Clock onClick={handleClick} />,
    avatar: <Avatar onClick={handleClick} />,
    status: <Status onClick={handleClick} />,
    exclamMark: <ExclamMark onClick={handleClick} />,
    payCard: <PayCard onClick={handleClick} />,
    notes: <Notes onClick={handleClick} />,
    ntak: <Ntak onClick={handleClick} />,
    sortIconNone: <SortIconNone onClick={handleClick} />,
    sortIconUp: <SortIconUp onClick={handleClick} />,
    sortIconDown: <SortIconDown onClick={handleClick} />,
    gridDots: <GridDots onClick={handleClick} />,
    brandApp: <BrandApp onClick={handleClick} />,
    barSoftLogo: <BarSoftLogo onClick={handleClick} />,
    menuOpen: <MenuOpen onClick={handleClick} />,
    fiscal: <Fiscal onClick={handleClick} />,
    dollar: <Dollar onClick={handleClick} />,
    creditCard: <CreditCard onClick={handleClick} />,
    clock2: <Clock2 onClick={handleClick} />,
    users: <Users onClick={handleClick} />,
    tag: <Tag onClick={handleClick} />,
    sun: <Sun onClick={handleClick} />,
    subCateg: <SubCateg onClick={handleClick} />,
    shoppingBag: <ShoppingBag onClick={handleClick} />,
    moon: <Moon onClick={handleClick} />,
    plus: <Plus onClick={handleClick} />,
    pieChart: <PieChart onClick={handleClick} />,
    monitor: <Monitor onClick={handleClick} />,
    menu: <Menu onClick={handleClick} />,
    list: <List onClick={handleClick} />,
    layout: <Layout onClick={handleClick} />,
    transactions: <Transactions onClick={handleClick} />,
    globe: <Globe onClick={handleClick} />,
    settings: <Settings onClick={handleClick} />,
    info: <Info onClick={handleClick} />,
    folder: <Folder onClick={handleClick} />,
    printer: <Printer onClick={handleClick} />,
    vat: <Vat onClick={handleClick} />,
    restart: <Restart onClick={handleClick} />,
    exit: <Exit onClick={handleClick} />,
    tipp: <Tipp onClick={handleClick} />,
    coins: <Coins onClick={handleClick} />,
    arrowUp: <ArrowUp onClick={handleClick} />,
    visibility: <Visibility onClick={handleClick} />,
    details: <Details onClick={handleClick} />,
    locked: <Locked onClick={handleClick} />,
    search: <Search onClick={handleClick} />,
    settingsAlert: <SettingsAlert onClick={handleClick} />,
    upload: <Upload onClick={handleClick} />,
    table: <Table onClick={handleClick} />
  };

  if(className)
  return <div className={className} onClick={handleDivClick}>{ svgObject?.[name] || <Warning /> }</div>

  return svgObject?.[name] || <Warning onClick={handleClick} />;
}

export default LoadSvg;
