import { useContext, useRef, useEffect, useState } from "react"
import BackToParentCateg from "./BackToParentCateg"
import MoveToSubCateg from "./MoveToSubCateg"
import { isMobile } from "react-device-detect"
import { PersonalSettingsContext } from "contexts/PersonalSettingsProvider"
import { MainContext } from "contexts/MainContext"
import { MainContainerContext } from "contexts/MainContainerProvider"
import useMultilang from "intl/useMultilang"
import { useItems } from "okeoke.client"
import Item from "./Item"
import PageSelector from "components/Pagination-v2/PageSelector"

function OrderItems(props) {

  const { setItemAdded } = props

  const { customization } = useContext(PersonalSettingsContext)

  const containerRef = useRef(null)
  const { categories, assembledItems } = useItems()
  const { selectedCategUUID } = useContext(MainContext)
  const { searchedText } = useContext(MainContainerContext)
  const { language } = useMultilang()

  const [paginationNames, setPaginationNames] = useState([])
  const [storedAssembledItems, setStoredAssembledItems] = useState([])
  const [addedItemUUID, setAddedItemUUID] = useState(null)

  let pageSelectorHeight = 42
  let flexGap = 12
  let rows = Math.floor(((containerRef.current?.offsetHeight || 0) - pageSelectorHeight) / ((customization?.orderItemFontSize || 16)*(customization?.orderItemHeight || 8) + flexGap))
  let cols = customization?.orderItemsColumns || 3
 
  const sortFunction = (a, b) => assembledItems[a.uuid].name?.[language] > assembledItems[b.uuid].name?.[language] ? 1 : -1

  const searchFunction = (item) => {
    let filteredSearchText = replaceAll(searchedText.toLowerCase(), {
      " ": "",
      "á": "a",
      "é": "e",
      "í": "i",
      "ó": "o",
      "ö": "o",
      "ő": "o",
      "ü": "u",
      "ű": "u",
      "ú": "u",
    })
    let name = JSON.stringify(item.name).toLowerCase()
    let nameString = replaceAll(name, {
      " ": "",
      "\"en\":": "",
      "\"hu\":": "",
      "{": "",
      "}": "",
      "\",": "",
      "\"": "",
      "á": "a",
      "é": "e",
      "í": "i",
      "ó": "o",
      "ö": "o",
      "ő": "o",
      "ü": "u",
      "ű": "u",
      "ú": "u",
    })
    return nameString.includes(filteredSearchText)  
  } 
  const replaceAll = (str, mapObj) => {
    if(str == null) return null
    let re = new RegExp(Object.keys(mapObj).join("|"),"gi")
    return str.replace(re, function(matched){
      return mapObj[matched.toLowerCase()]
    })
  }

  useEffect(() => {
    if(categories?.[selectedCategUUID]?.items == null || assembledItems == null) return null
    let itemsArray = categories?.[selectedCategUUID]?.items
    .map(item => assembledItems?.[item?.uuid])
    .filter(item => item?.enabled)
    .filter(item => assembledItems?.[item?.uuid]?.availability?.available)
    .sort(sortFunction)
    setStoredAssembledItems(itemsArray)
    // eslint-disable-next-line
  }, [categories, selectedCategUUID, assembledItems])

  useEffect(() => {
    if(categories?.[selectedCategUUID]?.items == null || assembledItems == null) return null
    if(searchedText != null && searchedText !== "" && searchedText.length > 1) {
      let itemsArray = Object.values(assembledItems)
      .filter(item => item.enabled)
      .filter(searchFunction)
      .map(item => item)
      .sort(sortFunction)
      setStoredAssembledItems(itemsArray)
    } else {
      let itemsArray = categories?.[selectedCategUUID]?.items
      .map(item => assembledItems?.[item?.uuid])
      .filter(item => item?.enabled)
      .filter(item => assembledItems?.[item?.uuid]?.availability?.available)
      .sort(sortFunction)
      setStoredAssembledItems(itemsArray)
    }
    // eslint-disable-next-line
  }, [searchedText])

  useEffect(() => {
    setPaginationNames(storedAssembledItems.filter((x, index) => index % (rows*cols) === 0).map(item => { return({name: item?.name, uuid: item.uuid}) }))
  }, [storedAssembledItems, rows, cols])

  return (
    <div id={"order-items-container-id"} className={`order-items-container ${isMobile ? "is-mobile" : ""} ${customization?.categoriesTop ? "horizontal" : "vertical"} ${customization?.paginationOff ? "pagination-off" : ""}`} ref={containerRef}>
      <BackToParentCateg />
      <MoveToSubCateg />
      {storedAssembledItems.map(item =>
        <Item
          key={selectedCategUUID + "" + item.uuid}
          itemUUID={item.uuid}
          assembledItem={assembledItems?.[item.uuid]}
          selectedCategUUID={selectedCategUUID}
          setItemAdded={setItemAdded}
          setAddedItemUUID={setAddedItemUUID}
          addedItemUUID={addedItemUUID}
        />
      )}
      <PageSelector selectableItems={paginationNames} />
    </div>
  )
}

export default OrderItems