import { useRef, useEffect, useContext, useState } from "react"
import { MainContainerContext } from "contexts/MainContainerProvider"
import LoadSvg from "components/LoadSvg-v2"
import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"
import { OEAsset } from 'okeoke.client'
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import { PersonalSettingsContext } from "contexts/PersonalSettingsProvider"
import moment from "moment"

function Item(props) {

  const { assembledItem, setItemAdded, addedItemUUID, setAddedItemUUID } = props
  const { addItem, addToUpdateQueue, clearUpdateQueueTimer, startUpdate } = useContext(SelectedOrderContext)
  const { itemMultiplier, setItemMultiplier } = useContext(MainContainerContext)
  const { localAppParams, customization } = useContext(PersonalSettingsContext)
  const { getName } = useMultilang()
  
  const timer = useRef(null)
  const newOrderItemUUIDRef = useRef(null)
  const countRef = useRef(0)
  
  const [count, setCount] = useState(0)
  const [countLayerVisible, setCountLayerVisible] = useState(false)
  const [itemClicked, setItemClicked] = useState(null)

  useEffect(() => {
    return () => clearTimeout(timer.current)
  }, [])

  useEffect(() => {
    if(itemClicked == null) return null
    clearTimeout(timer.current)
    if(countLayerVisible) {
      timer.current = setTimeout(() => {
        handleUpdateItem()
      }, 300)
    }
    // eslint-disable-next-line
  }, [itemClicked])

  useEffect(() => {
    if(addedItemUUID != null && addedItemUUID !== newOrderItemUUIDRef.current && countRef.current > 1) {
      clearTimeout(timer.current)
      handleUpdateItem()
    }
    // eslint-disable-next-line
  }, [addedItemUUID])

  const handleUpdateItem = () => {
    setCountLayerVisible(false)
    if(countRef.current > 1) {
      addToUpdateQueue({
        orderItemUUID: newOrderItemUUIDRef.current,
        count: countRef.current,
      })
    }
    setCount(0)
    newOrderItemUUIDRef.current = null
  }

  const handleItemAdd = (item) => {
    setItemAdded(moment())
    return addItem(item)
  }

  const handleClickOnItem = () => {
    if(localAppParams?.behaviour?.performanceMode) {
      let itemUUID = handleItemAdd({
        ...assembledItem,
        count: itemMultiplier || 1
      })
      newOrderItemUUIDRef.current = itemUUID
      setAddedItemUUID(itemUUID)
      setItemMultiplier(0)
    } else {
      clearUpdateQueueTimer()
      setItemClicked(itemClicked+1)
      setCountLayerVisible(true)
      if(countRef.current === 0) {
        let itemUUID = handleItemAdd({
            ...assembledItem,
            count: itemMultiplier || 1,
        })
        newOrderItemUUIDRef.current = itemUUID
        setAddedItemUUID(itemUUID)
        setCount(countRef.current + (itemMultiplier || 1))
        setItemMultiplier(0)
        startUpdate()
      } else {
        countRef.current = countRef.current + 1
        setCount(countRef.current)
      }
    }
  }

  useEffect(() => {
    countRef.current = count
  }, [count])

  let itemStyle = {
    color: assembledItem?.attributes?.posColor,
    backgroundColor: assembledItem?.attributes?.posBg
  }

  return (
    <div id={`order-items-item_${assembledItem.uuid}`} className="hidden quickAppear order-item-container" style={itemStyle}>
      {!customization?.itemImgOff && assembledItem.attributes.appImage !== "" && <div className="background-layer"><OEAsset uuid={assembledItem.attributes.appImage} addClass={`item-img`} size='s'/></div>}
      <div className={`order-item-count-layer ${countLayerVisible ? "visible" : "invisible"}`} onClick={handleClickOnItem}>
        <div className="order-item-count">{count}</div>
      </div>
      <div className="order-item-name">{getName(assembledItem?.name)}</div>
      {assembledItem?.availability?.visible === 0 && (
        <div className="sell-item-edit sell-item-visibility">
          <LoadSvg name="visibility" />
        </div>
      )}
      <div className="bottom-container">
        <div className="order-item-price" style={{color: itemStyle.color}}>
          <NumberFormat
            value={assembledItem?.totalPriceUnit}
            decimalSeparator=","
            suffix=" Ft"
            thousandSeparator="."
            displayType="text"
          />
        </div>
      </div>
    </div>
  )
}

export default Item