import { useIPanelApi, useClient } from "okeoke.client"

export default function useStatsApi() {

  const { get } = useIPanelApi()
  const { brandID, deviceLocationUUID } = useClient()
    
  const getOrdersByDeviceType = async (filter) => {

    const filters = JSON.stringify({ ...filter, locations: [deviceLocationUUID] })

    try {
      const response = await get(`brand/stats/orders/deviceType/total?brandID=${brandID}&filter=${filters}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }

  const getOrdersByDiscount = async (filter) => {

    const filters = JSON.stringify({ ...filter, locations: [deviceLocationUUID] })

    try {
      const response = await get(`brand/stats/orders/discounts/sum?brandID=${brandID}&filter=${filters}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }

  const getTopItemsSum = async (filter) => {

    const filters = JSON.stringify(filter)

    try {
      const response = await get(`brand/stats/orders/top/items/sum?brandID=${brandID}&filter=${filters}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }

  const getOrdersByPaymentType = async (filter) => {

    const filters = JSON.stringify({ ...filter, locations: [deviceLocationUUID] })

    try {
      const response = await get(`brand/stats/orders/paymentType/sum?brandID=${brandID}&filter=${filters}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }

  const getOrdersTotalSum = async (filter) => {

    const filters = JSON.stringify({ ...filter, locations: [deviceLocationUUID] })

    try {
      const response = await get(`brand/stats/orders/total/sum?brandID=${brandID}&filter=${filters}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }

  const getOrdersBaseTotal = async (filter) => {

    const filters = JSON.stringify({ ...filter, locations: [deviceLocationUUID] })

    try {
      const response = await get(`brand/stats/orders/base/total?brandID=${brandID}&filter=${filters}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }

  const getTippSum = async (filter) => {

    const filters = JSON.stringify({ ...filter, locations: [deviceLocationUUID] })

    try {
      const response = await get(`brand/stats/orders/tipp/sum?brandID=${brandID}&filter=${filters}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }  

  const getOrderByHour = async (filter) => {

    const filters = JSON.stringify({ ...filter, locations: [deviceLocationUUID] })

    try {
      const response = await get(`brand/stats/orders/hourly?brandID=${brandID}&filter=${filters}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }  

  const getSysUsersTotal = async (filter) => {

    const filters = JSON.stringify({ ...filter, locations: [deviceLocationUUID] })

    try {
      const response = await get(`brand/stats/orders/sysUsers/total?brandID=${brandID}&filter=${filters}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }  

  const getSysUsersBase = async (filter) => {

    const filters = JSON.stringify({ ...filter, locations: [deviceLocationUUID] })

    try {
      const response = await get(`brand/stats/orders/sysUsers/base?brandID=${brandID}&filter=${filters}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }  

  const getOrdersByVat = async (filter) => {

    const filters = JSON.stringify({ ...filter, locations: [deviceLocationUUID] })

    try {
      const response = await get(`brand/stats/orders/vat/sum?brandID=${brandID}&filter=${filters}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }  

  return {
    getOrdersByDeviceType,
    getOrdersByDiscount,
    getTopItemsSum,
    getOrdersByPaymentType,
    getOrdersTotalSum,
    getOrdersBaseTotal,
    getTippSum,
    getOrderByHour,
    getSysUsersTotal,
    getSysUsersBase,
    getOrdersByVat
  }
} 