import { useContext } from "react"
import useMultilang from "intl/useMultilang"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"

function BasketThrowModal(props) {
  
  const { abortOrder } = useContext(SelectedOrderContext)

  const { popModal } = props
  const { getT } = useMultilang()

  const handleBasketThrow = () => {
    abortOrder()
    popModal()
  }
  
  return (
    <div className="basket-throw-modal-root">
      <div className="text">{getT("modal.basketThrow.text")}</div>
      <div className="button-container">
        <div className="btn btn-cancel" onClick={handleBasketThrow}>{getT("modal.basketThrow.yes")}</div>
        <div className="btn btn-save" onClick={popModal}>{getT("modal.basketThrow.no")}</div>
      </div>
    </div>
  )
}

export default BasketThrowModal
