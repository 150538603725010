import useMultilang from "intl/useMultilang"

function PaymentPresentation() {

    const { getT } = useMultilang()

    return(
        <div className="payment-presentation-root">
            <div className="text-box border-glow-effect">
                {getT("tutorial.paymentPresentation")}
            </div>
        </div>
    )
}
export default PaymentPresentation