import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"

function Payments(props) {

    const { payments } = props

    const { getT, getName } = useMultilang()

    return(
        <div className="order-payments">
            {(payments || [])
            .map((payment, index) => 
            <div key={index} className="payment">
                <div className="name">
                {`${getT(`payment.type.${payment.agentType}`)} (${payment.paymentAgentName ? getName(payment.paymentAgentName) : "Agent name missing"})`}
                </div>
                <div className="amount">
                    <NumberFormat
                        value={+payment.amount}
                        decimalSeparator=","
                        suffix=" Ft"
                        thousandSeparator="."
                        displayType="text"
                    />
                </div>
            </div>
            )}
        </div>
    )
}
export default Payments