import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorFallback } from "./utils/ErrorHandler"
import { OrderEngineProvider, OkeFrameProvider, OkeDeviceProvider } from "okeoke.client"
import RegisterDevice from "views/RegisterDevice"
import { PersonalSettingsProvider } from "contexts/PersonalSettingsProvider"
import { LanguageProvider } from "intl/languageContext"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { ToastContainer } from "react-toastify"

ReactDOM.render(
  <BrowserRouter>
    <ErrorBoundary FallbackComponent={ErrorFallback} >
      <OkeFrameProvider>
        <ToastContainer />
        <OkeDeviceProvider prefix="pos" title="Pos" types={[2, 3]} CustomDeviceLogin={RegisterDevice}>
          <OrderEngineProvider>
            <PersonalSettingsProvider>
              <LanguageProvider app="pos" group="main">
                <DndProvider backend={HTML5Backend}>
                  <App />
                </DndProvider>
              </LanguageProvider>
            </PersonalSettingsProvider>
          </OrderEngineProvider>
        </OkeDeviceProvider>
      </OkeFrameProvider>
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById("root")
)
serviceWorkerRegistration.register()
reportWebVitals()