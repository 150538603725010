
import { useItems } from "okeoke.client"
import { useContext } from "react"
import useMultilang from "intl/useMultilang"
import { MainContext } from "contexts/MainContext"
import LoadSvg from "components/LoadSvg-v2"
import { useLocation } from "react-router-dom"

function MoveToSubCateg () {

  const { categories, assembledItems } = useItems()
  const {handleSelectCategory, selectedCategUUID} = useContext(MainContext)
  const { language } = useMultilang()
  const editorMode = useLocation().pathname === "/editor"

  if(selectedCategUUID == null) return null

  return Object.values(categories || {})
    .filter(categ => {
      return (
        categ?.parentUUID === selectedCategUUID &&
        (editorMode ? true :
          categ?.items.filter((itemUUID) => {
              return (
                assembledItems?.[itemUUID?.uuid] &&
                assembledItems?.[itemUUID?.uuid]?.availability?.available === 1
              );
            }).length > 0
        )
      )
    })
    .map((categ, index) => {
      return (
        <div key={index} className="order-item-container order-dummy-item-container" onClick={() => {
          handleSelectCategory(categ.uuid);
        }}>
          <div className="inner-container">
            <div className="svg-container">{<LoadSvg name={"folder"} />}</div>
            <div className="text">{categ.name?.[language]}</div>
          </div>
        </div>
      );
    })
}

export default MoveToSubCateg;
