import NumberFormat from "react-number-format"
import LoadSvg from "components/LoadSvg-v2"
import moment from "moment"
import useMultilang from "intl/useMultilang"
import consts from "const"

function Order(props) {

    const { order, setSelectedOrderUUID, selectedOrderUUID } = props
    const { getT } = useMultilang()

    return(
        <div className={`order-row ${order.uuid === selectedOrderUUID ? "selected" : ""}`} onClick={() => setSelectedOrderUUID(order.uuid)}>
            <div className="left-side">
                <div className="order-number">{order.orderNumber}</div>
                <div className="order-state">{getT(`orderState.${order.orderState}`)}</div>
                <div className="date">{moment(order.insertTimestamp).format("YYYY-MM-DD HH:mm:ss")}</div>
            </div>
            <div className="right-side">
                <div className="price">
                    <NumberFormat
                        value={+order.discountPrice}
                        decimalSeparator=","
                        suffix=" Ft"
                        thousandSeparator="."
                        displayType="text"
                    />
                </div>
                <div className="statuses">
                    {order?.ntakState != null && <LoadSvg name="ntak" className={`ntak-icon ${order?.ntakState?.status}`} />}
                    <LoadSvg name={consts.DEVICENAMES[order.insertDeviceType]} />
                </div>
            </div>
        </div>
    )
}
export default Order