import LoadSvg from "components/LoadSvg-v2"
import useMultilang from "intl/useMultilang"

function LeftSidePresentation(props) {

    const { goToNext } = props

    const { getT } = useMultilang()

    return(
        <div className="left-side-presentation-root" onClick={goToNext}>
            <div className="text-box">
                <LoadSvg name="ArrowLeftIcon" />
                <div className="text">{getT("tutorial.menuEditorMessage")}</div>
            </div>
        </div>
    )
}
export default LeftSidePresentation