import { useState, useEffect, useRef } from "react"
import useMultilang from "intl/useMultilang"
import InputFieldSelect from "components/InputFieldSelect"

const units = {
    ml: {
        subUnitID: "ml",
        mainUnitID: "liter",
        conversion: 0.001,
        displayed: {en: "ml", hu: "ml"}
    },
    cl: {
        subUnitID: "cl",
        mainUnitID: "liter",
        conversion: 0.01,
        displayed: {en: "cl", hu: "cl"}
    },
    dl: {
        subUnitID: "dl",
        mainUnitID: "liter",
        conversion: 0.1,
        displayed: {en: "dl", hu: "dl"}
    },
    liter: {
        subUnitID: "liter",
        mainUnitID: "liter",
        conversion: 1,
        displayed: {en: "liter", hu: "liter"}
    },
    g: {
        subUnitID: "g",
        mainUnitID: "kg",
        conversion: 0.001,
        displayed: {en: "g", hu: "g"}
    },
    dkg: {
        subUnitID: "dkg",
        mainUnitID: "kg",
        conversion: 0.01,
        displayed: {en: "dkg", hu: "dkg"}
    },
    kg: {
        subUnitID: "kg",
        mainUnitID: "kg",
        conversion: 1,
        displayed: {en: "kg", hu: "kg"}
    },
    unit: {
        subUnitID: "unit",
        mainUnitID: "unit",
        conversion: 1,
        displayed: {en: "unit", hu: "adag"}
    },
    piece: {
        subUnitID: "piece",
        mainUnitID: "piece",
        conversion: 1,
        displayed: {en: "piece", hu: "darab"}
    },
}

function UnitSelect(props) {

    const { onChange, unitValue, selectedSubUnitID, onSubUnitChange } = props

    const { getT, language } = useMultilang()
    const [localValue, setLocalValue] = useState(null)
    const currentValue = useRef(null)

    useEffect(() => {
        if(selectedSubUnitID != null && unitValue != null) {
            let newValue = unitValue / (units?.[selectedSubUnitID]?.conversion || 1)
            setLocalValue(newValue)
            currentValue.current = newValue
        }
    }, [unitValue, selectedSubUnitID])

    const handleChange = e => setLocalValue(e.target.value)

    const handleBlur = (e) => {
        if(typeof onChange === "function") {
            let calculatedValue = (units?.[selectedSubUnitID]?.conversion || 1) * e.target.value
            if(selectedSubUnitID === "piece") calculatedValue = Math.ceil(calculatedValue)
            onChange({ target: { name: "unit", value: calculatedValue }})
        }
    }

    const handleChangeUnit = (e) => {
        if(typeof onSubUnitChange === "function") {

            let subUnitID = e.target.value
            let unitType = units?.[subUnitID]?.mainUnitID || "piece"
            let calculatedValue = currentValue.current * (units?.[subUnitID]?.conversion || 1)
            if(subUnitID === "piece") calculatedValue = Math.ceil(calculatedValue)
            onSubUnitChange(subUnitID, unitType, calculatedValue)
        }
    }

    return (
        <div className="unit-select-root">
            <input onBlur={handleBlur} onChange={handleChange} type="number" value={localValue || ""} name="unit" disabled={selectedSubUnitID == null}/>
            <InputFieldSelect
                options={Object.values(units).map(unit => {
                    return { title: unit.displayed?.[language], value: unit.subUnitID }
                })}
                onChange={handleChangeUnit}
                name="selectedSubUnitID"
                value={selectedSubUnitID}
                displayedDefaultName={getT("unitSelect.selectUnit")}
                label={getT("unitSelect.selectedUnit")}
            />
        </div>
    )
}
export default UnitSelect