import { createContext, useContext, useEffect, useState } from "react"
import TopBar from "views/TopBar"
import LeftBar from "views/LeftBar"
import Login from "views/Login"
import OrderManager from "views/OrderManager"
import { useDevice, useClient, DeviceTypes, usePosPrinter, useOrders } from "okeoke.client"
import { isMobileOnly } from "react-device-detect"
import LoadSvg from "components/LoadSvg-v2"
import { TutorialContext } from "contexts/TutorialContext"
import { toast } from "react-toastify"
import useMultilang from "intl/useMultilang"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import { ModalContext } from "contexts/ModalContext"

const MainContainerContext = createContext()

const MainContainerProvider = (props) => {

  const { appParams, ordersInited } = useClient()

  const { loggedInUser, deviceType } = useDevice()

  const { tutorialOn } = useContext(TutorialContext)
  const { addModal, popModal } = useContext(ModalContext)
  const { printOrderSlips } = usePosPrinter()
  const { getT } = useMultilang()

  const [header, setHeader] = useState(null)
  const [itemMultiplier, setItemMultiplier] = useState(0)
  const [searchedText, setSearchedText] = useState("")
  const [leftBarClosed, setLeftBarClosed] = useState(!(localStorage['leftBarOpen'] === "true"))
  const [errors, setErrors] = useState({ openOrders: false, printerSettings: false, offline: false, ntak: false, settingsWizard: false })
  const [showBadge, setShowBadge] = useState(false)

  const contextValues = {
    header,
    setHeader,
    itemMultiplier,
    setItemMultiplier,
    searchedText,
    setSearchedText,
    leftBarClosed
  }

  useEffect(() => {
    checkErrors()
    // eslint-disable-next-line 
  }, [errors])

  const cbPrintOrderSlips = (order) => {
    console.log("Printing kitchenslip, order: ", order)
    printOrderSlips(order).then(res => {
      console.log("Print order slip res: ", res)
      if(res.code === 206) {
        toast.error(getT("modal.posPrinterError.kitchenSlipNotPrinted"))
        console.log(res.data)
        // addModal(
        //   <Modal
        //     key={uuidv4()}
        //     className="print-error-modal"
        //     onClickLayout={popModal}
        //   >
        //     {res.data.map(printer => <div className=""></div>)}
        //   </Modal>
        // )
      }
    })
  }

  // eslint-disable-next-line
  const { orders } = useOrders({ cbPrintOrderSlips })

  const toggleLeftBar = () => {
    setLeftBarClosed(!leftBarClosed)
    localStorage['leftBarOpen'] = leftBarClosed
  }

  const checkErrors = () => {
    let badgeOn = Object.values(errors).includes(true)
    setShowBadge(badgeOn)
  }

  if(!ordersInited) return null

  if(!loggedInUser) return <Login />

  if(DeviceTypes?.[deviceType] === "orderManager") 
  return (  
    <MainContainerContext.Provider value={contextValues}>
      <LeftBar deviceType={DeviceTypes?.[deviceType]} leftBarClosed={leftBarClosed} setErrors={setErrors} errors={errors}/>
      <OrderManager readOnly={appParams?.features?.readOnly} deviceType={DeviceTypes?.[deviceType]} />
    </MainContainerContext.Provider>
  )

  return (
    <MainContainerContext.Provider value={contextValues}>
      <LeftBar leftBarClosed={(!isMobileOnly && tutorialOn) ? false : leftBarClosed} setErrors={setErrors} errors={errors}/>
      <TopBar />
      <div className={`center-root ${isMobileOnly ? "is-mobile" : ""} ${(!isMobileOnly && tutorialOn) ? false : leftBarClosed ? "left-bar-closed" : ""}`}>
        {props.children}
      </div>
      <div className="menu-toggler" onClick={toggleLeftBar}><LoadSvg name="menu" />{showBadge && <div className="error-badge hidden quickAppear">!</div>}</div>
    </MainContainerContext.Provider>
  )
}

export { MainContainerContext, MainContainerProvider }