import { useContext, useState, useEffect } from "react"
import { MainContainerContext } from "contexts/MainContainerProvider"
import LoadSvg from "components/LoadSvg-v2"

function NumberRow() {

  const { itemMultiplier, setItemMultiplier } = useContext(MainContainerContext)
  const [value, setValue] = useState(0)

  const handleOnClick = (i) => {
    if (value * 10 + i > 999) {
      return null
    } else if (value !== 0) {
      setValue(value * 10 + i)
    } else {
      if (i === 0) {
        setValue(value * 10 + i)
      } else {
        setValue(i)
      }
    }
  };

  const handleDeleteNumber = () => {
    setValue(0);
  };

  useEffect(() => {
    if (value === 0) {
      setItemMultiplier(0)
    } else {
      setItemMultiplier(value);
    }
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    if (itemMultiplier !== value) {
      setValue(0)
    }
    // eslint-disable-next-line
  }, [itemMultiplier]);

  const numbers = [0, 1, 2, 3, 4, 5, 7, 8, 9]

  return (
    <div className={`number-row-container-root ${value > 0 ? "active" : "passive"}`}>
      <div className="number-row">
        {numbers.map((number) => {
          return (
            <div
              key={number}
              className={`number-in-circle`}
              onClick={() => {
                handleOnClick(number);
              }}
            >
              {number}
            </div>
          )
        })}
      </div>
      <div className="item-multiplier-number">{value > 0 ? value : "1"}x</div>
      <div className="icon-btn" onClick={handleDeleteNumber}>
        <LoadSvg name={"ArrowDeleteIcon"} />
      </div>
    </div>
  )
}
export default NumberRow