import { useDevice } from "okeoke.client"
import FiscatAdmin from "../FiscatAdmin"
import useMultilang from "intl/useMultilang"

function FiscalPrinterSettings() {

  const { deviceName, deviceParams} = useDevice()
  const printerType = deviceParams?.printer?.type

  const { getT } = useMultilang()

  return (
    <div className="fiscal-printer-control-panel-container">
      <div className="name">{deviceName}</div>
      <div className="printer-control-panel-container">
        {(printerType == null || printerType === "") && getT("settings.fiscalPrinter.noPrinter")}
        {printerType === "Fiscat" && <FiscatAdmin />}
      </div>
    </div>
  )
}
export default FiscalPrinterSettings