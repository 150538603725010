import { useState } from "react"
import NumberRowV2 from "./NumberRow_v2"
import useMultilang from "intl/useMultilang"


function CountModal(props) {

    const { setCount, popModal } = props

    const { getT } = useMultilang()

    const [localCount, setLocalCount] = useState(0)

    const handleSave = () => {
        if(localCount > 0) setCount(localCount)
        popModal()
    }

    return(
        <>
            <div className="title">{getT("sell.basket.count")}</div>
            <NumberRowV2 onChange={setLocalCount} value={localCount}/>
            <div className="button-container" style={{display: "flex", justifyContent: "center", gap: "12px", marginTop: "12px"}}>
                <div className="btn btn-cancel" onClick={popModal}>
                    {getT("identifier.modal.cancel")}
                </div>
                <div className={`btn btn-save`} onClick={handleSave}>
                    {getT("identifier.modal.next")}
                </div>
            </div>
        </>
    )
}
export default CountModal