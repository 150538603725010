import { useIPanelApi, useClient } from "okeoke.client"

export default function useSizesApi() {

  const { post, get } = useIPanelApi()
  const { brandID, selectedLocationUUID } = useClient()

  const getSizes = async (filter) => {
    try {
      const response = await get(`brand/catalog/sizes/all?brandID=${brandID}&filter=${JSON.stringify(filter || {})}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const getSize = async (sizeUUID) => {
    try {
      const response = await get(`brand/catalog/sizes/${sizeUUID}?brandID=${brandID}`)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  const setSize = async (size) => {
    let body = {
        brandID,
        size: {...size, locations: [selectedLocationUUID]}
        /*size: {
            uuid,
            enabled,
            name: {
                    hu: ""
            }
        }*/
    }
    try {
      const response = await post(`brand/catalog/sizes`, body)
      return response
    } catch (error) {
      console.log(error)
      return { success: false }
    }
  }
  return {
    getSizes,
    getSize,
    setSize
  }
}