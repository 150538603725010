import { useFiscatPrinter, usePrinter, useDevice } from "okeoke.client"
import CloseDay from './CloseDay'
import OpenDay from './OpenDay'
import './style.scss'

function FiscatAdmin() {

  const { deviceParams } = useDevice()
  const { 
    fiscatCloseFiscalReceipt, 
    fiscatCloseNonFiscalReceipt, 
    fiscatCancelFiscalReceipt 
  } = useFiscatPrinter(deviceParams?.printer)
  const { 
    display,
    dayOpened,
    noPaper,
    paperJammed,
    paperNearEnd,
    printerCover,
    printerFailure,
    saleOver24h,
    selfServiceMode,
    generalError
  } = usePrinter()

  const onClickCFR = () => {
    fiscatCloseFiscalReceipt().then(res => {
      console.log(res)
    })
  }

  const onClickCNFR = () => {
    fiscatCloseNonFiscalReceipt().then(res => {
      console.log(res)
    })
  }

  const onClickCELFR = () => {
    fiscatCancelFiscalReceipt().then(res => {
      console.log(res)
    })
  }

  const unstackPrinter = () => {
    onClickCFR()
    onClickCNFR()
    onClickCELFR()
  }

  // const onClickRS = () => {
  //   fiscatGetStatus().then(res => {
  //     setMessage(JSON.stringify(res))
  //   })
  // }

  return (
  <div className="fiscat-admin-root">
      <h2>Fiscat Admin</h2>
      <div className="display-container">
        <div className="display-row-1">{(display || [])[0]}</div>
        <div className="display-row-2">{(display || [])[1]}</div>
      </div>
      <div className="action-btn-container">
        <OpenDay dayOpened={dayOpened === "1"} />
        <CloseDay dayOpened={dayOpened === "1"} />
        <div className='btn' onClick={unstackPrinter}>Beragadt nyugta lezárás</div>
      </div>
      <p>Nyomtató státusz</p>
      <div className="status-container">
        <div className="status-box">
          <div className="label">Nap nyitva</div>
          <div className={`led ${dayOpened === "1" ? "green" : "red"}`}></div>
        </div>
        <div className="status-box">
          <div className="label">Nincs papír</div>
          <div className={`led ${noPaper === "1" ? "red" : ""}`}></div>
        </div>
        <div className="status-box">
          <div className="label">Papír elakadás</div>
          <div className={`led ${paperJammed === "1" ? "red" : ""}`}></div>
        </div>
        <div className="status-box">
          <div className="label">Kevés papír</div>
          <div className={`led ${paperNearEnd === "1" ? "blue" : ""}`}></div>
        </div>
        <div className="status-box">
          <div className="label">Fedél nyitva</div>
          <div className={`led ${printerCover === "1" ? "blue" : ""}`}></div>
        </div>
        <div className="status-box">
          <div className="label">Nyomtatási hiba</div>
          <div className={`led ${printerFailure === "1" ? "red" : ""}`}></div>
        </div>
        <div className="status-box">
          <div className="label">Több mint 24 órája nem volt zárás</div>
          <div className={`led ${saleOver24h === "1" ? "red" : ""}`}></div>
        </div>
        <div className="status-box">
          <div className="label">Önkiszolgáló mód</div>
          <div className={`led ${selfServiceMode === "1" ? "blue" : ""}`}></div>
        </div>
        <div className="status-box">
          <div className="label">Általános hiba</div>
          <div className={`led ${generalError === "1" ? "red" : ""}`}></div>
        </div>
      </div>
  </div>)   
}
export default FiscatAdmin