import { useContext } from "react"
import LoadSvg from "components/LoadSvg-v2"
import useMultilang from "intl/useMultilang"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"

function SellPresentation(props) {

    const { goToNext } = props
    
    const { getT } = useMultilang()
    const { orderItemsAssembled } = useContext(SelectedOrderContext)
    
    return(
        <div className="sell-presentation-root">
            <div className="order-head">{getT("tutorial.sell.orderHead")}<LoadSvg name="ArrowLeftIcon" /></div>
            <div className="order-actions">{getT("tutorial.sell.orderActions")}<LoadSvg name="ArrowLeftIcon" /></div>
            <div className="order-items">
                <div className="row">{getT("tutorial.sell.orderItems")}<LoadSvg name="ArrowLeftIcon" /></div>
                {Object.values(orderItemsAssembled || {}).length < 1 && <div className="no-items">
                    <div className="text">{getT("tutorial.sell.noItemsInOrder")}</div>
                    <div className="btn btn-highlighted border-glow-effect pulsate-fwd" onClick={goToNext}>{getT("tutorial.sell.addItemToOrder")}</div>
                </div>}
            </div>
            <div className="order-payment">{getT("tutorial.sell.orderPayment")}<LoadSvg name="ArrowLeftIcon" /></div>
        </div>
    )
}
export default SellPresentation