import { useDevice, useClient } from "okeoke.client"
import { useContext } from "react"
import { ModalContext } from "contexts/ModalContext"
import { MainContext } from "contexts/MainContext"
import useMultilang from "intl/useMultilang"
import { PersonalSettingsContext } from "contexts/PersonalSettingsProvider"
import LoadSvg from "components/LoadSvg-v2"

function DeviceSettings() {

  const { popModal, addModal } = useContext(ModalContext)
  const { featureList } = useContext(MainContext)
  const { deviceName, deviceParams } = useDevice()
  const { sandboxMode, setSandboxMode } = useClient(null)
  const { localAppParams, changeLocalAppParam } = useContext(PersonalSettingsContext)

  const { getT } = useMultilang()
  
  const clearCache = () => {
    localStorage.clear()
    reloadApp()
  }
  
  const reloadApp = () => {
    window.location.reload()
  }

  const openClearCacheModal = () => {
    addModal(
      <div className="clear-cache-modal">
        <h2>{getT("settings.device.clearCacheTitle")}</h2>
        <p>{getT("settings.device.message")}</p>
        <div className="actions">
          <div className="btn secondary" onClick={popModal}>{getT("settings.device.cancel")}</div>
          <div className="btn primary" onClick={clearCache}>{getT("settings.device.ok")}</div>
        </div>
      </div>
    )
  }
    
  const openRefreshModal = () => {
    addModal(
      <div className="clear-cache-modal">
        <h2>{getT("setting.device.refreshTitle")}</h2>
        <div className="actions">
          <div className="btn secondary" onClick={popModal}>{getT("settings.device.cancel")}</div>
          <div className="btn primary" onClick={reloadApp}>{getT("settings.device.ok")}</div>
        </div>
      </div>
    )
  }

  const checkOption = (name, value) => {
    changeLocalAppParam("behaviour", name, value)
  }

  const handleSetSandboxMode = () => {
    setSandboxMode(!(sandboxMode && !deviceParams?.sandbox))
  }
  
  return (
    <div className="device-control-panel-container">
      <div className="title">{getT("settings.options.deviceSettings")}</div>
      <div className="name">{deviceName}</div>
      <div className="function-button-container">
          <div className="btn btn-highlighted" onClick={openRefreshModal}>{getT("settings.device.refresh")}</div>
          <div className="btn btn-cancel" onClick={openClearCacheModal}>{getT("settings.device.clearCache")}</div>
      </div>
      <div className="app-params-container">
        <div className="sub-title">{getT("settings.device.featureList")}</div>
        <div className="features-container">
          {Object.entries(featureList || {})
          .map(feature => 
            <div key={feature[0]} className={`feature-box ${feature[1] ? "active" : "locked"}`}>
              <LoadSvg name={`${feature[1] ? "check" : "locked"}`}/>
              {getT(`settings.device.${feature[0]}`)}
            </div>)}
        </div>
      </div>
      <div className="app-params-container">
        <div className="sub-title">{getT("settings.device.behaviour")}</div>
        {/* {localAppParams?.behaviour?.localSettingsOverRideAllowed === false && <p>{getT("settings.device.paramsSettingNotAllowed")}</p>} */}
        {/*${localAppParams?.behaviour?.localSettingsOverRideAllowed ? "" : "disabled"}*/}
        <div className={`app-params`}>
          {featureList.orderManager === 1 && <div className={`option ${localAppParams?.behaviour?.sendToOrderManager ? "selected" : ""}`}  onClick={() => checkOption("sendToOrderManager", !localAppParams?.behaviour?.sendToOrderManager)}>
            <div className="inner-bg"></div>
            <div className="param-name">{getT(`modal.personalSettings.option.sendToOrderManager`)}</div>
          </div>}
          <div className={`option ${localAppParams?.behaviour?.performanceMode ? "selected" : ""}`} onClick={() => checkOption("performanceMode", !localAppParams?.behaviour?.performanceMode)}>
            <div className="inner-bg"></div>
            <div className="param-name">{getT(`modal.personalSettings.option.performanceMode`)}</div>
          </div>
          <div className={`option ${sandboxMode ? "selected" : ""}`} onClick={handleSetSandboxMode}>
            <div className="inner-bg"></div>
            <div className="param-name">{getT(`modal.personalSettings.option.sandboxMode`)}</div>
          </div>
        </div>
      </div>
    </div>
    )
  }
  export default DeviceSettings