import { useState, useEffect } from "react"
import moment from "moment"
import DataTable from "components/DataTable"
import useMultilang from "intl/useMultilang"
import NumberFormat from "react-number-format"
import ReportsMenu from "./ReportsMenu"
import ReportsHead from "./ReportsHead"
import useStatsApi from "apis/useStatsApi"

function PaymentType() {

  const { getOrdersByPaymentType } = useStatsApi()
  const { getT, getName } = useMultilang()

  const [list, setList] = useState(null)
  const [sumAmount, setSumAmount] = useState(null)
  const [sumBankcard, setSumBankCard] = useState(null)
  const [sumCash, setSumCash] = useState(null)
  const [sumOnline, setSumOnline] = useState(null)
  const [sumTippAmount, setSumTippAmount] = useState(null)
  const [sumCount, setSumCount] = useState(null)
  const [filter, setFilter] = useState({from: `${moment().format("YYYY-MM-DD")} 00:00:00`, to: `${moment().format("YYYY-MM-DD")} 23:59:59`})

  useEffect(() => {
    loadPaymentTypes()
      // eslint-disable-next-line
  }, [])

  useEffect(() => {
    loadPaymentTypes()
      // eslint-disable-next-line
  }, [filter])

  const loadPaymentTypes = () => {

    let filters = { fromDate: filter.from, toDate: filter.to }

    getOrdersByPaymentType(filters).then(res => {
      if(res.success) {
        setList(res.data)
        let sum1 = 0
        let sum2 = 0
        let sum3 = 0
        let sumBankcard = 0
        let sumCash = 0
        let sumOnline = 0
        for(let item of res.data) {
          sum1 = sum1 + parseInt(item.totalCount)
          sum2 = sum2 + parseInt(item.paySum)
          sum3 = sum3 + parseInt(item.tippSum)
          if(item.agentType === "bankCard") sumBankcard = sumBankcard + parseInt(item.paySum)
          if(item.agentType === "cash") sumCash = sumCash + parseInt(item.paySum)
          if(item.agentType === "online") sumOnline = sumOnline + parseInt(item.paySum)
        }
        setSumCount(sum1)
        setSumAmount(sum2)
        setSumTippAmount(sum3)
        setSumBankCard(sumBankcard)
        setSumCash(sumCash)
        setSumOnline(sumOnline)
      } else {
        console.log(res)
      }
    })
  }

  const columns = [
    {
      name: "name",
      label: getT("reports.paymentType.name"),
      options: {
        className: "head-align-left cell-align-left",
        sort: false,
        format: row => row.name == null ? getT("reports.paymentType.notAvailable") : getName(row.name),
        type: false,
      },
    },
    {
      name: "type",
      label: getT("reports.paymentType"),
      options: {
          className: "head-align-left cell-align-left",
          sort: false,
          format: row => {
            switch(row.agentType) {
              case "cash": return getT("reports.paymentType.cash")
              case "bankCard": return getT("reports.paymentType.bankCard")
              case "online": return getT("reports.paymentType.online")
              default: return getT("reports.paymentType.other")
            }
          },
          type: false,
      },
    },
    {
      name: "count",
      label: getT("reports.paymentType.count"),
      options: {
        className: "head-align-center cell-align-center",
        sort: false,
        format: row => row.totalCount,
        type: false,
      },
    },
    {
      name: "paySum",
      label: getT("reports.paymentType.paySum"),
      options: {
        className: "head-align-right cell-align-right",
        sort: false,
        format: row => 
        <NumberFormat
          value={parseInt(row.paySum)}
          decimalSeparator=","
          suffix=" Ft"
          thousandSeparator="."
          displayType="text"
        />,
        type: false,
      },
    },
    {
      name: "tippSum",
      label: getT("reports.paymentType.tippSum"),
      options: {
          className: "head-align-right cell-align-right",
          sort: false,
          format: row =>
          <NumberFormat
            value={parseInt(row.tippSum)}
            decimalSeparator=","
            suffix=" Ft"
            thousandSeparator="."
            displayType="text"
          />,
          type: false,
        },
      }
    ]

    const handleDateChange = (values) => {
      setFilter(values)
    }

    return(
      <div className="reports-container paymentType-container">
        <ReportsHead title={getT("reports.paymentType.title")} handleDateChange={handleDateChange} filter={filter}/>
        <div className="body">
        <div className="box-container">
          <div className="box">
            <div className="label">{getT("reports.paymentType.sumAmount")}</div>
            <div className="value">
              <NumberFormat
                value={parseInt(sumAmount)}
                decimalSeparator=","
                suffix=" Ft"
                thousandSeparator="."
                displayType="text"
              />
            </div>
          </div>
          <div className="box">
            <div className="label">{getT("reports.paymentType.sumCount")}</div>
            <div className="value">{sumCount}</div>
          </div>
          <div className="box">
            <div className="label">{getT("reports.paymentType.sumTippAmount")}</div>
            <div className="value">
              <NumberFormat
                value={parseInt(sumTippAmount)}
                decimalSeparator=","
                suffix=" Ft"
                thousandSeparator="."
                displayType="text"
              />
            </div>
          </div>
          <div className="box">
            <div className="label">{getT("reports.paymentType.cash")}</div>
            <div className="value">
              <NumberFormat
                value={parseInt(sumCash)}
                decimalSeparator=","
                suffix=" Ft"
                thousandSeparator="."
                displayType="text"
              />
            </div>
          </div>
          <div className="box">
            <div className="label">{getT("reports.paymentType.bankCard")}</div>
            <div className="value">
              <NumberFormat
                value={parseInt(sumBankcard)}
                decimalSeparator=","
                suffix=" Ft"
                thousandSeparator="."
                displayType="text"
              />
            </div>
          </div>
          <div className="box">
            <div className="label">{getT("reports.paymentType.online")}</div>
            <div className="value">
              <NumberFormat
                value={parseInt(sumOnline)}
                decimalSeparator=","
                suffix=" Ft"
                thousandSeparator="."
                displayType="text"
              />
            </div>
          </div>
        </div>
        <div className="table-container">
            <DataTable data={list} columns={columns} />
        </div>
      </div>
      <div className="footer">
        <ReportsMenu />
      </div>
    </div>
  )
}
export default PaymentType