import { useContext, useEffect, useState } from "react"
import { useItems } from "okeoke.client"
import { MainContext } from "contexts/MainContext"
import { MainContainerContext } from "contexts/MainContainerProvider"
import { isMobileOnly, isTablet } from "react-device-detect"
import useMultilang from "intl/useMultilang"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import { PersonalSettingsContext } from "contexts/PersonalSettingsProvider"
/*import { ModalContext } from "contexts/ModalContext"
import CreateNewCategory from "views/Editor/CreateNewCategory"
import Modal from "components/Modal/Modal"
import { v4 as uuidv4 } from "uuid"
import LoadSvg from "components/LoadSvg-v2"*/

function Categories(props) {

  const { editorMode } = props
    
  const { selectedCategUUID, handleSelectCategory } = useContext(MainContext)
  const { setSearchedText } = useContext(MainContainerContext)

  const { swiper } = useContext(SelectedOrderContext)
  const { customization } = useContext(PersonalSettingsContext)
  //const { addModal, popModal } = useContext(ModalContext)

  const { categories, categoryTree } = useItems()
  const { getT, language } = useMultilang()
  const [categoryList, setCategoryList] = useState(null)
  const [disabledCategoryList, setDisabledCategoryList] = useState(null)

  useEffect(() => {
    if(!editorMode) {
      let validCategs = (categoryTree || []).filter(categ => categ.availability.availableItems > 0)
      handleClickCategory(validCategs?.[0]?.uuid)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(categories == null || categoryTree == null) return null
    let list = categoryTree
    .filter(categ => categories?.[categ?.uuid]?.enabled)
    .filter(categ => categ.availability.availableItems > 0)
    setCategoryList(list)

    let disabledList = categoryTree
    .filter(categ => editorMode ? !categories?.[categ?.uuid]?.enabled : false)
    setDisabledCategoryList(disabledList)
    // eslint-disable-next-line
  }, [categoryTree, categories])

  useEffect(() => {
    let validCategs = (categoryTree || []).filter(categ => editorMode ? true : categ.availability.availableItems > 0)
    if (selectedCategUUID == null && validCategs?.length > 0) {
      handleClickCategory(validCategs?.[0]?.uuid)
    }
    // eslint-disable-next-line
  }, [selectedCategUUID, categoryTree])

  const handleClickCategory = (id) => {
    setSearchedText("")
    if(typeof handleSelectCategory === "function") handleSelectCategory(id)
    if(typeof swiper?.slideTo === "function" && swiper?.slides?.length > 0) swiper?.slideTo(1)
  }

  const checkSelectedCategory = (categoryUUID) => {
    if (!selectedCategUUID) return false
    if (categories[categoryUUID]?.parentUUID === "" && selectedCategUUID === categoryUUID) return true
    if (categories[selectedCategUUID]?.parentUUID === categoryUUID) return true
  }

  // const openCreateCategoryInModal = () => {
  //   addModal(
  //     <Modal
  //       key={uuidv4()}
  //       className="create-new-category-modal"
  //       onClickLayout={popModal}
  //     >
  //       <CreateNewCategory navigateToEditor={true} />
  //     </Modal>
  //   )
  // }

  if(categoryList == null || categoryList.length <= 1) return null

  return (
    <div className={`hidden appear categories-container ${isMobileOnly ? "is-mobile" : isTablet ? "is-tablet" : ""} ${customization?.categoriesTop ? "horizontal" : "vertical"} ${customization?.paginationOff ? "pagination-off" : ""}`}>
      {categoryList.length === 0 && <div className="message hidden appear">{getT("categories.noCategory")}</div>}
      {categoryList.length > 1 && categoryList.map(categ => 
        <div 
          key={categ.uuid} 
          className={`category-container ${isMobileOnly ? "is-mobile" : isTablet ? "is-tablet" : ""} ${checkSelectedCategory(categ.uuid) ? "selected" : ""} ${customization?.categoriesTop ? "" : "full-width"}`}
          onMouseUp={() => {handleClickCategory(categ.uuid)}}
        >
          {checkSelectedCategory(categ.uuid) && <div className="inner-bg"></div>}
          {categories?.[categ.uuid]?.name?.[language]}
        </div>
      )}
      {disabledCategoryList.length > 1 && disabledCategoryList.map(categ => 
        <div 
          key={categ.uuid} 
          className={`category-container disabled ${isMobileOnly ? "is-mobile" : isTablet ? "is-tablet" : ""} ${checkSelectedCategory(categ.uuid) ? "selected" : ""} ${customization?.categoriesTop ? "" : "full-width"}`}
          onMouseUp={() => {handleClickCategory(categ.uuid)}}
        >
          {checkSelectedCategory(categ.uuid) && <div className="inner-bg"></div>}
          {categories?.[categ.uuid]?.name?.[language]}
        </div>
      )}
      {/* {categoryList.length === 0 && <div className="btn btn-highlighted" onClick={openCreateCategoryInModal}>
          <div className="icon-btn">
            <LoadSvg name={"subCateg"} />
          </div>
          <div className="text">{getT("sell.edit.CreateCategory")}</div>
      </div>} */}
    </div>
  )
}

export default Categories
