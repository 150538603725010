import { useContext, useEffect, useRef, useState } from "react"
import OrderSummary from "views/Sell/OrderSummary"
import Categories from "views/Sell/Categories"
import OrderItems from "./OrderItems"
import { MainContainerContext } from "contexts/MainContainerProvider"
import { SelectedOrderContext } from "contexts/SelectedOrderContext"
import { TutorialContext } from "contexts/TutorialContext"
import { MainContext } from "contexts/MainContext"
import OrderPriceAndSubmit from "views/Sell/OrderPriceAndSubmit"
import { isMobileOnly } from "react-device-detect"
import { useOrder, useLocation, useClient, useItems } from "okeoke.client"
import useMultilang from "intl/useMultilang"
import NumberRow from "views/Sell/NumberRow"
import SellNotAllowedOverlay from "components/SellNotAllowedOverlay"
import FiscalPrinterOverlay from "components/FiscalPrinterOverlay"
import { Swiper, SwiperSlide } from 'swiper/react'
import { PersonalSettingsContext } from "contexts/PersonalSettingsProvider"
import LoadSvg from "components/LoadSvg-v2"
import SearchField from "./SearchField"
import SellPresentation from "./Tutorial/SellPresentation"
import Modal from 'components/Modal/Modal'
import { ModalContext } from 'contexts/ModalContext'
import { v4 as uuidv4 } from 'uuid'
import WelcomeDemoModal from "./WelcomeDemoModal"
import HeaderPresentation from "./Tutorial/HeaderPresentation"
import LeftSidePresentation from "./Tutorial/LeftSidePresentation"
import SellItemPresentation from "./Tutorial/SellItemPresentation"
import PaymentPresentation from "./Tutorial/PaymentPresentation"
//import LoadSvg from "components/LoadSvg-v2"
//import { useNavigate } from "react-router-dom"

function Sell() {
  
  const { demoMode } = useClient()
  const { categories, assembledItems } = useItems()
  const { setHeader } = useContext(MainContainerContext)
  const { offlineOpen, selectedCategUUID } = useContext(MainContext)
  const { addModal, popModal } = useContext(ModalContext)
  const { selectedOrderUUID, setSwiper, swiper } = useContext(SelectedOrderContext)
  const { tutorialOn, setContents, setSectionsVisibility, endTutorial } = useContext(TutorialContext)
  const { orderItemsAssembled } = useOrder(null, selectedOrderUUID)
  const { customization } = useContext(PersonalSettingsContext)
  const { location } = useLocation()

  const [activeSlide, setActiveSlide] = useState(0)
  const [priceAndSubmitOpen, setPriceAndSubmitOpen] = useState(localStorage['totalPriceOfOrderOpen'] === "1")
  const [itemAdded, setItemAdded] = useState(null)
  const [tutorialStep, setTutorialStep] = useState(null)

  const { getName, getT } = useMultilang()
  //const navigate = useNavigate()
  const swiperRef = useRef(null)

  let orderItems = Object.values(orderItemsAssembled || {})
  let lastItem = (orderItems[orderItems.length-1] || {})

  useEffect(() => {
    setHeader(
      <>
        {/* <div className="editor-header-left-side-container" onClick={() => navigate("/editor")}>
          <div className="icon-btn selected">
            <LoadSvg name="SellModeIcon" />
          </div>
          <div className="text">{getT("sell.header.sale")}</div>
        </div> */}
        <div className="sell-header-root">
          <SearchField />
          <NumberRow />
        </div>
      </>
    )
    setSwiper(swiperRef.current)

    return () => {
      setHeader(null)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(demoMode && localStorage['welcomeDone'] == null) {
      addModal(
        <Modal key={uuidv4()} className="welcome-demo-modal">
          <WelcomeDemoModal popModal={popModal} />
        </Modal>
      )
    }
    // eslint-disable-next-line
  }, [demoMode])

  useEffect(() => {

    if(location == null) return null

    if(location?.openState == null) return null

    const dayOpen = offlineOpen || (location?.openState?.dayState != null && location?.openState?.dayState?.open)
    
    if(tutorialOn && dayOpen) {
      if(categories?.[selectedCategUUID]?.items != null &&  assembledItems != null) {
        let itemsArray = categories?.[selectedCategUUID]?.items
        .map(item => assembledItems?.[item?.uuid])
        .filter(item => item?.enabled)
        .filter(item => assembledItems?.[item?.uuid]?.availability?.available)
        
        //const basketItems = Object.values(orderItemsAssembled || {}).filter(assembledItem => assembledItem?.count > 0)
        
        if(itemsArray.length > 0) {
          setTutorialStep("sellItem")
        } else {
          setTutorialStep("headerPresentation")
        }
      } else {
        setTutorialStep("sellPresentation")
      }
    }
  // eslint-disable-next-line
  }, [tutorialOn, offlineOpen, location, orderItemsAssembled, categories, assembledItems, selectedCategUUID])

  useEffect(() => {
    if(tutorialStep === "sellPresentation") {
      setContents([
        {
          section: "center",
          content: <SellPresentation goToNext={handleGoToNext} />
        },
        {
          section: "summary",
          content: null
        }
      ])
      setSectionsVisibility([
        {section: "summary", visibility: false}, 
        {section: "center", visibility: true}, 
        {section: "header", visibility: true},
        {section: "leftSide", visibility: true}
      ])
    }
    if(tutorialStep === "headerPresentation") {
      setContents([
        {
          section: "center",
          content: <HeaderPresentation goToNext={handleGoToNext} />
        }
      ])
      setSectionsVisibility([
        {section: "summary", visibility: true}, 
        {section: "center", visibility: true}, 
        {section: "header", visibility: false}
      ])
    }
    if(tutorialStep === "leftSidePresentation") {
      setContents([
        {
            section: "center",
            content: <LeftSidePresentation goToNext={handleGoToNext} />
        }
      ])
      setSectionsVisibility([
        {section: "summary", visibility: true}, 
        {section: "center", visibility: true}, 
        {section: "header", visibility: true}, 
        {section: "leftSide", visibility: false}
      ])
    }
    if(tutorialStep === "sellItem") {
      setContents([
        {
            section: "header",
            content: <SellItemPresentation goToNext={handleGoToNext} />
        }
      ])
      setSectionsVisibility([
        {section: "summary", visibility: false}, 
        {section: "center", visibility: false}, 
        {section: "header", visibility: true}, 
        {section: "leftSide", visibility: true}
      ])
    }
    if(tutorialStep === "paymentPresentation") {
      setContents([
        {
          section: "header",
          content: <PaymentPresentation />
        },
        {
          section: "center",
          content: null
        }
      ])
      setSectionsVisibility([
        {section: "summary", visibility: false}, 
        {section: "center", visibility: true}, 
        {section: "header", visibility: true},
        {section: "leftSide", visibility: true}
      ])
    }
    // eslint-disable-next-line
  }, [tutorialStep])

  const handleGoToNext = () => {
    if(tutorialStep === "sellPresentation") {
      if(categories?.[selectedCategUUID]?.items != null &&  assembledItems != null) {
        let itemsArray = categories?.[selectedCategUUID]?.items
          .map(item => assembledItems?.[item?.uuid])
          .filter(item => item?.enabled)
          .filter(item => assembledItems?.[item?.uuid]?.availability?.available)
        if(itemsArray.length > 0) {
          setTutorialStep("sellItem")
        } else {
          setTutorialStep("headerPresentation")
        }      
      } else {
        setTutorialStep("headerPresentation")
      }
    }
    if(tutorialStep === "headerPresentation") setTutorialStep("leftSidePresentation")
  }

  const handleClickSlideControl = (index) => {
    if(typeof swiper.slideTo === "function") swiper.slideTo(index)
  }

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.activeIndex)
  }

  const togglePanel = () => {
    setPriceAndSubmitOpen(priceAndSubmitOpen ? false : "1")
    priceAndSubmitOpen ? localStorage.removeItem("totalPriceOfOrderOpen") : localStorage["totalPriceOfOrderOpen"] = "1"
  }

  if(isMobileOnly) return(
    <>
    <Swiper ref={swiperRef} onSlideChange={handleSlideChange}>
      <SwiperSlide>
        <Categories />
      </SwiperSlide>
      <SwiperSlide>
        <div className="sell-root hidden quickAppear">
          <SellNotAllowedOverlay />
          <FiscalPrinterOverlay />
          <OrderItems setItemAdded={setItemAdded} />
          {<div className="last-item">{lastItem?.count != null && `${getT("sell.lastItem")}: ${lastItem.count}x ${getName(lastItem.name)}`}</div>}
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="summary-root">
          <OrderSummary priceAndSubmitOpen={priceAndSubmitOpen} itemAdded={itemAdded} />
          <OrderPriceAndSubmit togglePanel={togglePanel} priceAndSubmitOpen={priceAndSubmitOpen} />
        </div>
      </SwiperSlide>
    </Swiper>
    <div className="swiper-controller">
      <div className={`control ${activeSlide === 0 ? "selected" : ""}`} onClick={() => handleClickSlideControl(0)}>
        <LoadSvg name="list"/>
        <div className="dot">.</div>
      </div>
      <div className={`control ${activeSlide === 1 ? "selected" : ""}`} onClick={() => handleClickSlideControl(1)}>
        <LoadSvg name="PurchaseTagIcon"/>
        <div className="dot">.</div>
      </div>
      <div className={`control ${activeSlide === 2 ? "selected" : ""}`} onClick={() => handleClickSlideControl(2)}>
        <LoadSvg name="payCard"/>
        <div className="dot">.</div>
      </div>
    </div>
    </>
  )

  return (
    <div className="sell-outer-container">
      {customization.sellRightToLeft && <div className="summary-root">
        <OrderSummary priceAndSubmitOpen={priceAndSubmitOpen} itemAdded={itemAdded} />
        <OrderPriceAndSubmit togglePanel={togglePanel} priceAndSubmitOpen={priceAndSubmitOpen} />
      </div>}
      <div className="sell-root hidden quickAppear">
        <SellNotAllowedOverlay />
        <FiscalPrinterOverlay />
        <Categories />
        <OrderItems setItemAdded={setItemAdded} />
      </div>
      {!customization.sellRightToLeft && <div className="summary-root">
        <OrderSummary priceAndSubmitOpen={priceAndSubmitOpen} itemAdded={itemAdded} />
        <OrderPriceAndSubmit togglePanel={togglePanel} priceAndSubmitOpen={priceAndSubmitOpen} setTutorialStep={setTutorialStep} endTutorial={endTutorial} />
      </div>}
    </div>
  )
}
export default Sell